import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportHvs = ({
  id,
  dmcsCTitles,
  date,
  dmcsCSubheading,
  dmicroscopyHeader,
  dmicroscopyCells01,
  dmicroscopyCells02,
  dmicroscopyCells03,
  dmicroscopyCells04,
  dmicroscopyValues01,
  dmicroscopyValues02,
  dmicroscopyValues03,
  dmicroscopyValues04,
  dcultureCTitle,
  dcultureCSubHeading,
  dcultureC1Header,
  dcultureC2Header,
  antibiotics01,
  sensitivity01,
  antibiotics02,
  sensitivity02,
  antibiotics03,
  sensitivity03,
  antibiotics04,
  sensitivity04,
  antibiotics05,
  sensitivity05,
  antibiotics06,
  sensitivity06,
  antibiotics07,
  sensitivity07,
  antibiotics08,
  sensitivity08,
  antibiotics09,
  sensitivity09,
  antibiotics10,
  sensitivity10,
  checkedby,
}) => {
  const [mcsTitle, setMcsTitle] = useState(dmcsCTitles);
  const [mcsSubheading, setMcsSubheading] = useState(dmcsCSubheading);
  const [microscopyheader, setMicroscopyHeader] = useState(dmicroscopyHeader);
  const [microscopycell01, setMicroscopyCell01] = useState(dmicroscopyCells01);
  const [microscopycell02, setMicroscopyCell02] = useState(dmicroscopyCells02);
  const [microscopycell03, setMicroscopyCell03] = useState(dmicroscopyCells03);
  const [microscopycell04, setMicroscopyCell04] = useState(dmicroscopyCells04);
  const [microscopyvalue01, setMicroscopyValue01] =
    useState(dmicroscopyValues01);
  const [microscopyvalue02, setMicroscopyValue02] =
    useState(dmicroscopyValues02);
  const [microscopyvalue03, setMicroscopyValue03] =
    useState(dmicroscopyValues03);
  const [microscopyvalue04, setMicroscopyValue04] =
    useState(dmicroscopyValues04);
  const [cultueTitle, setCultureTitle] = useState(dcultureCTitle);
  const [cultueSubHeading, setCultureSubHeading] =
    useState(dcultureCSubHeading);
  const [cultue1Header, setCulture1Header] = useState(dcultureC1Header);
  const [cultue2Header, setCulture2Header] = useState(dcultureC2Header);
  const [createAntibiotics01, setCreateAntibiotics01] = useState(antibiotics01);
  const [createSensitivity01, setCreateSensitivity01] = useState(sensitivity01);
  const [createAntibiotics02, setCreateAntibiotics02] = useState(antibiotics02);
  const [createSensitivity02, setCreateSensitivity02] = useState(sensitivity02);
  const [createAntibiotics03, setCreateAntibiotics03] = useState(antibiotics03);
  const [createSensitivity03, setCreateSensitivity03] = useState(sensitivity03);
  const [createAntibiotics04, setCreateAntibiotics04] = useState(antibiotics04);
  const [createSensitivity04, setCreateSensitivity04] = useState(sensitivity04);
  const [createAntibiotics05, setCreateAntibiotics05] = useState(antibiotics05);
  const [createSensitivity05, setCreateSensitivity05] = useState(sensitivity05);
  const [createAntibiotics06, setCreateAntibiotics06] = useState(antibiotics06);
  const [createSensitivity06, setCreateSensitivity06] = useState(sensitivity06);
  const [createAntibiotics07, setCreateAntibiotics07] = useState(antibiotics07);
  const [createSensitivity07, setCreateSensitivity07] = useState(sensitivity07);
  const [createAntibiotics08, setCreateAntibiotics08] = useState(antibiotics08);
  const [createSensitivity08, setCreateSensitivity08] = useState(sensitivity08);
  const [createAntibiotics09, setCreateAntibiotics09] = useState(antibiotics09);
  const [createSensitivity09, setCreateSensitivity09] = useState(sensitivity09);
  const [createAntibiotics10, setCreateAntibiotics10] = useState(antibiotics10);
  const [createSensitivity10, setCreateSensitivity10] = useState(sensitivity10);
  const [createDate, setCreateDate] = useState(date);
  const [checkedBy, setCheckedby] = useState(checkedby);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "hvsandurine", id);
      await updateDoc(taskDocument, {
        dmcsCTitles: mcsTitle,
        date: createDate,
        dmcsCSubheading: mcsSubheading,
        dmicroscopyHeader: microscopyheader,
        dmicroscopyCells01: microscopycell01,
        dmicroscopyCells02: microscopycell02,
        dmicroscopyCells03: microscopycell03,
        dmicroscopyCells04: microscopycell04,
        dmicroscopyValues01: microscopyvalue01,
        dmicroscopyValues02: microscopyvalue02,
        dmicroscopyValues03: microscopyvalue03,
        dmicroscopyValues04: microscopyvalue04,
        dcultureCTitle: cultueTitle,
        dcultureCSubHeading: cultueSubHeading,
        dcultureC1Header: cultue1Header,
        dcultureC2Header: cultue2Header,
        antibiotics01: createAntibiotics01,
        sensitivity01: createSensitivity01,
        antibiotics02: createAntibiotics02,
        sensitivity02: createSensitivity02,
        antibiotics03: createAntibiotics03,
        sensitivity03: createSensitivity03,
        antibiotics04: createAntibiotics04,
        sensitivity04: createSensitivity04,
        antibiotics05: createAntibiotics05,
        sensitivity05: createSensitivity05,
        antibiotics06: createAntibiotics06,
        sensitivity06: createSensitivity06,
        antibiotics07: createAntibiotics07,
        sensitivity07: createSensitivity07,
        antibiotics08: createAntibiotics08,
        sensitivity08: createSensitivity08,
        antibiotics09: createAntibiotics09,
        sensitivity09: createSensitivity09,
        antibiotics10: createAntibiotics10,
        sensitivity10: createSensitivity10,
        checkedby: checkedBy,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update MCS
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      M/C/S TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter M/C/S Report Title"
                    defaultValue={mcsTitle}
                    onChange={(e) => setMcsTitle(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      MICROBIOLOGY SUB HEADING
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Microbiology Sub Heading"
                    defaultValue={mcsSubheading}
                    onChange={(e) => setMcsSubheading(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      MICROSCOPY HEADING
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Microscopy Heading"
                    defaultValue={microscopyheader}
                    onChange={(e) => setMicroscopyHeader(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Microscopy Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={microscopycell01}
                    onChange={(e) => setMicroscopyCell01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={microscopyvalue01}
                    onChange={(e) => setMicroscopyValue01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      Microscopy Row2AData and Row2BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2AData"
                    defaultValue={microscopycell02}
                    onChange={(e) => setMicroscopyCell02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2BData"
                    defaultValue={microscopyvalue02}
                    onChange={(e) => setMicroscopyValue02(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      Microscopy Row3AData and Row3BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3AData"
                    defaultValue={microscopycell03}
                    onChange={(e) => setMicroscopyCell03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3BData"
                    defaultValue={microscopyvalue03}
                    onChange={(e) => setMicroscopyValue03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      Microscopy Row4AData and Row4BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4AData"
                    defaultValue={microscopycell04}
                    onChange={(e) => setMicroscopyCell04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4BData"
                    defaultValue={microscopyvalue04}
                    onChange={(e) => setMicroscopyValue04(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      CULTUE HEADING
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Culture Heading"
                    defaultValue={cultueTitle}
                    onChange={(e) => setCultureTitle(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      CULTUE SUBHEADING
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Culture SubHeading"
                    defaultValue={cultueSubHeading}
                    onChange={(e) => setCultureSubHeading(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      ANTIBIOTICS AND SENSITIVITY ROW HEADER
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={cultue1Header}
                    placeholder="Enter Row1HeaderTitle"
                    onChange={(e) => setCulture1Header(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2HeaderTitle"
                    defaultValue={cultue2Header}
                    onChange={(e) => setCulture2Header(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      Culture Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={createAntibiotics01}
                    onChange={(e) => setCreateAntibiotics01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={createSensitivity01}
                    onChange={(e) => setCreateSensitivity01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-02">
                      Culture Row2AData and Row2BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2AData"
                    defaultValue={createAntibiotics02}
                    onChange={(e) => setCreateAntibiotics02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2BData"
                    defaultValue={createSensitivity02}
                    onChange={(e) => setCreateSensitivity02(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-03">
                      Culture Row3AData and Row3BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3AData"
                    defaultValue={createAntibiotics03}
                    onChange={(e) => setCreateAntibiotics03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3BData"
                    defaultValue={createSensitivity03}
                    onChange={(e) => setCreateSensitivity03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-04">
                      Culture Row4AData and Row4BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4AData"
                    defaultValue={createAntibiotics04}
                    onChange={(e) => setCreateAntibiotics04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4BData"
                    defaultValue={createSensitivity04}
                    onChange={(e) => setCreateSensitivity04(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-05">
                      Culture Row5AData and Row5BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5AData"
                    defaultValue={createAntibiotics05}
                    onChange={(e) => setCreateAntibiotics05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5BData"
                    defaultValue={createSensitivity05}
                    onChange={(e) => setCreateSensitivity05(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-06">
                      Culture Row6AData and Row6BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6AData"
                    defaultValue={createAntibiotics06}
                    onChange={(e) => setCreateAntibiotics06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6BData"
                    defaultValue={createSensitivity06}
                    onChange={(e) => setCreateSensitivity06(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-07">
                      Culture Row7AData and Row7BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7AData"
                    defaultValue={createAntibiotics07}
                    onChange={(e) => setCreateAntibiotics07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7BData"
                    defaultValue={createSensitivity07}
                    onChange={(e) => setCreateSensitivity07(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-08">
                      Culture Row8AData and Row8BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8AData"
                    defaultValue={createAntibiotics08}
                    onChange={(e) => setCreateAntibiotics08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8BData"
                    defaultValue={createSensitivity08}
                    onChange={(e) => setCreateSensitivity08(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-09">
                      Culture Row9AData and Row9BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9AData"
                    defaultValue={createAntibiotics09}
                    onChange={(e) => setCreateAntibiotics09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9BData"
                    defaultValue={createSensitivity09}
                    onChange={(e) => setCreateSensitivity09(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-10">
                      Culture Row10AData and Row10BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10AData"
                    defaultValue={createAntibiotics10}
                    onChange={(e) => setCreateAntibiotics10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10BData"
                    defaultValue={createSensitivity10}
                    onChange={(e) => setCreateSensitivity10(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      CHECKED BY
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Checked by"
                    defaultValue={checkedBy}
                    onChange={(e) => setCheckedby(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportHvs;
