import "../../components/sidebar/sidebar.scss";
// import "./sidebar.scss";

import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import InventoryIcon from "@mui/icons-material/Inventory";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import NotesIcon from "@mui/icons-material/Notes";
import RateReviewIcon from "@mui/icons-material/RateReview";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HandshakeIcon from "@mui/icons-material/Handshake";

import { Link, useParams } from "react-router-dom";
import logo from "../../bgImg/logo.png";
import { useMediaQuery } from "react-responsive";

const Sidebar = () => {
  const { userId } = useParams();

  const mobileMode = useMediaQuery({ query: "(max-width: 568px)" });

  return (
    <div className="sidebar sticky-xl-top">
      <div className="top sticky-top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">
            <img src={logo} alt="logo" />
            <p>greenleaf</p>
          </span>
        </Link>
      </div>

      <hr />
      <div className="center menu">
        <ul>
          <p className="title">MAIN</p>

          <Link
            to={`/users/${userId}/casefolder`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <FolderCopyIcon className="icon" />
              <span className="titleHead">Case Folder</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/medicalhistory`}
            style={{ textDecoration: "none" }}
          >
            <p className="title">Old Folders</p>

            <Link
              to={`/users/${userId}/previousfolder`}
              style={{ textDecoration: "none" }}
            >
              <li>
                <CloudUploadIcon className="icon" />
                <span className="titleHead">Previous Folder</span>
              </li>
            </Link>

            <p className="title">Lists</p>
            <li>
              <MedicalInformationIcon className="icon" />
              <span className="titleHead">Medical Information</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/consultationnotes`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <DocumentScannerIcon className="icon" />
              <span className="titleHead">Consultation Notes</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/nursingnotes`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <NotesIcon className="icon" />
              <span className="titleHead">Nursing Notes</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/treatmentsheets`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <HistoryEduIcon className="icon" />
              <span className="titleHead">Sheets&#91;Treatments&#93;</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/consentform`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <HandshakeIcon className="icon" />
              <span className="titleHead">Consent Form&#91;s&#93;</span>
            </li>
          </Link>

          <p className="title">Findings</p>
          <Link
            to={`/users/${userId}/investigations`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <VaccinesIcon className="icon" />
              <span className="titleHead">Investigations</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/procedures`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <HealthAndSafetyIcon className="icon" />
              <span className="titleHead">Procedures</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/treatments`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <MonitorHeartIcon className="icon" />
              <span className="titleHead">Treatments</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/cryopreservations`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <InventoryIcon className="icon" />
              <span className="titleHead">Cryo Preservations</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/reviewofresult`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <RateReviewIcon className="icon" />
              <span className="titleHead">Review Of Result</span>
            </li>
          </Link>

          <p className="title">Summary</p>

          <Link
            to={`/users/${userId}/pharmacy`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <LocalPharmacyIcon className="icon" />
              <span className="titleHead">Pharmacy</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/billing`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <LocalAtmIcon className="icon" />
              <span className="titleHead">Billing</span>
            </li>
          </Link>

          <Link
            to={`/users/${userId}/reports`}
            style={{ textDecoration: "none" }}
          >
            <li>
              <SummarizeIcon className="icon" />
              <span className="titleHead">Reports</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
