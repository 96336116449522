import "./viewreportlftb.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportLftb = () => {
  const [lftb, setLftb] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { lftbId } = useParams();

  // let id = serId

  useEffect(() => {
    const getser = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "totalLftb", lftbId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setLftb(theData);
      } else {
        setLftb({});
      }
    };
    getser(lftbId);
  }, [lftbId]);

  return (
    <div className="viewreportlftb">
      <div className="headerlftb">
        <div>
          <h1>
            <img src={logo} alt="reportLogo" />
            &nbsp;Greenleaf Clinic
          </h1>
        </div>

        <span>
          <i>
            <HomeIcon className="icon" />
            &nbsp;Address: No.1 Segun Adekoya Str,
          </i>
          <br />
          <i> Coker Estate, Akowonjo 100275, Lagos </i>
          <br />
          <i>
            <PhoneInTalkIcon className="icon" />
            &nbsp;Contact: 08180073995
          </i>
          <br />
          <i>
            <AlternateEmailIcon className="icon" />
            &nbsp;Email: info@greenleafclinic.org
          </i>
          <br />
          <i>
            <OpenInBrowserIcon className="icon" />
            &nbsp;Website: www.greenleafclinic.org
          </i>
          <br />
          {/* <i>
            <LocalHospitalIcon className="icon" />
            &nbsp;Portal: www.accessgreenleaf.com{" "}
          </i> */}
        </span>
      </div>

      <hr />

      <div className="lftbpatientsdetail">
        <div>
          <p>
            <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
            {users.firstName}&nbsp;{users.lastName}
          </p>
          <p>
            <strong>Email Address:</strong>&nbsp;{users.email}
          </p>
          <p>
            <strong>Date:</strong>&nbsp;{lftb.date}
          </p>
        </div>
        <div>
          <p>
            <strong>Age:</strong>&nbsp;{users.age}
          </p>
          <p>
            <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
          </p>
          <p>
            <strong>Occupation:</strong>&nbsp;{users.occupation}
          </p>
        </div>
        <div>
          <p>
            <strong>Sex:</strong>&nbsp;{users.sex}
          </p>
          <p>
            <strong>Registration No:</strong>&nbsp;{users.regNo}
          </p>
          <p>
            <strong>State of Origin:</strong>&nbsp;{users.state}
          </p>
        </div>
      </div>

      <hr />

      <div className="header">
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          {lftb.reportTitle}
        </h2>
        <u>
          <p style={{ textAlign: "center" }}>{lftb.subHeader}</p>
        </u>
      </div>

      <div className="coveragelftb">
        <div className="overalllftb">
          <div className="headerlftb">
            <p>{lftb.row1Header}</p>
            <p>{lftb.row2Header}</p>
            <p>{lftb.row3Header}</p>
          </div>
          <div className="contentlftb">
            {/* Investigation vis result */}
            <section style={{ marginTop: "8px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation01}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result01}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference01}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation02}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result02}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference02}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation03}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result03}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference03}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation04}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result04}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference04}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation05}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result05}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference05}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation06}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result06}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference06}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation07}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result07}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference07}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation08}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result08}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference08}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "-38px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation09}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result09}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference09}</p>
              </section>
            </section>
            {/* Investigation vis result */}

            {/* Investigation vis result */}
            <section style={{ marginTop: "8px" }}>
              <section className="lftb" style={{ marginLeft: "18px" }}>
                <p>{lftb.investigation10}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "250px" }}
              >
                <p>{lftb.result10}</p>
              </section>
              <section
                className="lftb"
                style={{ marginTop: "-41.0px", marginLeft: "400px" }}
              >
                <p>{lftb.reference10}</p>
              </section>
            </section>
            {/* Investigation vis result */}
          </div>
        </div>
      </div>

      {/* Ending Div */}
    </div>
  );
};

export default ViewReportLftb;
