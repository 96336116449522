import "./widget.scss";
import { KeyboardArrowUpOutlined, PersonOutlined } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase";

const Widget = ({ type }) => {
  const [totalQuery, setTotalQuery] = useState(null);
  const [diff, setDiff] = useState(null);

  let data;

  switch (type) {
    case "client":
      data = {
        title: "CLIENTS",
        query: "greenleaf",
        link: "See all users",
        icon: (
          <PersonOutlined
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "events":
      data = {
        title: "EVENTS",
        query: "calendar",
        link: "View Events Log",
        icon: (
          <EventAvailableIcon
            className="icon"
            style={{
              color: "goldenrod",
              backgroundColor: "rgba(218, 165, 32, 0.2)",
            }}
          />
        ),
      };
      break;
    case "alerts":
      data = {
        title: "ALERTS",
        query: "notifications",
        link: "Get Notified",
        icon: (
          <NotificationsActiveOutlinedIcon
            className="icon"
            style={{ color: "green", backgroundColor: "rgba(133, 255, 159)" }}
          />
        ),
      };
      break;
    case "task":
      data = {
        title: "WAITING ROOM",
        query: "tasks",
        link: "Pending",
        icon: (
          <HourglassTopIcon
            className="icon"
            style={{
              color: "purple",
              backgroundColor: "rgba(248, 116, 252)",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  async function fetchTotal() {
    const coll = collection(db, data.query);
    const snapshot = await getCountFromServer(coll);
    setTotalQuery(snapshot.data().count);
  }

  fetchTotal();

  return (
    <>
      <div className="widget">
        <div className="left">
          <span className="title">{data.title}</span>
          <span className="counter">
            {data.isMoney && "$"} {totalQuery}
          </span>{" "}
          {/*db*/}
          <span className="link"> {data.link} </span>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpOutlined />
            {/* {diff} % */}
          </div>
          {data.icon}
        </div>
      </div>
    </>
  );
};

export default Widget;
