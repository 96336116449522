import "./sonoreport.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import EditSonoReport from "./EditSonoReport";
import { useParams } from "react-router-dom";

const SonoReport = () => {
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [createDate, setCreateDate] = useState("");
  // const [createName, setCreateName] = useState("");
  const [createAge, setCreateAge] = useState("");
  const [createLmp, setCreateLmp] = useState("");
  const [createCervical, setCreateCervical] = useState("");
  const [createDepth, setCreateDepth] = useState("");
  const [createPolyps, setCreatePolyps] = useState("");
  const [createFibroids, setCreateFibroids] = useState("");
  const [createOther, setCreateOther] = useState("");
  const [createPhysician, setCreatePhysician] = useState("");
  const [checked, setChecked] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "sonoreport");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((list) => {
          let tasksData = list.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
          setChecked(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  //Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        date: createDate,
        // sname : createName,
        age: createAge,
        lmp: createLmp,
        is1Yes: false,
        is1No: false,
        cervical: createCervical,
        depth: createDepth,
        is2Yes: false,
        is2No: false,
        polyps: createPolyps,
        is3Yes: false,
        is3No: false,
        fibroids: createFibroids,
        is4Yes: false,
        is4No: false,
        other: createOther,
        physician: createPhysician,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  let id = userId;
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  // 1.Yes
  const checkBox1Yes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is1Yes: !state[indexToUpdate].is1Yes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is1Yes;
        transaction.update(docRef, { is1Yes: newValue });
      });
    } catch (error) {}
  };

  // 1.No
  const checkBox1No = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is1No: !state[indexToUpdate].is1No,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is1No;
        transaction.update(docRef, { is1No: newValue });
      });
    } catch (error) {}
  };

  // 2.Yes
  const checkBox2Yes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is2Yes: !state[indexToUpdate].is2Yes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is2Yes;
        transaction.update(docRef, { is2Yes: newValue });
      });
    } catch (error) {}
  };

  // 2.No
  const checkBox2No = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is2No: !state[indexToUpdate].is2No,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is2No;
        transaction.update(docRef, { is2No: newValue });
      });
    } catch (error) {}
  };

  // 3.Yes
  const checkBox3Yes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is3Yes: !state[indexToUpdate].is3Yes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is3Yes;
        transaction.update(docRef, { is3Yes: newValue });
      });
    } catch (error) {}
  };

  // 3.No
  const checkBox3No = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is3No: !state[indexToUpdate].is3No,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is3No;
        transaction.update(docRef, { is3No: newValue });
      });
    } catch (error) {}
  };

  // 4.Yes
  const checkBox4Yes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is4Yes: !state[indexToUpdate].is4Yes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is4Yes;
        transaction.update(docRef, { is4Yes: newValue });
      });
    } catch (error) {}
  };

  // 4.No
  const checkBox4No = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        is4No: !state[indexToUpdate].is4No,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(
        db,
        "greenleaf/" + userId,
        "sonoreport",
        e.target.name
      );
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().is4No;
        transaction.update(docRef, { is4No: newValue });
      });
    } catch (error) {}
  };

  return (
    <>
      <div className="sonoreport">
        <p>Sono Report</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#sonoreportModal"
        >
          Add Report
        </button>

        {tasks.map(
          ({
            id,
            date,
            age,
            lmp,
            cervical,
            depth,
            polyps,
            fibroids,
            other,
            physician,
            is1Yes,
            is1No,
            is2Yes,
            is2No,
            is3Yes,
            is3No,
            is4Yes,
            is4No,
          }) => (
            <>
              <div className="sonohistory" key={id}>
                <div className="sonodata">
                  <div className="sonodata1">
                    <span>
                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Date:&nbsp;</strong>
                          {date}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Name:&nbsp;</strong> {users.title}{" "}
                          {users.firstName} {users.lastName}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Age:&nbsp;</strong>
                          {age}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>LMP:&nbsp;</strong>
                          {lmp}
                        </p>
                      </span>

                      <span className="pim2">
                        <p>
                          <strong>Patient duly counseled?&nbsp;</strong>
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={is1Yes}
                          onChange={(e) => checkBox1Yes(e)}
                          name={id}
                        />{" "}
                        <p>Yes</p>
                        <input
                          type="checkbox"
                          defaultChecked={is1No}
                          onChange={(e) => checkBox1No(e)}
                          name={id}
                        />{" "}
                        <p>No</p>
                      </span>

                      <span className="pim">
                        <p>
                          <strong>Cervical Accessibility:&nbsp;</strong>
                          {cervical}
                        </p>
                      </span>

                      <span className="pim">
                        <p>
                          <strong>Endometrial Depth:&nbsp;</strong>
                          {depth}
                        </p>
                      </span>

                      <span className="pim2">
                        <p>
                          <strong>Endometrium Dilatable?&nbsp;</strong>
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={is2Yes}
                          onChange={(e) => checkBox2Yes(e)}
                          name={id}
                        />{" "}
                        <p>Yes</p>
                        <input
                          type="checkbox"
                          defaultChecked={is2No}
                          onChange={(e) => checkBox2No(e)}
                          name={id}
                        />{" "}
                        <p>No</p>
                      </span>

                      <span className="pim2">
                        <p>
                          <strong>Endometrial Polyps:&nbsp;</strong>
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={is3Yes}
                          onChange={(e) => checkBox3Yes(e)}
                          name={id}
                        />{" "}
                        <p>Yes</p>
                        <input
                          type="checkbox"
                          defaultChecked={is3No}
                          onChange={(e) => checkBox3No(e)}
                          name={id}
                        />{" "}
                        <p>No</p>
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>If yes, size and location:&nbsp;</strong>{" "}
                          {polyps}{" "}
                        </p>
                      </span>

                      <span className="pim2">
                        <p>
                          <strong>Fibroids?&nbsp;</strong>
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={is4Yes}
                          onChange={(e) => checkBox4Yes(e)}
                          name={id}
                        />{" "}
                        <p>Yes</p>
                        <input
                          type="checkbox"
                          defaultChecked={is4No}
                          onChange={(e) => checkBox4No(e)}
                          name={id}
                        />{" "}
                        <p>No</p>
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>If yes, size and location:&nbsp;</strong>{" "}
                          {fibroids}{" "}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Other Comments:&nbsp;</strong>
                          {other}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Physician in Charge:&nbsp;</strong>
                          {physician}
                        </p>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-end">
                <button type="button" className="btn-pri">
                  <EditSonoReport
                    id={id}
                    date={date}
                    age={age}
                    lmp={lmp}
                    cervical={cervical}
                    depth={depth}
                    polyps={polyps}
                    fibroids={fibroids}
                    other={other}
                    physician={physician}
                  />
                </button>
              </div>
            </>
          )
        )}
        {/* Ending Div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="sonoreportModal"
        tabindex="-1"
        aria-labelledby="sonoreportModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="sonoreportModalLabel">
                  Sono Report
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        DATE
                      </span>
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setCreateDate(e.target.value)}
                    />
                  </div>

                  {/* <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="pair-01">NAME</span>
        </div>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Name.."
            onChange={(e) => setCreateName(e.target.value)}
          />
          </div> */}

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-02">
                        AGE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter age..."
                      onChange={(e) => setCreateAge(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-03">
                        LMP
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter LMP..."
                      onChange={(e) => setCreateLmp(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-04">
                        CERVICAL ACCESSIBILITY
                      </span>
                    </div>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Cervical Accessibility..."
                      onChange={(e) => setCreateCervical(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-05">
                        ENDOMETRIAL DEPTH
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Endometrial Depth..."
                      onChange={(e) => setCreateDepth(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-06">
                        ENDOMETRIAL POLYPS
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Endometrial Polyps..."
                      onChange={(e) => setCreatePolyps(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-07">
                        FIBROIDS
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Fibroids..."
                      onChange={(e) => setCreateFibroids(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-08">
                        OTHER COMMENTS
                      </span>
                    </div>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Other Comments..."
                      onChange={(e) => setCreateOther(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-09">
                        PHYSICIAN IN CHARGE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Physician in Charge..."
                      onChange={(e) => setCreatePhysician(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Report
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SonoReport;
