import "./trackbills.scss";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EditTrackbills from "./EditTrackbills";
import moment from "moment";

const TrackBills = () => {
  const [tasks, setTasks] = useState([]);
  const [createDesc, setCreateDesc] = useState("");
  const [createBill, setCreateBill] = useState(0);
  const [createDate, setCreateDate] = useState("");
  const [createComment, setCreateComment] = useState("");
  const [createAmount, setCreateAmount] = useState(0);
  const [createMode, setCreateMode] = useState("");
  const [createBal, setCreateBal] = useState(0);
  const [createStatus, setCreateStatus] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "billing");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "desc"));
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  useEffect(() => {
    setCreateBal(parseInt(createBill) - parseInt(createAmount));
  }, [createBill, createAmount]);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        desc: createDesc,
        bill: createBill,
        date: createDate,
        comment: createComment,
        amount: createAmount,
        mode: createMode,
        bal: createBal,
        status: createStatus,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  moment.updateLocale("en", {
    invalidDate: "",
  });

  return (
    <div className="trackbills">
      <p>Billing Allocated</p>
      <hr />

      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#trackbillingModal"
      >
        Add Billing
      </button>

      {/* Table Components */}
      <div className="billingWrapper" style={{ marginTop: "30px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Date</th>
              <th style={{ textAlign: "center" }}>Description</th>
              <th style={{ textAlign: "center" }}>Bill</th>
              <th style={{ textAlign: "center" }}>Comment</th>
              <th style={{ textAlign: "center" }}>Amount Paid</th>
              <th style={{ textAlign: "center" }}>Mode Of Payment</th>
              <th style={{ textAlign: "center" }}>Balance</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Edit</th>
              <th style={{ textAlign: "center" }}>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(
              ({
                id,
                date,
                desc,
                bill,
                comment,
                amount,
                mode,
                bal,
                status,
              }) => (
                <tr key={id}>
                  <td>{moment(date).format("MMMM Do YYYY")}</td>
                  <td>{desc}</td>
                  <td>
                    ₦{bill.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>{comment}</td>
                  <td>
                    ₦{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>{mode}</td>
                  <td>
                    ₦{bal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>{status}</td>
                  <td>
                    {" "}
                    <button type="button" className="icon">
                      <EditTrackbills
                        id={id}
                        date={date}
                        desc={desc}
                        bill={bill}
                        comment={comment}
                        amount={amount}
                        mode={mode}
                        bal={bal}
                        status={status}
                      />
                    </button>
                  </td>
                  <td>
                    <Link to={`/users/${userId}/billing/receipt/${id}`}>
                      <button type="button" className="btn-prime">
                        <ReceiptIcon />
                      </button>
                    </Link>
                  </td>
                </tr>
              )
            )}
          </tbody>

          {/* Mapping those data in the doc alternative */}
          {/* <tbody>
         {Object.keys(tasks).map((id)=> {
           return(
             <tr>
               <td>{tasks[id].name}</td>
               <td>{tasks[id].date}</td>
               <td>{tasks[id].docto}</td>
               <td>{tasks[id].status}</td>
             </tr>
           )
         })}
       </tbody> */}
          {/* Mapping those data in the doc alternative */}
        </table>
      </div>
      {/* Table Components */}

      {/* Modal */}
      <div
        className="modal fade"
        id="trackbillingModal"
        tabindex="-1"
        aria-labelledby="trackbillingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="trackbillingModalLabel">
                  Billing
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      BILL
                    </span>
                  </div>
                  <input
                    type="number"
                    value={createBill}
                    className="form-control"
                    placeholder="enter bill"
                    onChange={(e) => setCreateBill(e.target.value)}
                    size="3080"
                  />
                </div>

                <input
                  type="text"
                  className="form-control"
                  placeholder="enter description"
                  onChange={(e) => setCreateDesc(e.target.value)}
                  size="3080"
                />
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setCreateDate(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="comment here..."
                  className="form-control"
                  onChange={(e) => setCreateComment(e.target.value)}
                  size="3080"
                />

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      AMOUNT PAID
                    </span>
                  </div>
                  <input
                    type="number"
                    value={createAmount}
                    placeholder="enter amount paid"
                    className="form-control"
                    onChange={(e) => setCreateAmount(e.target.value)}
                    size="3080"
                  />
                </div>

                <input
                  type="text"
                  placeholder="Mode of Payment"
                  className="form-control"
                  onChange={(e) => setCreateMode(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  placeholder="set status"
                  className="form-control"
                  onChange={(e) => setCreateStatus(e.target.value)}
                  size="3080"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Billing
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrackBills;
