import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";

const EditEmbryoReport = ({
  id,
  timeAt,
  date,
  comments,
  oocytes,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
  twentyone,
  twentytwo,
  twentythree,
  twentyfour,
  twentyfive,
  twentysix,
  twentyseven,
  twentyeight,
  twentynine,
  thirty,
}) => {
  const [createTime, setCreateTime] = useState(timeAt);
  const [createDate, setCreateDate] = useState(date);
  const [createComments, setCreateComments] = useState(comments);
  const [createOocytes, setCreateOocytes] = useState(oocytes);
  const [createOne, setCreateOne] = useState(one);
  const [createTwo, setCreateTwo] = useState(two);
  const [createThree, setCreateThree] = useState(three);
  const [createFour, setCreateFour] = useState(four);
  const [createFive, setCreateFive] = useState(five);
  const [createSix, setCreateSix] = useState(six);
  const [createSeven, setCreateSeven] = useState(seven);
  const [createEight, setCreateEight] = useState(eight);
  const [createNine, setCreateNine] = useState(nine);
  const [createTen, setCreateTen] = useState(ten);
  const [createEleven, setCreateEleven] = useState(eleven);
  const [createTwelve, setCreateTwelve] = useState(twelve);
  const [createThirteen, setCreateThirteen] = useState(thirteen);
  const [createFourteen, setCreateFourteen] = useState(fourteen);
  const [createFifteen, setCreateFifteen] = useState(fifteen);
  const [createSixteen, setCreateSixteen] = useState(sixteen);
  const [createSeventeen, setCreateSeventeen] = useState(seventeen);
  const [createEighteen, setCreateEighteen] = useState(eighteen);
  const [createNineteen, setCreateNineteen] = useState(nineteen);
  const [createTwenty, setCreateTwenty] = useState(twenty);
  const [createTwentyOne, setCreateTwentyOne] = useState(twentyone);
  const [createTwentyTwo, setCreateTwentyTwo] = useState(twentytwo);
  const [createTwentyThree, setCreateTwentyThree] = useState(twentythree);
  const [createTwentyFour, setCreateTwentyFour] = useState(twentyfour);
  const [createTwentyFive, setCreateTwentyFive] = useState(twentyfive);
  const [createTwentySix, setCreateTwentySix] = useState(twentysix);
  const [createTwentySeven, setCreateTwentySeven] = useState(twentyseven);
  const [createTwentyEight, setCreateTwentyEight] = useState(twentyeight);
  const [createTwentyNine, setCreateTwentyNine] = useState(twentynine);
  const [createThirty, setCreateThirty] = useState(thirty);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "embryoReport", id);
      await updateDoc(taskDocument, {
        timeAt: createTime,
        date: createDate,
        comments: createComments,
        oocytes: createOocytes,
        one: createOne,
        two: createTwo,
        three: createThree,
        four: createFour,
        five: createFive,
        six: createSix,
        seven: createSeven,
        eight: createEight,
        nine: createNine,
        ten: createTen,
        eleven: createEleven,
        twelve: createTwelve,
        thirteen: createThirteen,
        fourteen: createFourteen,
        fifteen: createFifteen,
        sixteen: createSixteen,
        seventeen: createSeventeen,
        eighteen: createEighteen,
        nineteen: createNineteen,
        twenty: createTwenty,
        twentyone: createTwentyOne,
        twentytwo: createTwentyTwo,
        twentythree: createTwentyThree,
        twentyfour: createTwentyFour,
        twentyfive: createTwentyFive,
        twentysix: createTwentySix,
        twentyseven: createTwentySeven,
        twentyeight: createTwentyEight,
        twentynine: createTwentyNine,
        thirty: createThirty,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update EmbyoReport
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter time"
                    defaultValue={createTime}
                    onChange={(e) => setCreateTime(e.target.value)}
                    size="3080"
                  />

                  <input
                    type="date"
                    className="form-control"
                    defaultValue={createDate}
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter comments"
                    defaultValue={createComments}
                    onChange={(e) => setCreateComments(e.target.value)}
                    size="3080"
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter oocytes"
                    defaultValue={createOocytes}
                    onChange={(e) => setCreateOocytes(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Time and Oocytes Header Listing
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      One vis oocytes and Two vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from one vis oocytes"
                    className="form-control"
                    defaultValue={createOne}
                    onChange={(e) => setCreateOne(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from two vis oocytes"
                    className="form-control"
                    defaultValue={createTwo}
                    onChange={(e) => setCreateTwo(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from three vis oocytes"
                    className="form-control"
                    defaultValue={createThree}
                    onChange={(e) => setCreateThree(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from four vis oocytes"
                    className="form-control"
                    defaultValue={createFour}
                    onChange={(e) => setCreateFour(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      Three vis oocytes and Four vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      Five vis oocytes and Six vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from five vis oocytes"
                    className="form-control"
                    defaultValue={createFive}
                    onChange={(e) => setCreateFive(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from six vis oocytes"
                    className="form-control"
                    defaultValue={createSix}
                    onChange={(e) => setCreateSix(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from seven vis oocytes"
                    className="form-control"
                    defaultValue={createSeven}
                    onChange={(e) => setCreateSeven(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from eight vis oocytes"
                    className="form-control"
                    defaultValue={createEight}
                    onChange={(e) => setCreateEight(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5">
                      Seven vis oocytes and Eight vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6">
                      Nine vis oocytes and Ten vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from nine vis oocytes"
                    className="form-control"
                    defaultValue={createNine}
                    onChange={(e) => setCreateNine(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from ten vis oocytes"
                    className="form-control"
                    defaultValue={createTen}
                    onChange={(e) => setCreateTen(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from eleven vis oocytes"
                    className="form-control"
                    defaultValue={createEleven}
                    onChange={(e) => setCreateEleven(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twelve vis oocytes"
                    className="form-control"
                    defaultValue={createTwelve}
                    onChange={(e) => setCreateTwelve(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-7">
                      Eleven vis oocytes and Twelve vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-8">
                      Thirteen vis oocytes and Fourteen vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from thirteen vis oocytes"
                    className="form-control"
                    defaultValue={createThirteen}
                    onChange={(e) => setCreateThirteen(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from fourteen vis oocytes"
                    className="form-control"
                    defaultValue={createFourteen}
                    onChange={(e) => setCreateFourteen(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from fifteen vis oocytes"
                    className="form-control"
                    defaultValue={createFifteen}
                    onChange={(e) => setCreateFifteen(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from sixteen vis oocytes"
                    className="form-control"
                    defaultValue={createSixteen}
                    onChange={(e) => setCreateSixteen(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-9">
                      Fifteen vis oocytes and Sixteen vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-10">
                      Seventeen vis oocytes and Eighteen vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from seventeen vis oocytes"
                    className="form-control"
                    defaultValue={createSeventeen}
                    onChange={(e) => setCreateSeventeen(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from eighteen vis oocytes"
                    className="form-control"
                    defaultValue={createEighteen}
                    onChange={(e) => setCreateEighteen(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from nineteen vis oocytes"
                    className="form-control"
                    defaultValue={createNineteen}
                    onChange={(e) => setCreateNineteen(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twenty vis oocytes"
                    className="form-control"
                    defaultValue={createTwenty}
                    onChange={(e) => setCreateTwenty(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-11">
                      Nineteen vis oocytes and Twenty vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-12">
                      TwentyOne vis oocytes and TwentyTwo vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from twentyone vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyOne}
                    onChange={(e) => setCreateTwentyOne(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twentytwo vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyTwo}
                    onChange={(e) => setCreateTwentyTwo(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from twentythree vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyThree}
                    onChange={(e) => setCreateTwentyThree(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twentyfour vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyFour}
                    onChange={(e) => setCreateTwentyFour(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-13">
                      TwentyThree vis oocytes and TwentyFour vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-14">
                      TwentyFive vis oocytes and TwentySix vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from twentyfive vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyFive}
                    onChange={(e) => setCreateTwentyFive(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twentysix vis oocytes"
                    className="form-control"
                    defaultValue={createTwentySix}
                    onChange={(e) => setCreateTwentySix(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from twentyseven vis oocytes"
                    className="form-control"
                    defaultValue={createTwentySeven}
                    onChange={(e) => setCreateTwentySeven(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from twentyeight vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyEight}
                    onChange={(e) => setCreateTwentyEight(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-15">
                      TwentySeven vis oocytes and TwentyEight vis oocytes
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-16">
                      TwentyNine vis oocytes and Thirty vis oocytes
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from twentynine vis oocytes"
                    className="form-control"
                    defaultValue={createTwentyNine}
                    onChange={(e) => setCreateTwentyNine(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from thirty vis oocytes"
                    className="form-control"
                    defaultValue={createThirty}
                    onChange={(e) => setCreateThirty(e.target.value)}
                    size="3080"
                  />
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update EmbryoReport
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmbryoReport;
