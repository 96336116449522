import "./reportmaleassay.scss";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import EditReportMaleAssay from "./EditReportMaleAssay";

const ReportMaleAssay = () => {
  const [tasks, setTasks] = useState([]);
  const [createTitle, setCreateTitle] = useState("");
  const [createHormoneTitle, setCreateHormoneTitle] = useState("");
  const [createHormone01, setCreateHormone01] = useState("");
  const [createHormone02, setCreateHormone02] = useState("");
  const [createHormone03, setCreateHormone03] = useState("");
  const [createHormone04, setCreateHormone04] = useState("");
  const [createHormone05, setCreateHormone05] = useState("");
  const [createHormone06, setCreateHormone06] = useState("");
  const [createHormone07, setCreateHormone07] = useState("");
  const [createHormone08, setCreateHormone08] = useState("");
  const [createHormone09, setCreateHormone09] = useState("");
  const [createHormone10, setCreateHormone10] = useState("");
  const [createDaysTitle, setCreateDaysTitle] = useState("");
  const [createDaymone01, setCreateDaymone01] = useState("");
  const [createDaymone02, setCreateDaymone02] = useState("");
  const [createDaymone03, setCreateDaymone03] = useState("");
  const [createDaymone04, setCreateDaymone04] = useState("");
  const [createDaymone05, setCreateDaymone05] = useState("");
  const [createDaymone06, setCreateDaymone06] = useState("");
  const [createDaymone07, setCreateDaymone07] = useState("");
  const [createDaymone08, setCreateDaymone08] = useState("");
  const [createDaymone09, setCreateDaymone09] = useState("");
  const [createDaymone10, setCreateDaymone10] = useState("");
  const [createMipTitle, setCreateMipTitle] = useState("");
  const [createMip01, setCreateMip01] = useState("");
  const [createMip02, setCreateMip02] = useState("");
  const [createMip03, setCreateMip03] = useState("");
  const [createMip04, setCreateMip04] = useState("");
  const [createMip05, setCreateMip05] = useState("");
  const [createMip06, setCreateMip06] = useState("");
  const [createMip07, setCreateMip07] = useState("");
  const [createMip08, setCreateMip08] = useState("");
  const [createMip09, setCreateMip09] = useState("");
  const [createMip10, setCreateMip10] = useState("");
  const [createUnitTitle, setCreateUnitTitle] = useState("");
  const [createUnit01, setCreateUnit01] = useState("");
  const [createUnit02, setCreateUnit02] = useState("");
  const [createUnit03, setCreateUnit03] = useState("");
  const [createUnit04, setCreateUnit04] = useState("");
  const [createUnit05, setCreateUnit05] = useState("");
  const [createUnit06, setCreateUnit06] = useState("");
  const [createUnit07, setCreateUnit07] = useState("");
  const [createUnit08, setCreateUnit08] = useState("");
  const [createUnit09, setCreateUnit09] = useState("");
  const [createUnit10, setCreateUnit10] = useState("");
  const [createRangeTitle, setCreateRangeTitle] = useState("");
  const [createRange01, setCreateRange01] = useState("");
  const [createRange02, setCreateRange02] = useState("");
  const [createRange03, setCreateRange03] = useState("");
  const [createRange04, setCreateRange04] = useState("");
  const [createRange05, setCreateRange05] = useState("");
  const [createRange06, setCreateRange06] = useState("");
  const [createRange07, setCreateRange07] = useState("");
  const [createRange08, setCreateRange08] = useState("");
  const [createRange09, setCreateRange09] = useState("");
  const [createRange10, setCreateRange10] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createEmbryoDegree, setCreateEmbryoDegree] = useState("");
  const [createEmbryoSub, setCreateEmbryoSub] = useState("");
  const [createEmbryoTag, setCreateEmbryoTag] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const reportTitles = ["MALE HORMONAL ASSAY"];

  const rowTitles = [
    "Hormones",
    "Day 21-23",
    "Male Infertility Profile",
    "Units",
    "Normal Range",
  ];

  const hormoneTitles = [
    "FSH",
    "LH",
    "PROLACTIN",
    "PROGESTERONE",
    "AMH",
    "TESTOSTERONE",
    "TSH",
    "T4",
    "T3",
  ];

  const unitsSet = ["mIU/ml", "ng/ml", "nmol/L"];

  const normalRange = [
    "1.59 - 19.41",
    "1.24 - 7.8",
    "0 - 15",
    "0 - 12",
    "4.79 - 5.35",
    "7.63 - 36.4",
  ];

  const embryoData = [
    "Dr Daramola Adeleke, MBBS, M.Sc (UK), PGD, M.D of Medicine",
    "Senior Clinical Embryologist/Andrologist, Chartered Biologist and Scientist",
    "Embryologist",
  ];

  const { userId } = useParams();

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  const collectionRef = collection(db, "greenleaf/" + userId, "reportmha");

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        reportTitle: createTitle,
        date: createDate,
        hormoneTitle: createHormoneTitle,
        hormone01: createHormone01,
        hormone02: createHormone02,
        hormone03: createHormone03,
        hormone04: createHormone04,
        hormone05: createHormone05,
        hormone06: createHormone06,
        hormone07: createHormone07,
        hormone08: createHormone08,
        hormone09: createHormone09,
        hormone10: createHormone10,
        daysTitle: createDaysTitle,
        dayMone01: createDaymone01,
        dayMone02: createDaymone02,
        dayMone03: createDaymone03,
        dayMone04: createDaymone04,
        dayMone05: createDaymone05,
        dayMone06: createDaymone06,
        dayMone07: createDaymone07,
        dayMone08: createDaymone08,
        dayMone09: createDaymone09,
        dayMone10: createDaymone10,
        mipTitle: createMipTitle,
        mip01: createMip01,
        mip02: createMip02,
        mip03: createMip03,
        mip04: createMip04,
        mip05: createMip05,
        mip06: createMip06,
        mip07: createMip07,
        mip08: createMip08,
        mip09: createMip09,
        mip10: createMip10,
        unitTitle: createUnitTitle,
        unit01: createUnit01,
        unit02: createUnit02,
        unit03: createUnit03,
        unit04: createUnit04,
        unit05: createUnit05,
        unit06: createUnit06,
        unit07: createUnit07,
        unit08: createUnit08,
        unit09: createUnit09,
        unit10: createUnit10,
        rangeTitle: createRangeTitle,
        range01: createRange01,
        range02: createRange02,
        range03: createRange03,
        range04: createRange04,
        range05: createRange05,
        range06: createRange06,
        range07: createRange07,
        range08: createRange08,
        range09: createRange09,
        range10: createRange10,
        eDegree: createEmbryoDegree,
        eSub: createEmbryoSub,
        eTag: createEmbryoTag,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="reportmaleassay">
        <p>Male Hormonal Assay</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#mhaModal"
        >
          Add MHA
        </button>

        {/* Mapped Area */}
        <div className="mhaWrapper" style={{ marginTop: "30px" }}>
          {tasks.map(
            ({
              id,
              reportTitle,
              date,
              hormoneTitle,
              hormone01,
              hormone02,
              hormone03,
              hormone04,
              hormone05,
              hormone06,
              hormone07,
              hormone08,
              hormone09,
              hormone10,
              daysTitle,
              dayMone01,
              dayMone02,
              dayMone03,
              dayMone04,
              dayMone05,
              dayMone06,
              dayMone07,
              dayMone08,
              dayMone09,
              dayMone10,
              mipTitle,
              mip01,
              mip02,
              mip03,
              mip04,
              mip05,
              mip06,
              mip07,
              mip08,
              mip09,
              mip10,
              unitTitle,
              unit01,
              unit02,
              unit03,
              unit04,
              unit05,
              unit06,
              unit07,
              unit08,
              unit09,
              unit10,
              rangeTitle,
              range01,
              range02,
              range03,
              range04,
              range05,
              range06,
              range07,
              range08,
              range09,
              range10,
              eDegree,
              eSub,
              eTag,
            }) => (
              <>
                <div className="coveragemha">
                  <div className="overallmha" key={id}>
                    <div className="headermha">
                      <p>{hormoneTitle}</p>
                      <p>{daysTitle}</p>
                      <p>{mipTitle}</p>
                      <p>{unitTitle}</p>
                      <p>{rangeTitle}</p>
                    </div>
                    <div className="contentmha">
                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone01}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone01}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip01}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit01}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range01}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone02}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone02}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip02}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit02}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range02}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone03}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone03}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip03}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit03}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range03}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone04}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone04}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip04}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit04}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range04}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone05}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone05}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip05}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit05}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range05}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone06}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone06}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip06}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit06}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range06}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone07}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone07}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip07}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit07}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range07}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone08}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone08}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip08}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit08}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range08}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone09}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone09}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip09}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit09}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range09}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}

                      {/* Male Hormonal Assay Data*/}
                      <section style={{ marginTop: "8px" }}>
                        <section className="cms" style={{ marginLeft: "35px" }}>
                          <p>{hormone10}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "195px" }}
                        >
                          <p>{dayMone10}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "410px" }}
                        >
                          <p>{mip10}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "590px" }}
                        >
                          <p>{unit10}</p>
                        </section>
                        <section
                          className="cms"
                          style={{ marginTop: "-40.0px", marginLeft: "710px" }}
                        >
                          <p>{range10}</p>
                        </section>
                      </section>
                      {/* Male Hormonal Assay Data*/}
                    </div>
                  </div>
                </div>

                <div className="buttonflex">
                  <div>
                    {/* Our lovely button */}
                    <button type="button" className="btn-pri">
                      <EditReportMaleAssay
                        id={id}
                        reportTitle={reportTitle}
                        date={date}
                        hormoneTitle={hormoneTitle}
                        hormone01={hormone01}
                        hormone02={hormone02}
                        hormone03={hormone03}
                        hormone04={hormone04}
                        hormone05={hormone05}
                        hormone06={hormone06}
                        hormone07={hormone07}
                        hormone08={hormone08}
                        hormone09={hormone09}
                        hormone10={hormone10}
                        daysTitle={daysTitle}
                        dayMone01={dayMone01}
                        dayMone02={dayMone02}
                        dayMone03={dayMone03}
                        dayMone04={dayMone04}
                        dayMone05={dayMone05}
                        dayMone06={dayMone06}
                        dayMone07={dayMone07}
                        dayMone08={dayMone08}
                        dayMone09={dayMone09}
                        dayMone10={dayMone10}
                        mipTitle={mipTitle}
                        mip01={mip01}
                        mip02={mip02}
                        mip03={mip03}
                        mip04={mip04}
                        mip05={mip05}
                        mip06={mip06}
                        mip07={mip07}
                        mip08={mip08}
                        mip09={mip09}
                        mip10={mip10}
                        unitTitle={unitTitle}
                        unit01={unit01}
                        unit02={unit02}
                        unit03={unit03}
                        unit04={unit04}
                        unit05={unit05}
                        unit06={unit06}
                        unit07={unit07}
                        unit08={unit08}
                        unit09={unit09}
                        unit10={unit10}
                        rangeTitle={rangeTitle}
                        range01={range01}
                        range02={range02}
                        range03={range03}
                        range04={range04}
                        range05={range05}
                        range06={range06}
                        range07={range07}
                        range08={range08}
                        range09={range09}
                        range10={range10}
                        eDegree={eDegree}
                        eSub={eSub}
                        eTag={eTag}
                      />
                    </button>
                  </div>

                  {/* Our lovely button */}
                  <div>
                    <Link to={`/users/${userId}/reports/mha/${id}`}>
                      <button type="button" className="btn-prime">
                        <PreviewIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            )
          )}
        </div>

        {/* Mapped Area */}

        {/* Ending div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="mhaModal"
        tabindex="-1"
        aria-labelledby="mhaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="mhaModalLabel">
                  Male Hormonal Assay
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        REPORT TITLE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Report Title"
                      list="reportTitles"
                      onChange={(e) => setCreateTitle(e.target.value)}
                    />
                    <datalist id="reportTitles">
                      {reportTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  {/* Row Headers */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        ROW HEADERS
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Header"
                      list="rowTitles"
                      onChange={(e) => setCreateHormoneTitle(e.target.value)}
                    />
                    <datalist id="rowTitles">
                      {rowTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Header"
                      list="rowTitles"
                      onChange={(e) => setCreateDaysTitle(e.target.value)}
                    />
                    <datalist id="rowTitles">
                      {rowTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Header"
                      list="rowTitles"
                      onChange={(e) => setCreateMipTitle(e.target.value)}
                    />
                    <datalist id="rowTitles">
                      {rowTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Header"
                      list="rowTitles"
                      onChange={(e) => setCreateUnitTitle(e.target.value)}
                    />
                    <datalist id="rowTitles">
                      {rowTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Header"
                      list="rowTitles"
                      onChange={(e) => setCreateRangeTitle(e.target.value)}
                    />
                    <datalist id="rowTitles">
                      {rowTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Headers */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2">
                        1ST ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 1"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone01(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 1"
                      onChange={(e) => setCreateDaymone01(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 1"
                      onChange={(e) => setCreateMip01(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 1"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit01(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 1"
                      list="normalRange"
                      onChange={(e) => setCreateRange01(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3">
                        2ND ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 2"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone02(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 2"
                      onChange={(e) => setCreateDaymone02(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 2"
                      onChange={(e) => setCreateMip02(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 2"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit02(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 2"
                      list="normalRange"
                      onChange={(e) => setCreateRange02(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4">
                        3RD ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 3"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone03(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 3"
                      onChange={(e) => setCreateDaymone03(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 3"
                      onChange={(e) => setCreateMip03(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 3"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit03(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 3"
                      list="normalRange"
                      onChange={(e) => setCreateRange03(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5">
                        4TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 4"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone04(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 4"
                      onChange={(e) => setCreateDaymone04(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 4"
                      onChange={(e) => setCreateMip04(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 4"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit04(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 4"
                      list="normalRange"
                      onChange={(e) => setCreateRange04(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6">
                        5TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 5"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone05(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 5"
                      onChange={(e) => setCreateDaymone05(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 5"
                      onChange={(e) => setCreateMip05(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 5"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit05(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 5"
                      list="normalRange"
                      onChange={(e) => setCreateRange05(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-7">
                        6TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 6"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone06(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 6"
                      onChange={(e) => setCreateDaymone06(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 6"
                      onChange={(e) => setCreateMip06(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 6"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit06(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 6"
                      list="normalRange"
                      onChange={(e) => setCreateRange06(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-8">
                        7TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 7"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone07(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 7"
                      onChange={(e) => setCreateDaymone07(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 7"
                      onChange={(e) => setCreateMip07(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 7"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit07(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 7"
                      list="normalRange"
                      onChange={(e) => setCreateRange07(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-9">
                        8TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 8"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone08(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 8"
                      onChange={(e) => setCreateDaymone08(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 8"
                      onChange={(e) => setCreateMip08(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 8"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit08(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 8"
                      list="normalRange"
                      onChange={(e) => setCreateRange08(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-10">
                        9TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row 9"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone09(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row 9"
                      onChange={(e) => setCreateDaymone09(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row 9"
                      onChange={(e) => setCreateMip09(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row 9"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit09(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row 9"
                      list="normalRange"
                      onChange={(e) => setCreateRange09(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Row Counts */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-11">
                        10TH ROW DATA
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Row10"
                      list="hormoneTitles"
                      onChange={(e) => setCreateHormone10(e.target.value)}
                    />
                    <datalist id="hormoneTitles">
                      {hormoneTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Days Row10"
                      onChange={(e) => setCreateDaymone10(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter MIP Row10"
                      onChange={(e) => setCreateMip10(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Row10"
                      list="unitsSet"
                      onChange={(e) => setCreateUnit10(e.target.value)}
                    />
                    <datalist id="unitsSet">
                      {unitsSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Range Row10"
                      list="normalRange"
                      onChange={(e) => setCreateRange10(e.target.value)}
                    />
                    <datalist id="normalRange">
                      {normalRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                  {/* Row Counts */}

                  {/* Embryo Data */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-13">
                        EMBRYO DEGREE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Degree"
                      list="embryoData"
                      onChange={(e) => setCreateEmbryoDegree(e.target.value)}
                    />
                    <datalist id="embryoData">
                      {embryoData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-14">
                        SUB EMBRYO{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Cert Info"
                      list="embryoData"
                      onChange={(e) => setCreateEmbryoSub(e.target.value)}
                    />
                    <datalist id="embryoData">
                      {embryoData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-15">
                        EMBRYO TAG
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Tag"
                      list="embryoData"
                      onChange={(e) => setCreateEmbryoTag(e.target.value)}
                    />
                    <datalist id="embryoData">
                      {embryoData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportMaleAssay;
