export const userColumns = [
    { field: 'regNo', headerName: 'ID', width: 130 },
    { field: 'title', headerName: 'Title', width: 40 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 180 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 110 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 30,
    },
    { field: 'date', headerName: 'Date', width: 90, },
    { field: 'status', headerName:'Status', width: 70,
        renderCell:(params)=>{
            return (
                <div className={`cellWithStatus ${params.row.status}`}>
                  {params.row.status}  
                </div>
            )
        }
        },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 250,
      valueGetter: (params) =>
      `${params.row.title || ''} ${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
]
