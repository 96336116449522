import React, { useContext } from "react";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Chat from "./pages/chat/Chat";
import Stats from "./pages/stats/Stats";
import Room from "./pages/room/Room";
import Notification from "./pages/notification/Notification";
import "../src/style/dark.scss";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import Error from "./pages/error/Error";
import Register from "./pages/register/Register";
// import { userInputs } from './formSource';
import MedicalHsy from "./pages/medicalhsy/MedicalHsy";
import Consulation from "./pages/consultation/Consulation";
import Nursing from "./pages/nursing/Nursing";
import Bell from "./pages/bell/Bell";
import Investigations from "./pages/investigations/Investigations";
import Procedures from "./pages/proceedures/Procedures";
import Treatments from "./pages/treatments/Treatments";
import Pharmacy from "./pages/pharmacy/Pharmacy";
import Billing from "./pages/billing/Billing";
import CryoPreservations from "./pages/cryopreservations/CryoPreservations";
import ReviewResults from "./pages/reviewresults/ReviewResults";
// import DummyNotes from './components/dummynotes/DummyNotes';
import Reports from "./pages/reports/Reports";
import ViewReportMcs from "./components/reportmcs/ViewReportMcs";
import Husband from "./pages/husband/Husband";
import ViewReportMaleAssay from "./components/reportmaleassay/ViewReportMaleAssay";
import ViewReportEcs from "./components/reportecs/ViewReportEcs";
import Global from "./components/global/Global";
import ViewReportAmh from "./components/reportamh/ViewReportAmh";
import ViewReportFbc from "./components/reportfbc/ViewReportFbc";
import ViewReportHvs from "./components/reporthvs/ViewReportHvs";
import ViewReportUrinalysis from "./components/reporturinalysis/ViewReportUrinalysis";
import ViewReportLftb from "./components/reportlftb/ViewReportLftb";
import ViewReportFemaleAssay from "./components/reportfemaleassay/ViewReportFemaleAssay";
import TreatmentSheets from "./pages/treatmentsheets/TreatmentSheets";
import ViewBills from "./components/trackbills/ViewBills";
import PreviousFolder from "./pages/previousfolder/PreviousFolder";
import Events from "./pages/events/Events";
import ConsentForm from "./pages/consentForm/ConsentForm";
import Alarms from "./pages/alarms/Alarms";
// import DC from './components/dummycalendar/DC';

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Error />} />
            <Route path="register" element={<Register />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />

            {/* Dummy Calendar */}
            {/* <Route path="dc" element={<DC/>}/> */}
            {/* Dummy Calendar */}

            {/*Lists route/page*/}
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <New title="Update Current Client" btnUpd="Update" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New title="Add New Client" btnUpd="Send" />
                  </RequireAuth>
                }
              />
            </Route>

            {/*Alerts route/page*/}
            <Route
              path="chat"
              element={
                <RequireAuth>
                  <Chat />
                </RequireAuth>
              }
            />
            <Route
              path="stats"
              element={
                <RequireAuth>
                  <Stats />
                </RequireAuth>
              }
            />
            <Route
              path="room"
              element={
                <RequireAuth>
                  <Room />
                </RequireAuth>
              }
            />
            <Route
              path="events"
              element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              }
            />
            <Route
              path="notification"
              element={
                <RequireAuth>
                  <Notification />
                </RequireAuth>
              }
            />

            <Route path="users">
              <Route
                path=":userId/casefolder/"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/previousfolder/"
                element={
                  <RequireAuth>
                    <PreviousFolder />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/consentform/"
                element={
                  <RequireAuth>
                    <ConsentForm />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/medicalhistory"
                element={
                  <RequireAuth>
                    <MedicalHsy />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/consultationnotes"
                element={
                  <RequireAuth>
                    <Consulation />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/nursingnotes"
                element={
                  <RequireAuth>
                    <Nursing />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reminder"
                element={
                  <RequireAuth>
                    <Bell />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/investigations"
                element={
                  <RequireAuth>
                    <Investigations />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/procedures"
                element={
                  <RequireAuth>
                    <Procedures />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/treatments"
                element={
                  <RequireAuth>
                    <Treatments />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/pharmacy"
                element={
                  <RequireAuth>
                    <Pharmacy />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/billing"
                element={
                  <RequireAuth>
                    <Billing />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/billing/receipt/:receiptId"
                element={
                  <RequireAuth>
                    <ViewBills />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/cryopreservations"
                element={
                  <RequireAuth>
                    <CryoPreservations />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reviewofresult"
                element={
                  <RequireAuth>
                    <ReviewResults />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/treatmentsheets"
                element={
                  <RequireAuth>
                    <TreatmentSheets />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports"
                element={
                  <RequireAuth>
                    <Reports />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Correct */}
            <Route path="users">
              <Route
                path=":userId/reports/culture/:cultureId"
                element={
                  <RequireAuth>
                    <ViewReportMcs />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/ecs/:ecsId"
                element={
                  <RequireAuth>
                    <ViewReportEcs />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/mha/:mhaId"
                element={
                  <RequireAuth>
                    <ViewReportMaleAssay />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/fmha/:fmhaId"
                element={
                  <RequireAuth>
                    <ViewReportFemaleAssay />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/serology/:serologyId"
                element={
                  <RequireAuth>
                    <ViewReportAmh />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/fbc/:fbcId"
                element={
                  <RequireAuth>
                    <ViewReportFbc />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/hvs/:hvsId"
                element={
                  <RequireAuth>
                    <ViewReportHvs />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/ury/:uryId"
                element={
                  <RequireAuth>
                    <ViewReportUrinalysis />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="users">
              <Route
                path=":userId/reports/lftb/:lftbId"
                element={
                  <RequireAuth>
                    <ViewReportLftb />
                  </RequireAuth>
                }
              />
            </Route>

            <Route
              path="global"
              element={
                <RequireAuth>
                  <Global />
                </RequireAuth>
              }
            />

            <Route
              path="alarm"
              element={
                <RequireAuth>
                  <Alarms />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
