import "./previousfolder.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import PreviousFiles from "../../components/previousfiles/PreviousFiles";


const PreviousFolder = () => {
  return (
    <div className='prevfolder'>
      <Sidebar/>
      <div className="prevContainer">
      <Navbar/>
      <div className="prev">
      <PreviousFiles/>
      </div>
      </div>
      </div>
  )
}

export default PreviousFolder