import "./sheets.scss";


const Sheets = () => {
  return (
    <div className="sheets">
    <h1>Treatment Sheets</h1>
  </div>
  )
}

export default Sheets