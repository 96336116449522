import "./stimulationsheet.scss";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditSimulationSheet from "./EditSimulationSheet";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

const StimulationSheet = () => {
  const stimulationHeader = [
    "DATE",
    "Centrotide",
    "Buserelin",
    "Menopur/Gonf/HMG",
    "HCG",
    "R.follicles",
    "L.follices",
    "Endometrium",
    "Signature",
  ];

  const [tasks, setTasks] = useState([]);
  const [createStimulSheet, setCreateStimulSheet] = useState("");
  const [createStart, setCreateStart] = useState("");
  const [createOne, setCreateOne] = useState("");
  const [createTwo, setCreateTwo] = useState("");
  const [createThree, setCreateThree] = useState("");
  const [createFour, setCreateFour] = useState("");
  const [createFive, setCreateFive] = useState("");
  const [createSix, setCreateSix] = useState("");
  const [createSeven, setCreateSeven] = useState("");
  const [createEight, setCreateEight] = useState("");
  const [createNine, setCreateNine] = useState("");
  const [createTen, setCreateTen] = useState("");
  const [createEleven, setCreateEleven] = useState("");
  const [createTwelve, setCreateTwelve] = useState("");
  const [createThirteen, setCreateThirteen] = useState("");
  const [createFourteen, setCreateFourteen] = useState("");
  const [createFifteen, setCreateFifteen] = useState("");
  const [createSixteen, setCreateSixteen] = useState("");
  const [createSeventeen, setCreateSeventeen] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(
    db,
    "greenleaf/" + userId,
    "stimulationTreatments"
  );

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "asc"));
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        stimulSheets: createStimulSheet,
        start: createStart,
        one: createOne,
        two: createTwo,
        three: createThree,
        four: createFour,
        five: createFive,
        six: createSix,
        seven: createSeven,
        eight: createEight,
        nine: createNine,
        ten: createTen,
        eleven: createEleven,
        twelve: createTwelve,
        thirteen: createThirteen,
        fourteen: createFourteen,
        fifteen: createFifteen,
        sixteen: createSixteen,
        seventeen: createSeventeen,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Handler
  const deleteTask = async (id) => {
    try {
      const del_task = window.confirm(
        "Are you sure you want to delete this report?"
      );
      if (del_task == true) {
        const documentRef = doc(
          db,
          "greenleaf/" + userId,
          "stimulationTreatments",
          id
        );
        await deleteDoc(documentRef);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="stimulationsheet">
        <p>STIMULATION TREATMENT SHEET</p>
        <hr />

        {/* Modal Button */}
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#stimulationModal"
        >
          Add Treatment
        </button>

        <div className="stimulWrapper" style={{ marginTop: "30px" }}>
          <table className="styled-table">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Day</th>
                <th style={{ textAlign: "center" }}>Start</th>
                <th style={{ textAlign: "center" }}>1</th>
                <th style={{ textAlign: "center" }}>2</th>
                <th style={{ textAlign: "center" }}>3</th>
                <th style={{ textAlign: "center" }}>4</th>
                <th style={{ textAlign: "center" }}>5</th>
                <th style={{ textAlign: "center" }}>6</th>
                <th style={{ textAlign: "center" }}>7</th>
                <th style={{ textAlign: "center" }}>8</th>
                <th style={{ textAlign: "center" }}>9</th>
                <th style={{ textAlign: "center" }}>10</th>
                <th style={{ textAlign: "center" }}>11</th>
                <th style={{ textAlign: "center" }}>12</th>
                <th style={{ textAlign: "center" }}>13</th>
                <th style={{ textAlign: "center" }}>14</th>
                <th style={{ textAlign: "center" }}>15</th>
                <th style={{ textAlign: "center" }}>16</th>
                <th style={{ textAlign: "center" }}>17</th>
                <th style={{ textAlign: "center" }}>Action</th>
                <th style={{ textAlign: "center" }}>Del</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(
                ({
                  id,
                  stimulSheets,
                  start,
                  one,
                  two,
                  three,
                  four,
                  five,
                  six,
                  seven,
                  eight,
                  nine,
                  ten,
                  eleven,
                  twelve,
                  thirteen,
                  fourteen,
                  fifteen,
                  sixteen,
                  seventeen,
                }) => (
                  <tr key={id}>
                    <td>{stimulSheets}</td>
                    <td>{start}</td>
                    <td>{one}</td>
                    <td>{two}</td>
                    <td>{three}</td>
                    <td>{four}</td>
                    <td>{five}</td>
                    <td>{six}</td>
                    <td>{seven}</td>
                    <td>{eight}</td>
                    <td>{nine}</td>
                    <td>{ten}</td>
                    <td>{eleven}</td>
                    <td>{twelve}</td>
                    <td>{thirteen}</td>
                    <td>{fourteen}</td>
                    <td>{fifteen}</td>
                    <td>{sixteen}</td>
                    <td>{seventeen}</td>
                    <td>
                      {" "}
                      <button type="button" className="icon">
                        <EditSimulationSheet
                          id={id}
                          stimulSheets={stimulSheets}
                          start={start}
                          one={one}
                          two={two}
                          three={three}
                          four={four}
                          five={five}
                          six={six}
                          seven={seven}
                          eight={eight}
                          nine={nine}
                          ten={ten}
                          eleven={eleven}
                          twelve={twelve}
                          thirteen={thirteen}
                          fourteen={fourteen}
                          fifteen={fifteen}
                          sixteen={sixteen}
                          seventeen={seventeen}
                        />
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger float-end"
                        onClick={() => deleteTask(id)}
                      >
                        <DeleteSweepIcon />
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* Table Components */}

        {/* Ending Div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="stimulationModal"
        tabindex="-1"
        aria-labelledby="stimulationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="stimulationModalLabel">
                  STIMULATION TREATMENT SHEET
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter stimulation sheet headers"
                      list="stimulationHeader"
                      onChange={(e) => setCreateStimulSheet(e.target.value)}
                      size="3080"
                    />
                    <datalist id="stimulationHeader">
                      {stimulationHeader.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Row Listing
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="start.."
                      onChange={(e) => setCreateStart(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-01">
                        Start
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2">
                        One vis Row Data and Two vis Row Data
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from one vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateOne(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from two vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateTwo(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from three vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateThree(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from four vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateFour(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3">
                        Three vis Row Data and Four vis Row Data
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4">
                        Five vis Row Data and Six vis Row Data
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from five vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateFive(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from six vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateSix(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from seven vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateSeven(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from eight vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateEight(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5">
                        Seven vis Row Data and Eight vis Row Data
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6">
                        Nine vis Row Data and Ten vis Row Data
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from nine vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateNine(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from ten vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateTen(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from eleven vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateEleven(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twelve vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateTwelve(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-7">
                        Eleven vis Row Data and Twelve vis Row Data
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-8">
                        Thirteen vis Row Data and Fourteen vis Row Data
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from thirteen vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateThirteen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from fourteen vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateFourteen(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from fifteen vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateFifteen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from sixteen vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateSixteen(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-9">
                        Fifteen vis Row Data and Sixteen vis Row Data
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-10">
                        Seventeen vis Row Data
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from seventeen vis Row Data"
                      className="form-control"
                      onChange={(e) => setCreateSeventeen(e.target.value)}
                      size="3080"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Treatment
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StimulationSheet;
