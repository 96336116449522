import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./featured.scss";
import { useState } from "react";
import { useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, onMessageListener, requestForToken } from "../../firebase";
import toast, { Toaster } from "react-hot-toast";

const Featured = () => {
  const [monthlyQuery, setMonthlyQuery] = useState(null);
  const [diff, setDiff] = useState(null);

  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    const fetchData = async () => {
      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
      const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

      const lastMonthQuery = query(
        collection(db, "greenleaf"),
        where("timeStamp", "<=", today),
        where("timeStamp", ">", lastMonth)
      );

      const prevMonthQuery = query(
        collection(db, "greenleaf"),
        where("timeStamp", "<=", lastMonth),
        where("timeStamp", ">", prevMonth)
      );

      const lastMonthData = await getDocs(lastMonthQuery);
      const prevMonthData = await getDocs(prevMonthQuery);

      setMonthlyQuery(lastMonthData.docs.length);
      setDiff(
        ((lastMonthData.docs.length - prevMonthData.docs.length) /
          lastMonthData.docs.length) *
          100
      );
    };
    fetchData();
  }, []);

  return (
    <>
      <Toaster />
      <div className="featured">
        <div className="top">
          <h1 className="title">Total IVF Cases</h1>
        </div>
        <div className="bottom">
          <div className="featuredChart">
            <CircularProgressbar value={65} text={"65"} strokeWidth={12} />
          </div>
          <p className="title">Total entries made last month</p>
          <p className="counts">{monthlyQuery}</p>
          <p className="desc">History is updated on a daily basis</p>
          <div className="summary">
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult positive">
                <KeyboardArrowDownOutlined fontSize="small" />
                <div className="resultCounts">10.2k</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult negative">
                <KeyboardArrowUpOutlined fontSize="small" />
                <div className="resultCounts">10.2k</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult positive">
                <KeyboardArrowDownOutlined fontSize="small" />
                <div className="resultCounts">10.2k</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
