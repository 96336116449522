import "./viewreportmaleassay.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportMaleAssay = () => {
  const [mha, setMha] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { mhaId } = useParams();

  useEffect(() => {
    const getMcs = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "reportmha", mhaId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setMha(theData);
      } else {
        setMha({});
      }
    };
    getMcs(mhaId);
  }, [mhaId]);

  return (
    <>
      <div className="viewreportmaleassay">
        <div className="headermha">
          <div>
            <h1>
              <img src={logo} alt="reportLogo" />
              &nbsp;Greenleaf Clinic
            </h1>
          </div>

          <span>
            <i>
              <HomeIcon className="icon" />
              &nbsp;Address: No.44, Yinka Folorunsho street, off Razak Banjoko,
            </i>
            <br />
            <i> Odofin road-Central Mosque Igbogbo, Ikorodu, Lagos, Nigeria.</i>
            <br />
            <i>
              <PhoneInTalkIcon className="icon" />
              &nbsp;Contact: 0916 925 7751
            </i>
            <br />
            <i>
              <AlternateEmailIcon className="icon" />
              &nbsp;Email: info@greenleafclinic.org
            </i>
            <br />
            <i>
              <OpenInBrowserIcon className="icon" />
              &nbsp;Website: www.greenleafclinic.org
            </i>
            <br />
            {/* <i>
              <LocalHospitalIcon className="icon" />
              &nbsp;Portal: www.accessgreenleaf.com{" "}
            </i> */}
          </span>
        </div>

        <hr />

        <div className="mhapatientsdetail">
          <div>
            <p>
              <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
              {users.firstName}&nbsp;{users.lastName}
            </p>
            <p>
              <strong>Email Address:</strong>&nbsp;{users.email}
            </p>
            <p>
              <strong>Date:</strong>&nbsp;{mha.date}
            </p>
          </div>
          <div>
            <p>
              <strong>Age:</strong>&nbsp;{users.age}
            </p>
            <p>
              <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
            </p>
            <p>
              <strong>Occupation:</strong>&nbsp;{users.occupation}
            </p>
          </div>
          <div>
            <p>
              <strong>Sex:</strong>&nbsp;{users.sex}
            </p>
            <p>
              <strong>Registration No:</strong>&nbsp;{users.regNo}
            </p>
            <p>
              <strong>State of Origin:</strong>&nbsp;{users.state}
            </p>
          </div>
        </div>

        <hr />

        <div className="header">
          <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
            {mha.reportTitle}
          </h2>
        </div>

        <div className="coveragemha">
          <div className="overallmha">
            <div className="headermha">
              <p>{mha.hormoneTitle}</p>
              <p>{mha.daysTitle}</p>
              <p>{mha.mipTitle}</p>
              <p>{mha.unitTitle}</p>
              <p>{mha.rangeTitle}</p>
            </div>
            <div className="contentmha">
              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range01}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range02}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range03}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range04}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range05}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range06}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone07}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone07}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip07}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit07}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range07}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              <br />

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone08}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone08}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip08}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit08}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range08}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone09}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone09}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip09}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit09}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range09}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}

              {/* Male Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "10px" }}>
                  <p>{mha.hormone10}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "150px" }}
                >
                  <p>{mha.dayMone10}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "310px" }}
                >
                  <p>{mha.mip10}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "450px" }}
                >
                  <p>{mha.unit10}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "550px" }}
                >
                  <p>{mha.range10}</p>
                </section>
              </section>
              {/* Male Hormonal Assay Data*/}
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <div className="embryoCollec">
          <p style={{ fontWeight: "bolder" }}>{mha.eDegree}</p>
          <p>{mha.eSub}</p>
          <br />
          <p>{mha.eTag}</p>
        </div>
      </div>
    </>
  );
};

export default ViewReportMaleAssay;
