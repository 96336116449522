import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";

const EditTrackbills = ({
  id,
  desc,
  bill,
  date,
  comment,
  amount,
  mode,
  bal,
  status,
}) => {
  const [createDesc, setCreateDesc] = useState(desc);
  const [createBill, setCreateBill] = useState(bill);
  const [createDate, setCreateDate] = useState(date);
  const [createComment, setCreateComment] = useState(comment);
  const [createAmount, setCreateAmount] = useState(amount);
  const [createMode, setCreateMode] = useState(mode);
  const [createBal, setCreateBal] = useState(bal);
  const [createStatus, setCreateStatus] = useState(status);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "billing", id);
      await updateDoc(taskDocument, {
        desc: createDesc,
        bill: createBill,
        date: createDate,
        comment: createComment,
        amount: createAmount,
        mode: createMode,
        bal: createBal,
        status: createStatus,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setCreateBal(parseInt(createBill) - parseInt(createAmount));
  }, [createBill, createAmount]);

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update Billing
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                placeholder="enter bill"
                defaultValue={createBill}
                onChange={(e) => setCreateBill(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                className="form-control"
                placeholder="enter description"
                defaultValue={createDesc}
                onChange={(e) => setCreateDesc(e.target.value)}
                size="3080"
              />
              <input
                type="date"
                className="form-control"
                defaultValue={createDate}
                onChange={(e) => setCreateDate(e.target.value)}
              />
              <input
                type="text"
                placeholder="comment here..."
                className="form-control"
                defaultValue={createComment}
                onChange={(e) => setCreateComment(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="enter amount paid"
                className="form-control"
                defaultValue={createAmount}
                onChange={(e) => setCreateAmount(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="Mode of Payment"
                className="form-control"
                defaultValue={createMode}
                onChange={(e) => setCreateMode(e.target.value)}
                size="3080"
              />
              <div
                type="text"
                className="form-control"
                onChange={(e) => setCreateBal(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="set status"
                className="form-control"
                defaultValue={createStatus}
                onChange={(e) => setCreateStatus(e.target.value)}
                size="3080"
              />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update Billing
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTrackbills;
