import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportEcs = ({
  id,
  antibiotics01,
  sensitivity01,
  antibiotics02,
  sensitivity02,
  antibiotics03,
  sensitivity03,
  antibiotics04,
  sensitivity04,
  antibiotics05,
  sensitivity05,
  antibiotics06,
  sensitivity06,
  antibiotics07,
  sensitivity07,
  antibiotics08,
  sensitivity08,
  antibiotics09,
  sensitivity09,
  antibiotics10,
  sensitivity10,
  row1Header,
  row2Header,
  reportTitle,
  date,
  subHeading,
  subReportTitle,
}) => {
  const [createAntibiotics01, setCreateAntibiotics01] = useState(antibiotics01);
  const [createSensitivity01, setCreateSensitivity01] = useState(sensitivity01);
  const [createAntibiotics02, setCreateAntibiotics02] = useState(antibiotics02);
  const [createSensitivity02, setCreateSensitivity02] = useState(sensitivity02);
  const [createAntibiotics03, setCreateAntibiotics03] = useState(antibiotics03);
  const [createSensitivity03, setCreateSensitivity03] = useState(sensitivity03);
  const [createAntibiotics04, setCreateAntibiotics04] = useState(antibiotics04);
  const [createSensitivity04, setCreateSensitivity04] = useState(sensitivity04);
  const [createAntibiotics05, setCreateAntibiotics05] = useState(antibiotics05);
  const [createSensitivity05, setCreateSensitivity05] = useState(sensitivity05);
  const [createAntibiotics06, setCreateAntibiotics06] = useState(antibiotics06);
  const [createSensitivity06, setCreateSensitivity06] = useState(sensitivity06);
  const [createAntibiotics07, setCreateAntibiotics07] = useState(antibiotics07);
  const [createSensitivity07, setCreateSensitivity07] = useState(sensitivity07);
  const [createAntibiotics08, setCreateAntibiotics08] = useState(antibiotics08);
  const [createSensitivity08, setCreateSensitivity08] = useState(sensitivity08);
  const [createAntibiotics09, setCreateAntibiotics09] = useState(antibiotics09);
  const [createSensitivity09, setCreateSensitivity09] = useState(sensitivity09);
  const [createAntibiotics10, setCreateAntibiotics10] = useState(antibiotics10);
  const [createSensitivity10, setCreateSensitivity10] = useState(sensitivity10);
  const [createRow1Header, setCreateRow1Header] = useState(row1Header);
  const [createRow2Header, setCreateRow2Header] = useState(row2Header);
  const [createTitle, setCreateTitle] = useState(reportTitle);
  const [createDate, setCreateDate] = useState(date);
  const [createSubHeading, setCreateSubHeading] = useState(subHeading);
  const [createSubReportTitle, setCreateSubReportTitle] =
    useState(subReportTitle);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "reportecs", id);
      await updateDoc(taskDocument, {
        antibiotics01: createAntibiotics01,
        sensitivity01: createSensitivity01,
        antibiotics02: createAntibiotics02,
        sensitivity02: createSensitivity02,
        antibiotics03: createAntibiotics03,
        sensitivity03: createSensitivity03,
        antibiotics04: createAntibiotics04,
        sensitivity04: createSensitivity04,
        antibiotics05: createAntibiotics05,
        sensitivity05: createSensitivity05,
        antibiotics06: createAntibiotics06,
        sensitivity06: createSensitivity06,
        antibiotics07: createAntibiotics07,
        sensitivity07: createSensitivity07,
        antibiotics08: createAntibiotics08,
        sensitivity08: createSensitivity08,
        antibiotics09: createAntibiotics09,
        sensitivity09: createSensitivity09,
        antibiotics10: createAntibiotics10,
        sensitivity10: createSensitivity10,
        row1Header: createRow1Header,
        row2Header: createRow2Header,
        reportTitle: createTitle,
        subHeading: createSubHeading,
        subReportTitle: createSubReportTitle,
        date: createDate,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update CULTURE
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REPORT TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Report Title"
                    defaultValue={createTitle}
                    onChange={(e) => setCreateTitle(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      SUB REPORT TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sub Report Title"
                    defaultValue={createSubReportTitle}
                    onChange={(e) => setCreateSubReportTitle(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      SUBHEADING
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter SubHeading"
                    defaultValue={createSubHeading}
                    onChange={(e) => setCreateSubHeading(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1HeaderTitle"
                    defaultValue={row1Header}
                    onChange={(e) => setCreateRow1Header(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2HeaderTitle"
                    defaultValue={row2Header}
                    onChange={(e) => setCreateRow2Header(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Row1HeaderTitle and Row2HeaderTitle
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={createAntibiotics01}
                    onChange={(e) => setCreateAntibiotics01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={createSensitivity01}
                    onChange={(e) => setCreateSensitivity01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2AData"
                    defaultValue={createAntibiotics02}
                    onChange={(e) => setCreateAntibiotics02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2BData"
                    defaultValue={createSensitivity02}
                    onChange={(e) => setCreateSensitivity02(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      Row2AData and Row2BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      Row3AData and Row3BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3AData"
                    defaultValue={createAntibiotics03}
                    onChange={(e) => setCreateAntibiotics03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3BData"
                    defaultValue={createSensitivity03}
                    onChange={(e) => setCreateSensitivity03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4AData"
                    defaultValue={createAntibiotics04}
                    onChange={(e) => setCreateAntibiotics04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4BData"
                    defaultValue={createSensitivity04}
                    onChange={(e) => setCreateSensitivity04(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      Row4AData and Row4BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5">
                      Row5AData and Row5BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5AData"
                    defaultValue={createAntibiotics05}
                    onChange={(e) => setCreateAntibiotics05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5BData"
                    defaultValue={createSensitivity05}
                    onChange={(e) => setCreateSensitivity05(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6AData"
                    defaultValue={createAntibiotics06}
                    onChange={(e) => setCreateAntibiotics06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6BData"
                    defaultValue={createSensitivity06}
                    onChange={(e) => setCreateSensitivity06(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6">
                      Row6AData and Row6BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-7">
                      Row7AData and Row7BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7AData"
                    defaultValue={createAntibiotics07}
                    onChange={(e) => setCreateAntibiotics07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7BData"
                    defaultValue={createSensitivity07}
                    onChange={(e) => setCreateSensitivity07(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8AData"
                    defaultValue={createAntibiotics08}
                    onChange={(e) => setCreateAntibiotics08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8BData"
                    defaultValue={createSensitivity08}
                    onChange={(e) => setCreateSensitivity08(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-8">
                      Row8AData and Row8BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-9">
                      Row9AData and Row9BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9AData"
                    defaultValue={createAntibiotics09}
                    onChange={(e) => setCreateAntibiotics09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9BData"
                    defaultValue={createSensitivity09}
                    onChange={(e) => setCreateSensitivity09(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10AData"
                    defaultValue={createAntibiotics10}
                    onChange={(e) => setCreateAntibiotics10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10BData"
                    defaultValue={createSensitivity10}
                    onChange={(e) => setCreateSensitivity10(e.target.value)}
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-10">
                      Row10AData and Row10BData
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportEcs;
