import Embryoalarm from "../../components/embryoalarm/Embryoalarm";
import Spermalarm from "../../components/spermalarm/Spermalarm";
import "./alarms.scss";

const Alarms = () => {
  return (
    <>
    <div className="alarms">
    <div className='alarmsSperm'>
       <Spermalarm/>
    </div>
    <div className='alarmsEmbryo'>
        <Embryoalarm/>
    </div>
    </div>
    </>
  )
}

export default Alarms
