import "./reportfemaleassay.scss";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import EditReportFemaleAssay from "./EditReportFemaleAssay";

const ReportFemaleAssay = () => {
  const [tasks, setTasks] = useState([]);
  const [createTitle, setCreateTitle] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createHormoneTitle, setCreateHormoneTitle] = useState("");
  const [createPhaseTitle, setCreatePhaseTitle] = useState("");
  const [createNmensTitle, setCreateNmensTitle] = useState("");
  const [createDaysTitle, setCreateDaysTitle] = useState("");
  const [createNDaysTitle, setCreateNDaysTitle] = useState("");
  const [createUnitsTitle, setCreateUnitsTitle] = useState("");
  const [createRangeTitle, setCreateRangeTitle] = useState("");
  const [createHormone01, setCreateHormone01] = useState("");
  const [createHormone02, setCreateHormone02] = useState("");
  const [createHormone03, setCreateHormone03] = useState("");
  const [createHormone04, setCreateHormone04] = useState("");
  const [createHormone05, setCreateHormone05] = useState("");
  const [createHormone06, setCreateHormone06] = useState("");
  const [createPhase01, setCreatePhase01] = useState("");
  const [createPhase02, setCreatePhase02] = useState("");
  const [createPhase03, setCreatePhase03] = useState("");
  const [createPhase04, setCreatePhase04] = useState("");
  const [createPhase05, setCreatePhase05] = useState("");
  const [createPhase06, setCreatePhase06] = useState("");
  const [createNmens01, setCreateNmens01] = useState("");
  const [createNmens02, setCreateNmens02] = useState("");
  const [createNmens03, setCreateNmens03] = useState("");
  const [createNmens04, setCreateNmens04] = useState("");
  const [createNmens05, setCreateNmens05] = useState("");
  const [createNmens06, setCreateNmens06] = useState("");
  const [createDays01, setCreateDays01] = useState("");
  const [createDays02, setCreateDays02] = useState("");
  const [createDays03, setCreateDays03] = useState("");
  const [createDays04, setCreateDays04] = useState("");
  const [createDays05, setCreateDays05] = useState("");
  const [createDays06, setCreateDays06] = useState("");
  const [createNDays01, setCreateNDays01] = useState("");
  const [createNDays02, setCreateNDays02] = useState("");
  const [createNDays03, setCreateNDays03] = useState("");
  const [createNDays04, setCreateNDays04] = useState("");
  const [createNDays05, setCreateNDays05] = useState("");
  const [createNDays06, setCreateNDays06] = useState("");
  const [createUnits01, setCreateUnits01] = useState("");
  const [createUnits02, setCreateUnits02] = useState("");
  const [createUnits03, setCreateUnits03] = useState("");
  const [createUnits04, setCreateUnits04] = useState("");
  const [createUnits05, setCreateUnits05] = useState("");
  const [createUnits06, setCreateUnits06] = useState("");
  const [createRange01, setCreateRange01] = useState("");
  const [createRange02, setCreateRange02] = useState("");
  const [createRange03, setCreateRange03] = useState("");
  const [createRange04, setCreateRange04] = useState("");
  const [createRange05, setCreateRange05] = useState("");
  const [createRange06, setCreateRange06] = useState("");
  const [createEmbryoDegree, setCreateEmbryoDegree] = useState("");
  const [createEmbryoSub, setCreateEmbryoSub] = useState("");
  const [createEmbryoTag, setCreateEmbryoTag] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const reportfmhaTitles = ["FEMALE HORMONAL ASSAY"];

  const rowfmhaTitles = [
    "Hormones",
    "Phases",
    "Non Men's",
    "Day 2-5",
    "Day 21-23",
    "Units",
    "Normal Range",
  ];

  const hormonefmhaTitles = [
    "FSH",
    "LH",
    "PROLACTIN",
    "PROGESTERONE",
    "AMH",
    "TESTOSTERONE",
    "TSH",
    "T4",
    "T3",
  ];

  const phasesfmhaData = [
    "Follicular",
    "Ovulation",
    "Luteal",
    "Menopause",
    "Early trimester of Pregnancy",
    "Mid trimester of Pregnancy",
  ];

  const unitsfmhaSet = ["mIU/ml", "ng/ml", "nmol/L"];

  const normalfmhaRange = [
    "1.59 - 19.41",
    "1.24 - 7.8",
    "0 - 12.8",
    "2 - 28",
    "0 - 15",
    "0 - 12",
    "4.79 - 5.35",
    "7.63 - 36.4",
    "3.88 - 8.84",
    "4.43 - 21.97",
    "1.75 - 5.18",
    "16.83 - 112.30",
    "2.12 - 10.84",
    "19.8 - 103",
    "1.2 - 12.86",
    "10.87 - 58.84",
    "5 - 20",
    "0 - 15.3",
    "0 - 1",
    "25.4 - 83.3",
  ];

  const embryofmhaData = [
    "Dr Daramola Adeleke, MBBS, M.Sc (UK), PGD, M.D of Medicine",
    "Senior Clinical Embryologist/Andrologist, Chartered Biologist and Scientist",
    "Embryologist",
  ];

  const { userId } = useParams();

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  const collectionRef = collection(db, "greenleaf/" + userId, "reportfmha");

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        reportTitle: createTitle,
        date: createDate,
        hormoneTitle: createHormoneTitle,
        phaseTitle: createPhaseTitle,
        nonMensTitle: createNmensTitle,
        daysTitle: createDaysTitle,
        ndaysTitle: createNDaysTitle,
        unitsTitle: createUnitsTitle,
        rangeTitle: createRangeTitle,
        hormone01: createHormone01,
        hormone02: createHormone02,
        hormone03: createHormone03,
        hormone04: createHormone04,
        hormone05: createHormone05,
        hormone06: createHormone06,
        phase01: createPhase01,
        phase02: createPhase02,
        phase03: createPhase03,
        phase04: createPhase04,
        phase05: createPhase05,
        phase06: createPhase06,
        nMens01: createNmens01,
        nMens02: createNmens02,
        nMens03: createNmens03,
        nMens04: createNmens04,
        nMens05: createNmens05,
        nMens06: createNmens06,
        days01: createDays01,
        days02: createDays02,
        days03: createDays03,
        days04: createDays04,
        days05: createDays05,
        days06: createDays06,
        ndays01: createNDays01,
        ndays02: createNDays02,
        ndays03: createNDays03,
        ndays04: createNDays04,
        ndays05: createNDays05,
        ndays06: createNDays06,
        units01: createUnits01,
        units02: createUnits02,
        units03: createUnits03,
        units04: createUnits04,
        units05: createUnits05,
        units06: createUnits06,
        range01: createRange01,
        range02: createRange02,
        range03: createRange03,
        range04: createRange04,
        range05: createRange05,
        range06: createRange06,
        eDegree: createEmbryoDegree,
        eSub: createEmbryoSub,
        eTag: createEmbryoTag,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="reportfemaleassay">
        <p>Female Hormonal Assay</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#fmhaModal"
        >
          Add FMHA
        </button>

        {/* Mapped Area */}
        {tasks.map(
          ({
            id,
            reportTitle,
            date,
            hormoneTitle,
            phaseTitle,
            nonMensTitle,
            daysTitle,
            ndaysTitle,
            unitsTitle,
            rangeTitle,
            hormone01,
            hormone02,
            hormone03,
            hormone04,
            hormone05,
            hormone06,
            phase01,
            phase02,
            phase03,
            phase04,
            phase05,
            phase06,
            nMens01,
            nMens02,
            nMens03,
            nMens04,
            nMens05,
            nMens06,
            days01,
            days02,
            days03,
            days04,
            days05,
            days06,
            ndays01,
            ndays02,
            ndays03,
            ndays04,
            ndays05,
            ndays06,
            units01,
            units02,
            units03,
            units04,
            units05,
            units06,
            range01,
            range02,
            range03,
            range04,
            range05,
            range06,
            eDegree,
            eSub,
            eTag,
          }) => (
            <>
              <div className="coveragefmha">
                <div className="overallfmha" key={id}>
                  <div className="headerfmha">
                    <p>{hormoneTitle}</p>
                    <p>{phaseTitle}</p>
                    <p>{nonMensTitle}</p>
                    <p>{daysTitle}</p>
                    <p>{ndaysTitle}</p>
                    <p>{unitsTitle}</p>
                    <p>{rangeTitle}</p>
                  </div>
                  <div className="contentfmha">
                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "8px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170px" }}
                      >
                        <p style={{ maxWidth: "90px", textAlign: "left" }}>
                          {phase01}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range01}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}

                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "8px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170PX" }}
                      >
                        <p style={{ maxWidth: "90px", textAlign: "left" }}>
                          {phase02}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range02}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}

                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "8px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170PX" }}
                      >
                        <p style={{ maxWidth: "90px", textAlign: "left" }}>
                          {phase03}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range03}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}

                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "8px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170PX" }}
                      >
                        <p style={{ maxWidth: "90px", textAlign: "left" }}>
                          {phase04}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range04}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}

                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "64px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170PX" }}
                      >
                        <p style={{ maxWidth: "98px", textAlign: "left" }}>
                          {phase05}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range05}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}

                    {/* Female Hormonal Assay Data*/}
                    <section style={{ marginTop: "64px" }}>
                      <section className="cms" style={{ marginLeft: "40px" }}>
                        <p>{hormone06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "170PX" }}
                      >
                        <p style={{ maxWidth: "98px", textAlign: "left" }}>
                          {phase06}
                        </p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "270px" }}
                      >
                        <p>{nMens06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                      >
                        <p>{days06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "500px" }}
                      >
                        <p>{ndays06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "620px" }}
                      >
                        <p>{units06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-40.0px", marginLeft: "730px" }}
                      >
                        <p>{range06}</p>
                      </section>
                    </section>
                    {/* Female Hormonal Assay Data*/}
                  </div>
                </div>
              </div>

              <div className="buttonflex">
                <div>
                  {/* Our lovely button */}
                  <button type="button" className="btn-pri">
                    <EditReportFemaleAssay
                      id={id}
                      reportTitle={reportTitle}
                      date={date}
                      hormoneTitle={hormoneTitle}
                      phaseTitle={phaseTitle}
                      nonMensTitle={nonMensTitle}
                      daysTitle={daysTitle}
                      ndaysTitle={ndaysTitle}
                      unitsTitle={unitsTitle}
                      rangeTitle={rangeTitle}
                      hormone01={hormone01}
                      hormone02={hormone02}
                      hormone03={hormone03}
                      hormone04={hormone04}
                      hormone05={hormone05}
                      hormone06={hormone06}
                      phase01={phase01}
                      phase02={phase02}
                      phase03={phase03}
                      phase04={phase04}
                      phase05={phase05}
                      phase06={phase06}
                      nMens01={nMens01}
                      nMens02={nMens02}
                      nMens03={nMens03}
                      nMens04={nMens04}
                      nMens05={nMens05}
                      nMens06={nMens06}
                      days01={days01}
                      days02={days02}
                      days03={days03}
                      days04={days04}
                      days05={days05}
                      days06={days06}
                      ndays01={ndays01}
                      ndays02={ndays02}
                      ndays03={ndays03}
                      ndays04={ndays04}
                      ndays05={ndays05}
                      ndays06={ndays06}
                      units01={units01}
                      units02={units02}
                      units03={units03}
                      units04={units04}
                      units05={units05}
                      units06={units06}
                      range01={range01}
                      range02={range02}
                      range03={range03}
                      range04={range04}
                      range05={range05}
                      range06={range06}
                      eDegree={eDegree}
                      eSub={eSub}
                      eTag={eTag}
                    />
                  </button>
                </div>

                {/* Our lovely button */}
                <div>
                  <Link to={`/users/${userId}/reports/fmha/${id}`}>
                    <button type="button" className="btn-prime">
                      <PreviewIcon />
                    </button>
                  </Link>
                </div>
              </div>
            </>
          )
        )}
        {/* Mapped Area */}

        {/* Ending Div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="fmhaModal"
        tabindex="-1"
        aria-labelledby="fmhaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="fmhaModalLabel">
                  Female Hormonal Assay
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        REPORT TITLE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Report Title"
                      list="reportfmhaTitles"
                      onChange={(e) => setCreateTitle(e.target.value)}
                    />
                    <datalist id="reportfmhaTitles">
                      {reportfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        HORMONE,PHASES,DAYS,UNITS,RANGE HEADER
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateHormoneTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phases Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreatePhaseTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateNmensTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateDaysTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateNDaysTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateUnitsTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Header"
                      list="rowfmhaTitles"
                      onChange={(e) => setCreateRangeTitle(e.target.value)}
                    />
                    <datalist id="rowfmhaTitles">
                      {rowfmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1A">
                        ROW 1A, 1B, 1C, 1D, 1E, 1F, 1G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone01(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase01(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens01(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays01(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays01(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits01(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange01(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2A">
                        ROW 2A, 2B, 2C, 2D, 2E, 2F, 2G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone02(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase02(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens02(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays02(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays02(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits02(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange02(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3A">
                        ROW 3A, 3B, 3C, 3D, 3E, 3F, 3G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone03(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase03(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens03(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays03(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays03(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits03(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange03(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4A">
                        ROW 4A, 4B, 4C, 4D, 4E, 4F, 4G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone04(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase04(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens04(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays04(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays04(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits04(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange04(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5A">
                        ROW 5A, 5B, 5C, 5D, 5E, 5F, 5G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone05(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase05(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens05(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays05(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays05(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits05(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange05(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6A">
                        ROW 6A, 6B, 6C, 6D, 6E, 6F, 6G VALUES
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Hormone Value"
                      list="hormonefmhaTitles"
                      onChange={(e) => setCreateHormone06(e.target.value)}
                    />
                    <datalist id="hormonefmhaTitles">
                      {hormonefmhaTitles.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phase Value"
                      list="phasesfmhaData"
                      onChange={(e) => setCreatePhase06(e.target.value)}
                    />
                    <datalist id="phasesfmhaData">
                      {phasesfmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Non Men's Value"
                      onChange={(e) => setCreateNmens06(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 2-5 Value"
                      onChange={(e) => setCreateDays06(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day 21-23 Value"
                      onChange={(e) => setCreateNDays06(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Units Value"
                      list="unitsfmhaSet"
                      onChange={(e) => setCreateUnits06(e.target.value)}
                    />
                    <datalist id="unitsfmhaSet">
                      {unitsfmhaSet.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Normal Range Value"
                      list="normalfmhaRange"
                      onChange={(e) => setCreateRange06(e.target.value)}
                    />
                    <datalist id="normalfmhaRange">
                      {normalfmhaRange.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  {/* Embryo Data */}
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-13">
                        EMBRYO DEGREE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Degree"
                      list="embryofmhaData"
                      onChange={(e) => setCreateEmbryoDegree(e.target.value)}
                    />
                    <datalist id="embryofmhaData">
                      {embryofmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-14">
                        SUB EMBRYO{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Cert Info"
                      list="embryofmhaData"
                      onChange={(e) => setCreateEmbryoSub(e.target.value)}
                    />
                    <datalist id="embryofmhaData">
                      {embryofmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-15">
                        EMBRYO TAG
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Embryo Tag"
                      list="embryofmhaData"
                      onChange={(e) => setCreateEmbryoTag(e.target.value)}
                    />
                    <datalist id="embryofmhaData">
                      {embryofmhaData.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportFemaleAssay;
