import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditGynaecological = ({
  id,
  age,
  lmp,
  cycle,
  color,
  regularity,
  hair,
  hormonal,
  fertility,
  hsgResult,
}) => {
  const [createAge, setCreateAge] = useState(age);
  const [createLmp, setCreateLmp] = useState(lmp);
  const [createCycle, setCreateCycle] = useState(cycle);
  const [createColor, setCreateColor] = useState(color);
  const [createRegularity, setCreateRegularity] = useState(regularity);
  const [createHair, setCreateHair] = useState(hair);
  const [createHormonal, setCreateHormonal] = useState(hormonal);
  const [createFertility, setCreateFertility] = useState(fertility);
  const [createHsg, setCreateHsg] = useState(hsgResult);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "gynaecology", id);
      await updateDoc(taskDocument, {
        age: createAge,
        lmp: createLmp,
        cycle: createCycle,
        color: createColor,
        hsgResult: createHsg,
        regularity: createRegularity,
        hair: createHair,
        hormonal: createHormonal,
        fertility: createFertility,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update Gynaelogical History
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-01">
                    AGE OF MENARCHE
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter age of menarche"
                  defaultValue={createAge}
                  onChange={(e) => setCreateAge(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-02">
                    L.M.P
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="L.M.P"
                  defaultValue={createLmp}
                  onChange={(e) => setCreateLmp(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-03">
                    CYCLE LENGTH
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cycle length"
                  defaultValue={createCycle}
                  onChange={(e) => setCreateCycle(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-04">
                    MENSTRUAL COLOR
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter menstrual color"
                  defaultValue={createColor}
                  onChange={(e) => setCreateColor(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-05">
                    MENSTRUAL REGULARITY
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter menstrual regularity"
                  defaultValue={createRegularity}
                  onChange={(e) => setCreateRegularity(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-06">
                    HAIR GROWTH
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter hair growth(chin/chest)"
                  defaultValue={createHair}
                  onChange={(e) => setCreateHair(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-07">
                    PREVIOUS HORMONAL ASSAY
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Previous hormonal assay"
                  defaultValue={createHormonal}
                  onChange={(e) => setCreateHormonal(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-07">
                    PREVIOUS HSG RESULT
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Previous Hsg Result"
                  defaultValue={createHsg}
                  onChange={(e) => setCreateHsg(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair-08">
                    PREVIOUS FERT TREATMENT
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Previous fertility treatment"
                  defaultValue={createFertility}
                  onChange={(e) => setCreateFertility(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update History
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGynaecological;
