import "./viewreporthvs.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportHvs = () => {
  const [hvs, setHvs] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { hvsId } = useParams();

  // let id = mcsId

  useEffect(() => {
    const getMcs = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "hvsandurine", hvsId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setHvs(theData);
      } else {
        setHvs({});
      }
    };
    getMcs(hvsId);
  }, [hvsId]);

  return (
    <>
      <div className="viewreporthvs">
        <div className="headerhvs">
          <div>
            <h1>
              <img src={logo} alt="reportLogo" />
              &nbsp;Greenleaf Clinic
            </h1>
          </div>

          <span>
            <i>
              <HomeIcon className="icon" />
              &nbsp;Address: No.44, Yinka Folorunsho street, off Razak Banjoko,
            </i>
            <br />
            <i> Odofin road-Central Mosque Igbogbo, Ikorodu, Lagos, Nigeria.</i>
            <br />
            <i>
              <PhoneInTalkIcon className="icon" />
              &nbsp;Contact: 0916 925 7751
            </i>
            <br />
            <i>
              <AlternateEmailIcon className="icon" />
              &nbsp;Email: info@greenleafclinic.org
            </i>
            <br />
            <i>
              <OpenInBrowserIcon className="icon" />
              &nbsp;Website: www.greenleafclinic.org
            </i>
            <br />
            {/* <i>
              <LocalHospitalIcon className="icon" />
              &nbsp;Portal: www.accessgreenleaf.com{" "}
            </i> */}
          </span>
        </div>

        <hr />

        <div className="hvspatientsdetail">
          <div>
            <p>
              <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
              {users.firstName}&nbsp;{users.lastName}
            </p>
            <p>
              <strong>Email Address:</strong>&nbsp;{users.email}
            </p>
            <p>
              <strong>Date:</strong>&nbsp;{hvs.date}
            </p>
          </div>
          <div>
            <p>
              <strong>Age:</strong>&nbsp;{users.age}
            </p>
            <p>
              <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
            </p>
            <p>
              <strong>Occupation:</strong>&nbsp;{users.occupation}
            </p>
          </div>
          <div>
            <p>
              <strong>Sex:</strong>&nbsp;{users.sex}
            </p>
            <p>
              <strong>Registration No:</strong>&nbsp;{users.regNo}
            </p>
            <p>
              <strong>State of Origin:</strong>&nbsp;{users.state}
            </p>
          </div>
        </div>

        <hr />

        {/* HVS OR URINE MCS */}
        <div className="header" style={{ marginTop: "64px" }}>
          <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
            {hvs.dmcsCTitles}
          </h2>
          <u>
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              {hvs.dmcsCSubheading}
            </h4>
          </u>
        </div>

        <div className="coveragehvs">
          <div className="overallhvs">
            <div className="headerhvs"></div>
            <div className="contenthvs">
              {/* Microscopy plus cells */}
              <section style={{ marginTop: "8px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <u>
                    <p style={{ fontWeight: "bold" }}>
                      {hvs.dmicroscopyHeader}
                    </p>
                  </u>
                </section>
              </section>
              {/* Microscopy plus cells */}

              {/* Microscopy plus cells */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.dmicroscopyCells01}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.dmicroscopyValues01}</p>
                </section>
              </section>
              {/* Microscopy plus cells */}

              {/* Microscopy plus cells */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.dmicroscopyCells02}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.dmicroscopyValues02}</p>
                </section>
              </section>
              {/* Microscopy plus cells */}

              {/* Microscopy plus cells */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.dmicroscopyCells03}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.dmicroscopyValues03}</p>
                </section>
              </section>
              {/* Microscopy plus cells */}

              {/* Microscopy plus cells */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.dmicroscopyCells04}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.dmicroscopyValues04}</p>
                </section>
              </section>
              {/* Microscopy plus cells */}
            </div>
          </div>
        </div>
        {/* HVS OR URINE MCS */}

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/* CULTURE */}
        <div className="header">
          <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
            {hvs.dcultureCTitle}
          </h2>
          <u>
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              {hvs.dcultureCSubHeading}
            </h4>
          </u>
        </div>

        <div className="coveragehvs">
          <div className="overallhvs">
            <div className="headerhvs">
              <p>{hvs.dcultureC1Header}</p>
              <p>{hvs.dcultureC2Header}</p>
            </div>
            <div className="contenthvs">
              {/* Antibiotics plus Sensitivity*/}
              <section style={{ marginTop: "8px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics01}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity01}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics02}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity02}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics03}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity03}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics04}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity04}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics05}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity05}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics06}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity06}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics07}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity07}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics08}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity08}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics09}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity09}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}

              {/* Antibiotics plus Sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="hvs" style={{ marginLeft: "18px" }}>
                  <p>{hvs.antibiotics10}</p>
                </section>
                <section
                  className="hvs"
                  style={{ marginTop: "-41.0px", marginLeft: "230px" }}
                >
                  <p>{hvs.sensitivity10}</p>
                </section>
              </section>
              {/* Antibiotics plus Sensitivity */}
            </div>
          </div>
        </div>

        {/* CULTURE */}

        <div className="sTags">
          <span>
            <p style={{ fontWeight: "bolder" }}>R= Resistant</p>
            <p style={{ fontWeight: "bolder" }}>S= Sensitive</p>
          </span>
        </div>

        <div className="chby">
          <p>Checked By</p>
        </div>

        <div className="sTags">
          <span>
            <p style={{ textAlign: "center" }}>{hvs.checkedby}</p>
            <hr style={{ marginTop: "-12px" }} />
            <p style={{ fontWeight: "bolder", marginTop: "-9px" }}>
              Med. Lab. Scientist in Charge
            </p>
          </span>
        </div>

        {/* Ending Div */}
      </div>
    </>
  );
};

export default ViewReportHvs;
