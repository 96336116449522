import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import {db} from "../../firebase";
import 'bootstrap/dist/css/bootstrap.min.css';


const EditTask = ({ task, id, clockout}) => {

  const [tasks, setTasks] = useState(task);
  const [createClock, setCreateClock] = useState(clockout);

  const updateTask = async (e) => {
    e.preventDefault()
    try {
      const taskDocument = doc(db, "tasks", id);
      await updateDoc(taskDocument, {
        task: tasks,
        clockout: createClock
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}>
        Update Room
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editLabel" style={{color:"green"}}>
                Update Room Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <form>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={tasks}
                  onChange={e => setTasks(e.target.value)}
                />

                 <input
                    type="time"
                    className="form-control"
                    defaultValue={createClock}
                    onChange={(e) => setCreateClock(e.target.value)}
                  />
              </form>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => updateTask(e)}
              >Update Room</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditTask