import "./viewreportfemaleassy.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportFemaleAssay = () => {
  const [fmha, setFmha] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { fmhaId } = useParams();

  useEffect(() => {
    const getMcs = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "reportfmha", fmhaId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setFmha(theData);
      } else {
        setFmha({});
      }
    };
    getMcs(fmhaId);
  }, [fmhaId]);

  return (
    <>
      <div className="viewreportfemaleassy">
        <div className="headerfmha">
          <div>
            <h1>
              <img src={logo} alt="reportLogo" />
              &nbsp;Greenleaf Clinic
            </h1>
          </div>

          <span>
            <i>
              <HomeIcon className="icon" />
              &nbsp;Address: No.44, Yinka Folorunsho street, off Razak Banjoko,
            </i>
            <br />
            <i> Odofin road-Central Mosque Igbogbo, Ikorodu, Lagos, Nigeria.</i>
            <br />
            <i>
              <PhoneInTalkIcon className="icon" />
              &nbsp;Contact: 0916 925 7751
            </i>
            <br />
            <i>
              <AlternateEmailIcon className="icon" />
              &nbsp;Email: info@greenleafclinic.org
            </i>
            <br />
            <i>
              <OpenInBrowserIcon className="icon" />
              &nbsp;Website: www.greenleafclinic.org
            </i>
            <br />
            {/* <i>
              <LocalHospitalIcon className="icon" />
              &nbsp;Portal: www.accessgreenleaf.com{" "}
            </i> */}
          </span>
        </div>

        <hr />

        <div className="fmhapatientsdetail">
          <div>
            <p>
              <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
              {users.firstName}&nbsp;{users.lastName}
            </p>
            <p>
              <strong>Email Address:</strong>&nbsp;{users.email}
            </p>
            <p>
              <strong>Date:</strong>&nbsp;{fmha.date}
            </p>
          </div>
          <div>
            <p>
              <strong>Age:</strong>&nbsp;{users.age}
            </p>
            <p>
              <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
            </p>
            <p>
              <strong>Occupation:</strong>&nbsp;{users.occupation}
            </p>
          </div>
          <div>
            <p>
              <strong>Sex:</strong>&nbsp;{users.sex}
            </p>
            <p>
              <strong>Registration No:</strong>&nbsp;{users.regNo}
            </p>
            <p>
              <strong>State of Origin:</strong>&nbsp;{users.state}
            </p>
          </div>
        </div>

        <hr style={{ marginTop: "-10px" }} />

        <div className="header">
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "-10px",
            }}
          >
            {fmha.reportTitle}
          </h3>
        </div>

        <div className="coveragefmha">
          <div className="overallfmha">
            <div className="headerfmha">
              <p>{fmha.hormoneTitle}</p>
              <p>{fmha.phaseTitle}</p>
              <p>{fmha.nonMensTitle}</p>
              <p>{fmha.daysTitle}</p>
              <p>{fmha.ndaysTitle}</p>
              <p>{fmha.unitsTitle}</p>
              <p>{fmha.rangeTitle}</p>
            </div>
            <div className="contentfmha">
              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "90px", textAlign: "left" }}>
                    {fmha.phase01}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range01}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}

              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "90px", textAlign: "left" }}>
                    {fmha.phase02}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range02}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}

              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "90px", textAlign: "left" }}>
                    {fmha.phase03}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range03}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}

              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "8px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "90px", textAlign: "left" }}>
                    {fmha.phase04}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range04}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}

              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "64px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "98px", textAlign: "left" }}>
                    {fmha.phase05}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range05}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}

              {/* Female Hormonal Assay Data*/}
              <section style={{ marginTop: "64px" }}>
                <section className="cms" style={{ marginLeft: "-15px" }}>
                  <p>{fmha.hormone06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "120px" }}
                >
                  <p style={{ maxWidth: "98px", textAlign: "left" }}>
                    {fmha.phase06}
                  </p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "210px" }}
                >
                  <p>{fmha.nMens06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "300px" }}
                >
                  <p>{fmha.days06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "400px" }}
                >
                  <p>{fmha.ndays06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "475px" }}
                >
                  <p>{fmha.units06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-40.0px", marginLeft: "540px" }}
                >
                  <p>{fmha.range06}</p>
                </section>
              </section>
              {/* Female Hormonal Assay Data*/}
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <div className="embryoCollec">
          <p style={{ fontWeight: "bolder" }}>{fmha.eDegree}</p>
          <p>{fmha.eSub}</p>
          <br />
          <p>{fmha.eTag}</p>
        </div>
      </div>
    </>
  );
};

export default ViewReportFemaleAssay;
