import "./room.scss"
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/navbar/Navbar';
import RoomManager from '../../components/roommanager/RoomManager';
import WaitingRoom from '../../components/trackusers/WaitingRoom';


const Room = () => {
  return (
    <div className='room'>
    <Sidebar/>
    <div className="roomContainer">
    <Navbar/>
    <div className="roommanage">
    <RoomManager
    room="Welcome to the Waiting Room"
    />
    </div>
    <div className="trackusers">
      <WaitingRoom/>
    </div>
    </div>
    </div>
  )
}

export default Room

