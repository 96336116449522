import { useEffect, useState } from "react";
import "./trackbell.scss";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import EditTrackBell from "./EditTrackBell";

const TrackBell = () => {
  const [data, setData] = useState([]);
  const [createDate, setCreateDate] = useState("");
  const [createName, setCreateName] = useState("");
  const [createType, setCreateType] = useState("");
  const [createTag, setCreateTag] = useState("");
  const [createTime, setCreateTime] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "appointment");

  useEffect(() => {
    // LISTEN (REALTIME)
    const q = query(collectionRef, orderBy("timeStamp", "desc"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list = [{ id: doc.id, ...doc.data() }, ...list];
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        date: createDate,
        serviceName: createName,
        serviceType: createType,
        serviceTime: createTime,
        serviceTag: createTag,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="trackbell">
        <p>Client's Appointment</p>
        <hr />

        {/* Modal Button */}
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Add Notifier
        </button>

        {/* Table Components */}
        <div style={{ marginTop: "30px" }}>
          <table className="styled-table">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "center" }}>Service Type</th>
                <th style={{ textAlign: "center" }}>Service Name</th>
                <th style={{ textAlign: "center" }}>Time</th>
                <th style={{ textAlign: "center" }}>Tag Allocated</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map(
                ({
                  id,
                  date,
                  serviceName,
                  serviceType,
                  serviceTime,
                  serviceTag,
                }) => (
                  <tr key={id}>
                    <td>{date}</td>
                    <td>{serviceName}</td>
                    <td>{serviceType}</td>
                    <td>{serviceTime}</td>
                    <td>{serviceTag}</td>
                    <td>
                      {" "}
                      <button type="button" className="icon">
                        <EditTrackBell
                          id={id}
                          date={date}
                          serviceName={serviceName}
                          serviceType={serviceType}
                          serviceTime={serviceTime}
                          serviceTag={serviceTag}
                        />
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* Table Components */}

        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form className="d-flex" onSubmit={submitTask}>
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Appointment
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setCreateDate(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="enter service name"
                      className="form-control"
                      onChange={(e) => setCreateName(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="enter service type"
                      className="form-control"
                      onChange={(e) => setCreateType(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="time"
                      placeholder="time designated"
                      className="form-control"
                      onChange={(e) => setCreateTime(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="enter tag allocated"
                      className="form-control"
                      onChange={(e) => setCreateTag(e.target.value)}
                      size="3080"
                    />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={buttonDisabled}
                  >
                    Add Notifier
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackBell;
