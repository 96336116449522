import "./events.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import EventsManager from "../../components/eventsmanager/EventsManager";
import TrackSchedules from "../../components/trackschedules/TrackSchedules";



const Events = () => {
  return (
    <div className='events'>
    <Sidebar/>
    <div className="eventsContainer">
    <Navbar/>
    <div className="evms">
      <EventsManager/>
    </div>
    <div className="tracksch">
      <TrackSchedules/>
    </div>
    </div>
    </div>
  )
}

export default Events
