import "./trackdrugs.scss";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import EditDrugs from "./EditDrugs";

const TrackDrugs = () => {
  const [tasks, setTasks] = useState([]);
  const [createDrug, setCreateDrug] = useState("");
  const [createDosage, setCreateDosage] = useState("");
  const [createQuantity, setCreateQuantity] = useState("");
  const [createNurse, setCreateNurse] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createAdmin, setCreateAdmin] = useState("");
  const [createDuration, setCreateDuration] = useState("");
  const [createUsage, setCreateUsage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const drugs = [
    "Doxycycline",
    "Vasoprin",
    "Cyclogest",
    "Prednisolone",
    "Piroxicam",
    "Gestone",
    "Primolut N",
    "Levofem",
    "Paracetamol",
    "Diazepam",
    "Ketamine",
    "Diclofenac",
    "Pentazocine",
    "Promethazine",
    "Menotas",
    "Gonal F",
    "Cetrotide",
    "HCG",
    "Letozole",
    "Cabergoline",
    "Viagra",
  ];

  const dosage = ["2mg", "4mg", "6mg", "8mg", "12mg"];

  const frequency = ["Daily", "Twice", "Thrice"];

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "pharmacy");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        drug: createDrug,
        dosage: createDosage,
        quantity: createQuantity,
        nurse: createNurse,
        date: createDate,
        admin: createAdmin,
        usage: createUsage,
        duration: createDuration,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  moment.updateLocale("en", {
    invalidDate: "",
  });

  return (
    <div className="trackdrugs">
      <p>Drugs Allocated</p>
      <hr />

      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Add Drug
      </button>

      {/* Table Components */}
      <div className="pharmWrapper" style={{ marginTop: "30px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Date</th>
              <th style={{ textAlign: "center" }}>Drug</th>
              <th style={{ textAlign: "center" }}>Quantity</th>
              <th style={{ textAlign: "center" }}>Nurse/Staff</th>
              <th style={{ textAlign: "center" }}>Dosage</th>
              <th style={{ textAlign: "center" }}>Frequency</th>
              <th style={{ textAlign: "center" }}>Administration</th>
              <th style={{ textAlign: "center" }}>Duration</th>
              <th style={{ textAlign: "center" }}>Edit</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(
              ({
                id,
                drug,
                quantity,
                nurse,
                dosage,
                date,
                admin,
                duration,
                usage,
              }) => (
                <tr key={id}>
                  <td>{moment(date).format("MMMM Do YYYY")}</td>
                  <td>{drug}</td>
                  <td>{quantity}</td>
                  <td>{nurse}</td>
                  <td>{dosage}</td>
                  <td>{usage}</td>
                  <td>{admin}</td>
                  <td>{duration}</td>
                  <td>
                    {" "}
                    <button type="button" className="icon">
                      <EditDrugs
                        id={id}
                        drug={drug}
                        dosage={dosage}
                        quantity={quantity}
                        nurse={nurse}
                        date={date}
                        admin={admin}
                        usage={usage}
                        duration={duration}
                      />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {/* Table Components */}

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Drugs
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter drug name"
                    list="drugs"
                    onChange={(e) => setCreateDrug(e.target.value)}
                    size="3080"
                  />
                  <datalist id="drugs">
                    {drugs.map((op) => (
                      <option>{op}</option>
                    ))}
                  </datalist>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter quantity"
                    onChange={(e) => setCreateQuantity(e.target.value)}
                    size="3080"
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter nurse/staff dispensed to"
                    onChange={(e) => setCreateNurse(e.target.value)}
                    size="3080"
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter dosage"
                    list="dosage"
                    onChange={(e) => setCreateDosage(e.target.value)}
                    size="3080"
                  />
                  <datalist id="dosage">
                    {dosage.map((op) => (
                      <option>{op}</option>
                    ))}
                  </datalist>

                  <input
                    type="text"
                    placeholder="Route of Administration"
                    className="form-control"
                    onChange={(e) => setCreateAdmin(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="enter duration"
                    className="form-control"
                    onChange={(e) => setCreateDuration(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="enter frequency"
                    list="frequency"
                    className="form-control"
                    onChange={(e) => setCreateUsage(e.target.value)}
                    size="3080"
                  />
                  <datalist id="frequency">
                    {frequency.map((op) => (
                      <option>{op}</option>
                    ))}
                  </datalist>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Drug
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrackDrugs;
