import "./new.scss";
import Sidebar from "./../../components/sidebar/Sidebar";
import Navbar from "./../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const New = ({ title, btnUpd }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const findUs = ["Referral Doctor", "Website", "Friends", "Facebook", "Nil"];

  const statusCheck = ["married", "divorced", "single"];

  const titled = ["Mr", "Mrs"];

  const sexes = ["Male", "Female"];

  const [userInfo, setUserInfo] = useState({
    regNo: "",
    title: "",
    firstName: "",
    lastName: "",
    sex: "",
    email: "",
    reffBy: "",
    address: "",
    phoneNumber: "",
    age: "",
    date: "",
    birth: "",
    status: "",
    state: "",
    occupation: "",
    duration: "",
    finding: "",
  });

  // fetch id from the url
  const { userId } = useParams();
  let id = userId;

  useEffect(() => {
    let getUserDoc = async (id) => {
      let docRef = doc(db, "greenleaf/" + id);
      let docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        let theData = docSnap.data();
        setUserInfo(theData);
      }
    };

    if (id) {
      getUserDoc(id);
    } else {
    }
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  // Check if the user exists
  // async function dataExists() {
  //   const docRef = doc(db, "greenleaf", "66bYZykWNne4ctBKKCN4");
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     console.log("Document data:", docSnap.data());
  //   } else {
  //     // docSnap.data() will be undefined in this case
  //     console.log("No such document!");
  //   }
  // }

  // dataExists();

  // Adding and Updating Existing Records....
  const handleAdd = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "greenleaf", userInfo.email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && id === undefined) {
      toast.error("User Already Exists 😞");
    } else if (isEmpty(id)) {
      await setDoc(doc(db, "greenleaf", userInfo.email), {
        ...userInfo,
        timeStamp: serverTimestamp(),
      });
      toast.success("User Registered Successfully 🤗");
      navigate("/users");
    } else {
      await updateDoc(doc(db, "greenleaf/" + id), {
        ...userInfo,
        timeStamp: serverTimestamp(),
      });
      toast.success("User Updated Successfully 👏");
      navigate("/users");
    }
  };

  // Calculating age from date of birth
  const ageCalc = userInfo.birth;

  const trueAge = moment().diff(moment(ageCalc, "YYYYMMDD"), "years");

  // Uploading Profile Pictures
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUserInfo((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1> {title} </h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              <div className="formInput">
                <label>ID</label>
                <input
                  id="regNo"
                  name="regNo"
                  type="text"
                  required
                  placeholder="enter client id"
                  value={userInfo.regNo}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Title</label>
                <input
                  id="title"
                  name="title"
                  list="titled"
                  type="text"
                  required
                  placeholder="enter client title, e.g: Mr, Mrs"
                  value={userInfo.title}
                  onChange={handleInput}
                />
                <datalist id="titled">
                  {titled.map((op) => (
                    <option>{op}</option>
                  ))}
                </datalist>
              </div>

              <div className="formInput">
                <label>First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  placeholder="enter first name"
                  value={userInfo.firstName}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  placeholder="enter last name"
                  value={userInfo.lastName}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Sex</label>
                <input
                  id="sex"
                  name="sex"
                  list="sexes"
                  type="text"
                  required
                  placeholder="Male or Female"
                  value={userInfo.sex}
                  onChange={handleInput}
                />
                <datalist id="sexes">
                  {sexes.map((op) => (
                    <option>{op}</option>
                  ))}
                </datalist>
              </div>

              <div className="formInput">
                <label>Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="enter your email address"
                  value={userInfo.email}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Refferal</label>
                <input
                  id="reffBy"
                  name="reffBy"
                  type="text"
                  required
                  placeholder="enter your referral"
                  value={userInfo.reffBy}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Address</label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  required
                  placeholder="enter your address"
                  value={userInfo.address}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Date</label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  required
                  value={userInfo.date}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Phone Number</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="number"
                  required
                  placeholder="phone number"
                  value={userInfo.phoneNumber}
                  onChange={handleInput}
                />
              </div>

              {/* Automatically Calculate Age */}
              <div className="formInput">
                <label>Age</label>
                <input
                  id="age"
                  name="age"
                  type="text"
                  required
                  placeholder="age"
                  value={(userInfo.age = trueAge)}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Date of Birth</label>
                <input
                  id="birth"
                  name="birth"
                  type="date"
                  required
                  value={userInfo.birth}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Status</label>
                <input
                  id="status"
                  name="status"
                  list="statusCheck"
                  type="text"
                  required
                  placeholder="status"
                  value={userInfo.status}
                  onChange={handleInput}
                />
                <datalist id="statusCheck">
                  {statusCheck.map((op) => (
                    <option>{op}</option>
                  ))}
                </datalist>
              </div>

              <div className="formInput">
                <label>State of Origin</label>
                <input
                  id="state"
                  name="state"
                  type="text"
                  required
                  placeholder="state of origin"
                  value={userInfo.state}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Occupation</label>
                <input
                  id="occupation"
                  name="occupation"
                  type="text"
                  required
                  placeholder="occupation"
                  value={userInfo.occupation}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>Duration of Infertility</label>
                <input
                  id="duration"
                  name="duration"
                  type="text"
                  required
                  placeholder="duration of infertility"
                  value={userInfo.duration}
                  onChange={handleInput}
                />
              </div>

              <div className="formInput">
                <label>How Did you find us</label>
                <input
                  id="finding"
                  name="finding"
                  type="text"
                  list="findUs"
                  required
                  placeholder="How did you find us"
                  value={userInfo.finding}
                  onChange={handleInput}
                />
                <datalist id="findUs">
                  {findUs.map((op) => (
                    <option>{op}</option>
                  ))}
                </datalist>
              </div>
              <button type="submit" disabled={buttonDisabled}>
                {btnUpd}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
