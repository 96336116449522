import "./reportmcs.scss";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditReportMcs from "./EditReportMcs";
import PreviewIcon from "@mui/icons-material/Preview";

const ReportMcs = () => {
  const reportsTitle = ["ANTI MULLERIAN HORMONE(AMH)"];

  const reports1Header = ["AGE"];

  const reports2Header = ["REFERENCE VALUE"];

  const row1listings = ["20-24", "25-29", "30-34", "35-39", "40-44", "45-49"];

  const row2listings = [
    "3.97 (3.55-4.33)",
    "3.34 (3.03-3.87)",
    "2.76 (2.34-3.55)",
    "2.05 (1.78-3.24)",
    "1.06 (0.734-2.13)",
    "0.223 (0.125-0.498)",
  ];

  const subHeading = [];

  const subReportTitle = ["AMH: ng/ml"];

  const [tasks, setTasks] = useState([]);
  const [createAntibiotics01, setCreateAntibiotics01] = useState("");
  const [createSensitivity01, setCreateSensitivity01] = useState("");
  const [createAntibiotics02, setCreateAntibiotics02] = useState("");
  const [createSensitivity02, setCreateSensitivity02] = useState("");
  const [createAntibiotics03, setCreateAntibiotics03] = useState("");
  const [createSensitivity03, setCreateSensitivity03] = useState("");
  const [createAntibiotics04, setCreateAntibiotics04] = useState("");
  const [createSensitivity04, setCreateSensitivity04] = useState("");
  const [createAntibiotics05, setCreateAntibiotics05] = useState("");
  const [createSensitivity05, setCreateSensitivity05] = useState("");
  const [createAntibiotics06, setCreateAntibiotics06] = useState("");
  const [createSensitivity06, setCreateSensitivity06] = useState("");
  const [createAntibiotics07, setCreateAntibiotics07] = useState("");
  const [createSensitivity07, setCreateSensitivity07] = useState("");
  const [createAntibiotics08, setCreateAntibiotics08] = useState("");
  const [createSensitivity08, setCreateSensitivity08] = useState("");
  const [createAntibiotics09, setCreateAntibiotics09] = useState("");
  const [createSensitivity09, setCreateSensitivity09] = useState("");
  const [createAntibiotics10, setCreateAntibiotics10] = useState("");
  const [createSensitivity10, setCreateSensitivity10] = useState("");
  const [createRow1Header, setCreateRow1Header] = useState("");
  const [createRow2Header, setCreateRow2Header] = useState("");
  const [createTitle, setCreateTitle] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createSubHeading, setCreateSubHeading] = useState("");
  const [createSubReportTitle, setCreateSubReportTitle] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "reportmcs");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        antibiotics01: createAntibiotics01,
        sensitivity01: createSensitivity01,
        antibiotics02: createAntibiotics02,
        sensitivity02: createSensitivity02,
        antibiotics03: createAntibiotics03,
        sensitivity03: createSensitivity03,
        antibiotics04: createAntibiotics04,
        sensitivity04: createSensitivity04,
        antibiotics05: createAntibiotics05,
        sensitivity05: createSensitivity05,
        antibiotics06: createAntibiotics06,
        sensitivity06: createSensitivity06,
        antibiotics07: createAntibiotics07,
        sensitivity07: createSensitivity07,
        antibiotics08: createAntibiotics08,
        sensitivity08: createSensitivity08,
        antibiotics09: createAntibiotics09,
        sensitivity09: createSensitivity09,
        antibiotics10: createAntibiotics10,
        sensitivity10: createSensitivity10,
        row1Header: createRow1Header,
        row2Header: createRow2Header,
        reportTitle: createTitle,
        date: createDate,
        subHeading: createSubHeading,
        subReportTitle: createSubReportTitle,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="reportmcs">
        <p>&#91;IMMUNOASSAY&#40;AMH&#41;&#93;</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#mcsModal"
        >
          Add AMH
        </button>

        {/* Mapped Area */}
        {tasks.map(
          ({
            id,
            antibiotics01,
            sensitivity01,
            antibiotics02,
            sensitivity02,
            antibiotics03,
            sensitivity03,
            antibiotics04,
            sensitivity04,
            antibiotics05,
            sensitivity05,
            antibiotics06,
            sensitivity06,
            antibiotics07,
            sensitivity07,
            antibiotics08,
            sensitivity08,
            antibiotics09,
            sensitivity09,
            antibiotics10,
            sensitivity10,
            row1Header,
            row2Header,
            reportTitle,
            date,
            subHeading,
            subReportTitle,
          }) => (
            <>
              <div className="coveragemcs">
                <div className="overallmcs" key={id}>
                  <div className="headermcs">
                    <p>{row1Header}</p>
                    <p>{row2Header}</p>
                  </div>
                  <div className="contentmcs">
                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "8px" }}>
                      <section className="cms">
                        <p>{antibiotics01}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity01}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics02}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity02}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics03}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity03}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics04}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity04}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics05}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity05}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics06}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity06}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics07}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity07}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics08}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity08}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics09}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity09}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}

                    {/* Antibiotics vis sensitivity */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="cms">
                        <p>{antibiotics10}</p>
                      </section>
                      <section
                        className="cms"
                        style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                      >
                        <p>{sensitivity10}</p>
                      </section>
                    </section>
                    {/* Antibiotics vis sensitivity */}
                  </div>
                </div>
              </div>

              <div className="buttonflex">
                <div>
                  {/* Our lovely button */}
                  <button type="button" className="btn-pri">
                    <EditReportMcs
                      id={id}
                      antibiotics01={antibiotics01}
                      sensitivity01={sensitivity01}
                      antibiotics02={antibiotics02}
                      sensitivity02={sensitivity02}
                      antibiotics03={antibiotics03}
                      sensitivity03={sensitivity03}
                      antibiotics04={antibiotics04}
                      sensitivity04={sensitivity04}
                      antibiotics05={antibiotics05}
                      sensitivity05={sensitivity05}
                      antibiotics06={antibiotics06}
                      sensitivity06={sensitivity06}
                      antibiotics07={antibiotics07}
                      sensitivity07={sensitivity07}
                      antibiotics08={antibiotics08}
                      sensitivity08={sensitivity08}
                      antibiotics09={antibiotics09}
                      sensitivity09={sensitivity09}
                      antibiotics10={antibiotics10}
                      sensitivity10={sensitivity10}
                      reportTitle={reportTitle}
                      row1Header={row1Header}
                      row2Header={row2Header}
                      date={date}
                      subHeading={subHeading}
                      subReportTitle={subReportTitle}
                    />
                  </button>
                </div>

                {/* Our lovely button */}
                <div>
                  <Link to={`/users/${userId}/reports/culture/${id}`}>
                    <button type="button" className="btn-prime">
                      <PreviewIcon />
                    </button>
                  </Link>
                </div>
              </div>
            </>
          )
        )}
        {/* Mapped Area */}

        {/* Ending div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="mcsModal"
        tabindex="-1"
        aria-labelledby="mcsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="mcsModalLabel">
                  AMH
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        REPORT TITLE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Report Title"
                      list="reportsTitle"
                      onChange={(e) => setCreateTitle(e.target.value)}
                    />
                    <datalist id="reportsTitle">
                      {reportsTitle.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        SUB REPORT TITLE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Sub Report Title"
                      list="subReportTitle"
                      onChange={(e) => setCreateSubReportTitle(e.target.value)}
                    />
                    <datalist id="subReportTitle">
                      {subReportTitle.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        SUBHEADING
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter SubHeading"
                      list="subHeading"
                      onChange={(e) => setCreateSubHeading(e.target.value)}
                    />
                    <datalist id="subHeading">
                      {subHeading.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      list="reports1Header"
                      placeholder="Enter Row1HeaderTitle"
                      onChange={(e) => setCreateRow1Header(e.target.value)}
                    />
                    <datalist id="reports1Header">
                      {reports1Header.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2HeaderTitle"
                      list="reports2Header"
                      onChange={(e) => setCreateRow2Header(e.target.value)}
                    />
                    <datalist id="reports2Header">
                      {reports2Header.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Row1HeaderTitle and Row2HeaderTitle
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Row1AData and Row1BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row1AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics01(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row1BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity01(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics02(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity02(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2">
                        Row2AData and Row2BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3">
                        Row3AData and Row3BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row3AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics03(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row3BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity03(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row4AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics04(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row4BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity04(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4">
                        Row4AData and Row4BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5">
                        Row5AData and Row5BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row5AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics05(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row5BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity05(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row6AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics06(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row6BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity06(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6">
                        Row6AData and Row6BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-7">
                        Row7AData and Row7BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row7AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics07(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row7BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity07(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row8AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics08(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row8BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity08(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-8">
                        Row8AData and Row8BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-9">
                        Row9AData and Row9BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row9AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics09(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row9BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity09(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row10AData"
                      list="row1listings"
                      onChange={(e) => setCreateAntibiotics10(e.target.value)}
                    />
                    <datalist id="row1listings">
                      {row1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row10BData"
                      list="row2listings"
                      onChange={(e) => setCreateSensitivity10(e.target.value)}
                    />
                    <datalist id="row2listings">
                      {row2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-10">
                        Row10AData and Row10BData
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportMcs;
