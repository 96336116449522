import "./reportamh.scss";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditReportAmh from "./EditReportAmh";
import PreviewIcon from "@mui/icons-material/Preview";

const ReportAmh = () => {
  const serologyTitle = ["SEROLOGY", "PREGNANCY TEST REPORT"];

  const serology1Header = ["INVESTIGATION"];

  const serology2Header = ["RESULT"];

  const ser1listings = [
    "BLOOD GROUP",
    "GENOTYPE",
    "HIV",
    "HBSag",
    "HCV",
    "VDRL",
    "PREGNANCY TEST",
  ];

  const ser2listings = [
    "O Rh'D' POSITIVE",
    "A Rh'D' POSITIVE",
    "B Rh'D' POSITIVE",
    "AB Rh'D' POSITIVE",
    "AA",
    "AS",
    "NEGATIVE",
    "O Rh'D' NEGATIVE",
    "A Rh'D' NEGATIVE",
    "B Rh'D' NEGATIVE",
    "AB Rh'D' NEGATIVE",
  ];

  const [tasks, setTasks] = useState([]);
  const [createTitle, setCreateTitle] = useState("");
  const [createRow1Header, setCreateRow1Header] = useState("");
  const [createRow2Header, setCreateRow2Header] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createInvestigation01, setCreateInvestigation01] = useState("");
  const [createResult01, setCreateResult01] = useState("");
  const [createInvestigation02, setCreateInvestigation02] = useState("");
  const [createResult02, setCreateResult02] = useState("");
  const [createInvestigation03, setCreateInvestigation03] = useState("");
  const [createResult03, setCreateResult03] = useState("");
  const [createInvestigation04, setCreateInvestigation04] = useState("");
  const [createResult04, setCreateResult04] = useState("");
  const [createInvestigation05, setCreateInvestigation05] = useState("");
  const [createResult05, setCreateResult05] = useState("");
  const [createInvestigation06, setCreateInvestigation06] = useState("");
  const [createResult06, setCreateResult06] = useState("");
  const [createInvestigation07, setCreateInvestigation07] = useState("");
  const [createResult07, setCreateResult07] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "serology");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        reportTitle: createTitle,
        date: createDate,
        row1Header: createRow1Header,
        row2Header: createRow2Header,
        investigation01: createInvestigation01,
        result01: createResult01,
        investigation02: createInvestigation02,
        result02: createResult02,
        investigation03: createInvestigation03,
        result03: createResult03,
        investigation04: createInvestigation04,
        result04: createResult04,
        investigation05: createInvestigation05,
        result05: createResult05,
        investigation06: createInvestigation06,
        result06: createResult06,
        investigation07: createInvestigation07,
        result07: createResult07,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="reportamh">
        <p>SEROLOGY, PT</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#serModal"
        >
          Add Serology
        </button>

        {/* Mapped Area */}
        {tasks.map(
          ({
            id,
            reportTitle,
            row1Header,
            row2Header,
            date,
            investigation01,
            result01,
            investigation02,
            result02,
            investigation03,
            result03,
            investigation04,
            result04,
            investigation05,
            result05,
            investigation06,
            result06,
            investigation07,
            result07,
          }) => (
            <>
              <div className="coverageser">
                <div className="overallser" key={id}>
                  <div className="headerser">
                    <p>{row1Header}</p>
                    <p>{row2Header}</p>
                  </div>
                  <div className="contentser">
                    {/* Investigation vis result */}
                    <section style={{ marginTop: "8px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation01}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result01}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation02}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result02}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation03}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result03}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation04}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result04}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation05}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result05}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation06}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result06}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}

                    {/* Investigation vis result */}
                    <section style={{ marginTop: "-38px" }}>
                      <section className="ser" style={{ marginLeft: "18px" }}>
                        <p>{investigation07}</p>
                      </section>
                      <section
                        className="ser"
                        style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                      >
                        <p>{result07}</p>
                      </section>
                    </section>
                    {/* Investigation vis result */}
                  </div>
                </div>
              </div>

              <div className="buttonflex">
                <div>
                  {/* Our lovely button */}
                  <button type="button" className="btn-pri">
                    <EditReportAmh
                      id={id}
                      reportTitle={reportTitle}
                      row1Header={row1Header}
                      row2Header={row2Header}
                      date={date}
                      investigation01={investigation01}
                      result01={result01}
                      investigation02={investigation02}
                      result02={result02}
                      investigation03={investigation03}
                      result03={result03}
                      investigation04={investigation04}
                      result04={result04}
                      investigation05={investigation05}
                      result05={result05}
                      investigation06={investigation06}
                      result06={result06}
                      investigation07={investigation07}
                      result07={result07}
                    />
                  </button>
                </div>

                {/* Our lovely button */}
                <div>
                  <Link to={`/users/${userId}/reports/serology/${id}`}>
                    <button type="button" className="btn-prime">
                      <PreviewIcon />
                    </button>
                  </Link>
                </div>
              </div>
            </>
          )
        )}
        {/* Mapped Area */}

        {/* Ending div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="serModal"
        tabindex="-1"
        aria-labelledby="serModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="serModalLabel">
                  SEROLOGY
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair">
                        REPORT TITLE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Report Title"
                      list="serologyTitle"
                      onChange={(e) => setCreateTitle(e.target.value)}
                    />
                    <datalist id="serologyTitle">
                      {serologyTitle.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setCreateDate(e.target.value)}
                  />

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      list="serology1Header"
                      placeholder="Enter Row1HeaderTitle"
                      onChange={(e) => setCreateRow1Header(e.target.value)}
                    />
                    <datalist id="serology1Header">
                      {serology1Header.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2HeaderTitle"
                      list="serology2Header"
                      onChange={(e) => setCreateRow2Header(e.target.value)}
                    />
                    <datalist id="serology2Header">
                      {serology2Header.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Row1HeaderTitle and Row2HeaderTitle
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Row1AData and Row1BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row1AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation01(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row1BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult01(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation02(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row2BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult02(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2">
                        Row2AData and Row2BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3">
                        Row3AData and Row3BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row3AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation03(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row3BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult03(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row4AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation04(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row4BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult04(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4">
                        Row4AData and Row4BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5">
                        Row5AData and Row5BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row5AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation05(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row5BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult05(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row6AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation06(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row6BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult06(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6">
                        Row6AData and Row6BData
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-7">
                        Row7AData and Row7BData
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row7AData"
                      list="ser1listings"
                      onChange={(e) => setCreateInvestigation07(e.target.value)}
                    />
                    <datalist id="ser1listings">
                      {ser1listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Row7BData"
                      list="ser2listings"
                      onChange={(e) => setCreateResult07(e.target.value)}
                    />
                    <datalist id="ser2listings">
                      {ser2listings.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportAmh;
