import "./eventsmanager.scss";


const EventsManager = () => {
  return (
    <div className="eventsmanager">
      <h1>Track Appointments Globally</h1>
    </div>
  )
}

export default EventsManager
