import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";

const EditCnote = ({ cnote, id, reported, duration }) => {
  const [notes, setNotes] = useState(cnote);
  const [createReport, setCreateReport] = useState(reported);
  const [createDuration, setCreateDuration] = useState(duration);

  const { userId } = useParams();

  const updateNotes = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "consultation", id);
      await updateDoc(taskDocument, {
        cnote: notes,
        reported: createReport,
        duration: createDuration,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update Notes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                type="text"
                className="form-control"
                defaultValue={notes}
                onChange={(e) => setNotes(e.target.value)}
              />

              <input
                type="text"
                className="form-control"
                placeholder="duration(tracking old clients).."
                defaultValue={createDuration}
                onChange={(e) => setCreateDuration(e.target.value)}
                size="3080"
              />

              <input
                type="text"
                className="form-control"
                placeholder="reported by.."
                defaultValue={createReport}
                onChange={(e) => setCreateReport(e.target.value)}
                size="3080"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateNotes(e)}
              >
                Update Note
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCnote;
