import "./embryoreport.scss";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditEmbryoReport from "./EditEmbryoReport";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import moment from "moment";

const EmbryoReport = () => {
  const oocytedHeader = [
    "M II",
    "GV/MI/A",
    "ICSI/IVF",
    "ICSI(Remarks),",
    "Pronuclei Day1",
    "No. of Blast. Day2",
    "Embryo score",
    "No. of Blast. Day3",
    "Embryo score",
    "No. of Blast. Day4",
    "Embryo score",
    "No. of Blast. Day5",
    "Embryo score",
    "Freezing / Trans",
  ];

  const [tasks, setTasks] = useState([]);
  const [createTime, setCreateTime] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createComments, setCreateComments] = useState("");
  const [createOocytes, setCreateOocytes] = useState("");
  const [createOne, setCreateOne] = useState("");
  const [createTwo, setCreateTwo] = useState("");
  const [createThree, setCreateThree] = useState("");
  const [createFour, setCreateFour] = useState("");
  const [createFive, setCreateFive] = useState("");
  const [createSix, setCreateSix] = useState("");
  const [createSeven, setCreateSeven] = useState("");
  const [createEight, setCreateEight] = useState("");
  const [createNine, setCreateNine] = useState("");
  const [createTen, setCreateTen] = useState("");
  const [createEleven, setCreateEleven] = useState("");
  const [createTwelve, setCreateTwelve] = useState("");
  const [createThirteen, setCreateThirteen] = useState("");
  const [createFourteen, setCreateFourteen] = useState("");
  const [createFifteen, setCreateFifteen] = useState("");
  const [createSixteen, setCreateSixteen] = useState("");
  const [createSeventeen, setCreateSeventeen] = useState("");
  const [createEighteen, setCreateEighteen] = useState("");
  const [createNineteen, setCreateNineteen] = useState("");
  const [createTwenty, setCreateTwenty] = useState("");
  const [createTwentyOne, setCreateTwentyOne] = useState("");
  const [createTwentyTwo, setCreateTwentyTwo] = useState("");
  const [createTwentyThree, setCreateTwentyThree] = useState("");
  const [createTwentyFour, setCreateTwentyFour] = useState("");
  const [createTwentyFive, setCreateTwentyFive] = useState("");
  const [createTwentySix, setCreateTwentySix] = useState("");
  const [createTwentySeven, setCreateTwentySeven] = useState("");
  const [createTwentyEight, setCreateTwentyEight] = useState("");
  const [createTwentyNine, setCreateTwentyNine] = useState("");
  const [createThirty, setCreateThirty] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "embryoReport");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "asc"));
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        timeAt: createTime,
        date: createDate,
        comments: createComments,
        oocytes: createOocytes,
        one: createOne,
        two: createTwo,
        three: createThree,
        four: createFour,
        five: createFive,
        six: createSix,
        seven: createSeven,
        eight: createEight,
        nine: createNine,
        ten: createTen,
        eleven: createEleven,
        twelve: createTwelve,
        thirteen: createThirteen,
        fourteen: createFourteen,
        fifteen: createFifteen,
        sixteen: createSixteen,
        seventeen: createSeventeen,
        eighteen: createEighteen,
        nineteen: createNineteen,
        twenty: createTwenty,
        twentyone: createTwentyOne,
        twentytwo: createTwentyTwo,
        twentythree: createTwentyThree,
        twentyfour: createTwentyFour,
        twentyfive: createTwentyFive,
        twentysix: createTwentySix,
        twentyseven: createTwentySeven,
        twentyeight: createTwentyEight,
        twentynine: createTwentyNine,
        thirty: createThirty,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Handler
  const deleteTask = async (id) => {
    try {
      const del_task = window.confirm(
        "Are you sure you want to delete this report?"
      );
      if (del_task == true) {
        const documentRef = doc(db, "greenleaf/" + userId, "embryoReport", id);
        await deleteDoc(documentRef);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  moment.updateLocale("en", {
    invalidDate: "",
  });

  return (
    <div className="embryoreport">
      <p>Embryo Preservation</p>

      <hr />

      {/* Modal Button */}
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#embryoReportModal"
      >
        Add EmbryoReport
      </button>
      {/* Table Components */}
      <div className="embryoWrapper" style={{ marginTop: "30px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Time</th>
              <th style={{ textAlign: "center" }}>Date</th>
              <th style={{ textAlign: "center" }}>Comments</th>
              <th style={{ textAlign: "center" }}>Oocytes</th>
              <th style={{ textAlign: "center" }}>1</th>
              <th style={{ textAlign: "center" }}>2</th>
              <th style={{ textAlign: "center" }}>3</th>
              <th style={{ textAlign: "center" }}>4</th>
              <th style={{ textAlign: "center" }}>5</th>
              <th style={{ textAlign: "center" }}>6</th>
              <th style={{ textAlign: "center" }}>7</th>
              <th style={{ textAlign: "center" }}>8</th>
              <th style={{ textAlign: "center" }}>9</th>
              <th style={{ textAlign: "center" }}>10</th>
              <th style={{ textAlign: "center" }}>11</th>
              <th style={{ textAlign: "center" }}>12</th>
              <th style={{ textAlign: "center" }}>13</th>
              <th style={{ textAlign: "center" }}>14</th>
              <th style={{ textAlign: "center" }}>15</th>
              <th style={{ textAlign: "center" }}>16</th>
              <th style={{ textAlign: "center" }}>17</th>
              <th style={{ textAlign: "center" }}>18</th>
              <th style={{ textAlign: "center" }}>19</th>
              <th style={{ textAlign: "center" }}>20</th>
              <th style={{ textAlign: "center" }}>021</th>
              <th style={{ textAlign: "center" }}>022</th>
              <th style={{ textAlign: "center" }}>023</th>
              <th style={{ textAlign: "center" }}>024</th>
              <th style={{ textAlign: "center" }}>025</th>
              <th style={{ textAlign: "center" }}>026</th>
              <th style={{ textAlign: "center" }}>027</th>
              <th style={{ textAlign: "center" }}>028</th>
              <th style={{ textAlign: "center" }}>029</th>
              <th style={{ textAlign: "center" }}>030</th>
              <th style={{ textAlign: "center" }}>Action</th>
              <th style={{ textAlign: "center" }}>Del</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(
              ({
                id,
                timeAt,
                date,
                comments,
                oocytes,
                one,
                two,
                three,
                four,
                five,
                six,
                seven,
                eight,
                nine,
                ten,
                eleven,
                twelve,
                thirteen,
                fourteen,
                fifteen,
                sixteen,
                seventeen,
                eighteen,
                nineteen,
                twenty,
                twentyone,
                twentytwo,
                twentythree,
                twentyfour,
                twentyfive,
                twentysix,
                twentyseven,
                twentyeight,
                twentynine,
                thirty,
              }) => (
                <tr key={id}>
                  <td>{timeAt}</td>
                  <td>{moment(date).format("MMMM Do YYYY")}</td>
                  <td>{comments}</td>
                  <td>{oocytes}</td>
                  <td>{one}</td>
                  <td>{two}</td>
                  <td>{three}</td>
                  <td>{four}</td>
                  <td>{five}</td>
                  <td>{six}</td>
                  <td>{seven}</td>
                  <td>{eight}</td>
                  <td>{nine}</td>
                  <td>{ten}</td>
                  <td>{eleven}</td>
                  <td>{twelve}</td>
                  <td>{thirteen}</td>
                  <td>{fourteen}</td>
                  <td>{fifteen}</td>
                  <td>{sixteen}</td>
                  <td>{seventeen}</td>
                  <td>{eighteen}</td>
                  <td>{nineteen}</td>
                  <td>{twenty}</td>
                  <td>{twentyone}</td>
                  <td>{twentytwo}</td>
                  <td>{twentythree}</td>
                  <td>{twentyfour}</td>
                  <td>{twentyfive}</td>
                  <td>{twentysix}</td>
                  <td>{twentyseven}</td>
                  <td>{twentyeight}</td>
                  <td>{twentynine}</td>
                  <td>{thirty}</td>
                  <td>
                    {" "}
                    <button type="button" className="icon">
                      <EditEmbryoReport
                        id={id}
                        timeAt={timeAt}
                        date={date}
                        comments={comments}
                        oocytes={oocytes}
                        one={one}
                        two={two}
                        three={three}
                        four={four}
                        five={five}
                        six={six}
                        seven={seven}
                        eight={eight}
                        nine={nine}
                        ten={ten}
                        eleven={eleven}
                        twelve={twelve}
                        thirteen={thirteen}
                        fourteen={fourteen}
                        fifteen={fifteen}
                        sixteen={sixteen}
                        seventeen={seventeen}
                        eighteen={eighteen}
                        nineteen={nineteen}
                        twenty={twenty}
                        twentyone={twentyone}
                        twentytwo={twentytwo}
                        twentythree={twentythree}
                        twentyfour={twentyfour}
                        twentyfive={twentyfive}
                        twentysix={twentysix}
                        twentyseven={twentyseven}
                        twentyeight={twentyeight}
                        twentynine={twentynine}
                        thirty={thirty}
                      />
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger float-end"
                      onClick={() => deleteTask(id)}
                    >
                      <DeleteSweepIcon />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>

          {/* Mapping those data in the doc alternative */}
          {/* <tbody>
         {Object.keys(tasks).map((id)=> {
           return(
             <tr>
               <td>{tasks[id].name}</td>
               <td>{tasks[id].date}</td>
               <td>{tasks[id].docto}</td>
               <td>{tasks[id].status}</td>
             </tr>
           )
         })}
       </tbody> */}
          {/* Mapping those data in the doc alternative */}
        </table>
      </div>
      {/* Table Components */}

      {/* Modal */}
      <div
        className="modal fade"
        id="embryoReportModal"
        tabindex="-1"
        aria-labelledby="embryoReportModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="embryoReportModalLabel">
                  Embryo Report
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter time"
                      onChange={(e) => setCreateTime(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setCreateDate(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter comments"
                      onChange={(e) => setCreateComments(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter oocytes"
                      list="oocytedHeader"
                      onChange={(e) => setCreateOocytes(e.target.value)}
                      size="3080"
                    />
                    <datalist id="oocytedHeader">
                      {oocytedHeader.map((op) => (
                        <option>{op}</option>
                      ))}
                    </datalist>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-1">
                        Time and Oocytes Header Listing
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-2">
                        One vis oocytes and Two vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from one vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateOne(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from two vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwo(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from three vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateThree(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from four vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateFour(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-3">
                        Three vis oocytes and Four vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-4">
                        Five vis oocytes and Six vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from five vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateFive(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from six vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateSix(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from seven vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateSeven(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from eight vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateEight(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-5">
                        Seven vis oocytes and Eight vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-6">
                        Nine vis oocytes and Ten vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from nine vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateNine(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from ten vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTen(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from eleven vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateEleven(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twelve vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwelve(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-7">
                        Eleven vis oocytes and Twelve vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-8">
                        Thirteen vis oocytes and Fourteen vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from thirteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateThirteen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from fourteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateFourteen(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from fifteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateFifteen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from sixteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateSixteen(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-9">
                        Fifteen vis oocytes and Sixteen vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-10">
                        Seventeen vis oocytes and Eighteen vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from seventeen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateSeventeen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from eighteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateEighteen(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from nineteen vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateNineteen(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twenty vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwenty(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-11">
                        Nineteen vis oocytes and Twenty vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-12">
                        TwentyOne vis oocytes and TwentyTwo vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from twentyone vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyOne(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twentytwo vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyTwo(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from twentythree vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyThree(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twentyfour vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyFour(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-13">
                        TwentyThree vis oocytes and TwentyFour vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-14">
                        TwentyFive vis oocytes and TwentySix vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from twentyfive vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyFive(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twentysix vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentySix(e.target.value)}
                      size="3080"
                    />
                  </div>

                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="counting from twentyseven vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentySeven(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from twentyeight vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyEight(e.target.value)}
                      size="3080"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-15">
                        TwentySeven vis oocytes and TwentyEight vis oocytes
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-16">
                        TwentyNine vis oocytes and Thirty vis oocytes
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="counting from twentynine vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateTwentyNine(e.target.value)}
                      size="3080"
                    />
                    <input
                      type="text"
                      placeholder="counting from thirty vis oocytes"
                      className="form-control"
                      onChange={(e) => setCreateThirty(e.target.value)}
                      size="3080"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add EmbryoReport
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmbryoReport;
