import "./cnotes.scss"
 
const Cnotes = () => {
  return (
    <div className="cnotes">
      <h1>Consultation Notes</h1>
    </div>
  )
}

export default Cnotes