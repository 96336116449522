import "./texting.scss"
import Chatbar from "../chatcomponents/chatbar/Chatbar"
import Schat from '../chatcomponents/schat/Schat';

const Texting = () => {
  return (
    <div className='texting'>
      <div className="container">
        <Chatbar/>
        <Schat/>
      </div>
    </div>
  )
}

export default Texting