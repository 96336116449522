import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditSonoReport = ({
  id,
  date,
  age,
  lmp,
  cervical,
  depth,
  polyps,
  fibroids,
  other,
  physician,
}) => {
  const [createDate, setCreateDate] = useState(date);
  // const [createName, setCreateName] = useState(sname);
  const [createAge, setCreateAge] = useState(age);
  const [createLmp, setCreateLmp] = useState(lmp);
  const [createCervical, setCreateCervical] = useState(cervical);
  const [createDepth, setCreateDepth] = useState(depth);
  const [createPolyps, setCreatePolyps] = useState(polyps);
  const [createFibroids, setCreateFibroids] = useState(fibroids);
  const [createOther, setCreateOther] = useState(other);
  const [createPhysician, setCreatePhysician] = useState(physician);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "sonoreport", id);
      await updateDoc(taskDocument, {
        date: createDate,
        // sname : createName,
        age: createAge,
        lmp: createLmp,
        cervical: createCervical,
        depth: createDepth,
        polyps: createPolyps,
        fibroids: createFibroids,
        other: createOther,
        physician: createPhysician,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update Sono Report
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      DATE
                    </span>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={createDate}
                    onChange={(e) => setCreateDate(e.target.value)}
                  />
                </div>

                {/* <div class="input-group">
      <div class="input-group-prepend">
      <span class="input-group-text" id="pair-01">NAME</span>
      </div>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Name.."
      onChange={(e) => setCreateName(e.target.value)}
      />
      </div> */}

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-02">
                      AGE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter age..."
                    defaultValue={createAge}
                    onChange={(e) => setCreateAge(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-03">
                      LMP
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter LMP..."
                    defaultValue={createLmp}
                    onChange={(e) => setCreateLmp(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-04">
                      CERVICAL ACCESSIBILITY
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Cervical Accessibility..."
                    defaultValue={createCervical}
                    onChange={(e) => setCreateCervical(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-05">
                      ENDOMETRIAL DEPTH
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Endometrial Depth..."
                    defaultValue={createDepth}
                    onChange={(e) => setCreateDepth(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-06">
                      ENDOMETRIAL POLYPS
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Endometrial Polyps..."
                    defaultValue={createPolyps}
                    onChange={(e) => setCreatePolyps(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-07">
                      FIBROIDS
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Fibroids..."
                    defaultValue={createFibroids}
                    onChange={(e) => setCreateFibroids(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-08">
                      OTHER COMMENTS
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Other Comments..."
                    defaultValue={createOther}
                    onChange={(e) => setCreateOther(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-09">
                      PHYSICIAN IN CHARGE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Physician in Charge..."
                    defaultValue={createPhysician}
                    onChange={(e) => setCreatePhysician(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSonoReport;
