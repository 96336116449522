import "./medicals.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import EditMedicals from "./EditMedicals";
import { useParams } from "react-router-dom";

const Medicals = () => {
  const [tasks, setTasks] = useState([]);
  const [createWeight, setCreateWeight] = useState("");
  const [createMedication, setCreateMedication] = useState("");
  const [createOthers, setCreateOthers] = useState("");
  const [checked, setChecked] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "medicals");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef);
      await getDocs(q)
        .then((list) => {
          let tasksData = list.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
          setChecked(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  //Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        weight: createWeight,
        medication: createMedication,
        otherHis: createOthers,
        isSickle: false,
        isDiabetes: false,
        isHypertension: false,
        isEpilepsy: false,
        isAsthma: false,
        isHyperthyroidism: false,
        isHypothyroidism: false,
        isOthers: false,
        isYes: false,
        isNo: false,
        isCigarette: false,
        isSmoking: false,
        isAlcohol: false,
        isCoffee: false,
        isSteriods: false,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // SickleCell
  const checkBoxSickle = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isSickle: !state[indexToUpdate].isSickle,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isSickle;
        transaction.update(docRef, { isSickle: newValue });
      });
    } catch (error) {}
  };

  // Diabetes
  const checkBoxDiabetes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isDiabetes: !state[indexToUpdate].isDiabetes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isDiabetes;
        transaction.update(docRef, { isDiabetes: newValue });
      });
    } catch (error) {}
  };

  // Hypertension
  const checkBoxHypertension = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isHypertension: !state[indexToUpdate].isHypertension,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isHypertension;
        transaction.update(docRef, { isHypertension: newValue });
      });
    } catch (error) {}
  };

  // Epilepsy
  const checkBoxEpilepsy = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isEpilepsy: !state[indexToUpdate].isEpilepsy,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isEpilepsy;
        transaction.update(docRef, { isEpilepsy: newValue });
      });
    } catch (error) {}
  };

  // Asthma
  const checkBoxAsthma = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isAsthma: !state[indexToUpdate].isAsthma,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isAsthma;
        transaction.update(docRef, { isAsthma: newValue });
      });
    } catch (error) {}
  };

  // Hyperthyroidism
  const checkBoxHyperthyroidism = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isHyperthyroidism: !state[indexToUpdate].isHyperthyroidism,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isHyperthyroidism;
        transaction.update(docRef, { isHyperthyroidism: newValue });
      });
    } catch (error) {}
  };

  // Hypothyroidism
  const checkBoxHypothyroidism = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isHypothyroidism: !state[indexToUpdate].isHypothyroidism,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isHypothyroidism;
        transaction.update(docRef, { isHypothyroidism: newValue });
      });
    } catch (error) {}
  };

  // Yes
  const checkBoxOthers = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isOthers: !state[indexToUpdate].isOthers,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isOthers;
        transaction.update(docRef, { isOthers: newValue });
      });
    } catch (error) {}
  };

  // Yes
  const checkBoxYes = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isYes: !state[indexToUpdate].isYes,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isYes;
        transaction.update(docRef, { isYes: newValue });
      });
    } catch (error) {}
  };

  // No
  const checkBoxNo = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isNo: !state[indexToUpdate].isNo,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isNo;
        transaction.update(docRef, { isNo: newValue });
      });
    } catch (error) {}
  };

  // Cigarette
  const checkBoxCigarete = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isCigarette: !state[indexToUpdate].isCigarette,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isCigarette;
        transaction.update(docRef, { isCigarette: newValue });
      });
    } catch (error) {}
  };

  // Smoking
  const checkBoxSmoking = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isSmoking: !state[indexToUpdate].isSmoking,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isSmoking;
        transaction.update(docRef, { isSmoking: newValue });
      });
    } catch (error) {}
  };

  // Alcohol
  const checkBoxAlcohol = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isAlcohol: !state[indexToUpdate].isAlcohol,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isAlcohol;
        transaction.update(docRef, { isAlcohol: newValue });
      });
    } catch (error) {}
  };

  // Coffee
  const checkBoxCoffee = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isCoffee: !state[indexToUpdate].isCoffee,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isCoffee;
        transaction.update(docRef, { isCoffee: newValue });
      });
    } catch (error) {}
  };

  // Steriods
  const checkBoxSteriods = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isSteriods: !state[indexToUpdate].isSteriods,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "medicals", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isSteriods;
        transaction.update(docRef, { isSteriods: newValue });
      });
    } catch (error) {}
  };
  return (
    <>
      <div className="medicals">
        Medical History
        <hr />
        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#medicalHModal"
        >
          Add History
        </button>
        {/* Modal Button */}
        <div className="mdcWrapper" style={{ marginTop: "30px" }}>
          {tasks.map(
            ({
              weight,
              id,
              medication,
              isSickle,
              isDiabetes,
              isHypertension,
              otherHis,
              isEpilepsy,
              isAsthma,
              isHyperthyroidism,
              isHypothyroidism,
              isOthers,
              isYes,
              isNo,
              isCigarette,
              isSmoking,
              isAlcohol,
              isCoffee,
              isSteriods,
            }) => (
              <>
                <div className="medicalhistory" key={id}>
                  <div className="medicaldata">
                    <div className="medicaldata1">
                      <span>
                        <span className="pim">
                          <p>
                            <strong>Any history of the following:&nbsp;</strong>
                          </p>
                          <input
                            type="checkbox"
                            defaultChecked={isSickle}
                            onChange={(e) => checkBoxSickle(e)}
                            name={id}
                          />{" "}
                          <p>Sickle cell</p>
                          <input
                            type="checkbox"
                            defaultChecked={isDiabetes}
                            onChange={(e) => checkBoxDiabetes(e)}
                            name={id}
                          />{" "}
                          <p>Diabetes</p>
                          <input
                            type="checkbox"
                            defaultChecked={isHypertension}
                            onChange={(e) => checkBoxHypertension(e)}
                            name={id}
                          />{" "}
                          <p>Hypertension</p>
                          <input
                            type="checkbox"
                            defaultChecked={isEpilepsy}
                            onChange={(e) => checkBoxEpilepsy(e)}
                            name={id}
                          />{" "}
                          <p>Epilepsy</p>
                          <input
                            type="checkbox"
                            defaultChecked={isAsthma}
                            onChange={(e) => checkBoxAsthma(e)}
                            name={id}
                          />{" "}
                          <p>Asthma</p>
                          <input
                            type="checkbox"
                            defaultChecked={isHyperthyroidism}
                            onChange={(e) => checkBoxHyperthyroidism(e)}
                            name={id}
                          />{" "}
                          <p>Hyperthyroidism</p>
                          <input
                            type="checkbox"
                            defaultChecked={isHypothyroidism}
                            onChange={(e) => checkBoxHypothyroidism(e)}
                            name={id}
                          />{" "}
                          <p>Hypothyroidism</p>
                          <input
                            type="checkbox"
                            defaultChecked={isOthers}
                            onChange={(e) => checkBoxOthers(e)}
                            name={id}
                          />{" "}
                          <p>Others</p>
                        </span>
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>If Others, specify:&nbsp;</strong> {otherHis}{" "}
                        </p>

                        <span className="pim2">
                          <p>
                            <strong>
                              Do you react to any medication:&nbsp;
                            </strong>
                          </p>
                          <input
                            type="checkbox"
                            defaultChecked={isYes}
                            onChange={(e) => checkBoxYes(e)}
                            name={id}
                          />{" "}
                          <p>Yes</p>
                          <input
                            type="checkbox"
                            defaultChecked={isNo}
                            onChange={(e) => checkBoxNo(e)}
                            name={id}
                          />{" "}
                          <p>No</p>
                          <p style={{ fontSize: 18, color: "#01693C" }}>
                            <strong>If yes, specify:&nbsp;</strong> {medication}{" "}
                          </p>
                        </span>

                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Weight Gain or Weight Loss:&nbsp;</strong>{" "}
                          {weight}{" "}
                        </p>

                        <span className="pim">
                          <p>
                            <strong>Do you take any of these:&nbsp;</strong>
                          </p>
                          <input
                            type="checkbox"
                            defaultChecked={isCigarette}
                            onChange={(e) => checkBoxCigarete(e)}
                            name={id}
                          />{" "}
                          <p>Cigarette</p>
                          <input
                            type="checkbox"
                            defaultChecked={isSmoking}
                            onChange={(e) => checkBoxSmoking(e)}
                            name={id}
                          />{" "}
                          <p>Smoking</p>
                          <input
                            type="checkbox"
                            defaultChecked={isAlcohol}
                            onChange={(e) => checkBoxAlcohol(e)}
                            name={id}
                          />{" "}
                          <p>Alcohol</p>
                          <input
                            type="checkbox"
                            defaultChecked={isCoffee}
                            onChange={(e) => checkBoxCoffee(e)}
                            name={id}
                          />{" "}
                          <p>Coffee/Caffeine</p>
                          <input
                            type="checkbox"
                            defaultChecked={isSteriods}
                            onChange={(e) => checkBoxSteriods(e)}
                            name={id}
                          />{" "}
                          <p>Steriods</p>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 text-end">
                  <button type="button" className="btn-pri">
                    <EditMedicals
                      weight={weight}
                      medication={medication}
                      id={id}
                      otherHis={otherHis}
                    />
                  </button>
                </div>
              </>
            )
          )}
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="medicalHModal"
        tabindex="-1"
        aria-labelledby="medicalHModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="medicalHModalLabel">
                  Medical History
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      WEIGHT GAIN OR LOSS
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Weight Status(Gain or Loss)"
                    onChange={(e) => setCreateWeight(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-02">
                      DO YOU REACT TO MED.
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Do you react to medication.."
                    onChange={(e) => setCreateMedication(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-03">
                      OTHER HISTORY
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Other History"
                    onChange={(e) => setCreateOthers(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Medicals
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Medicals;
