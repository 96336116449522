import "./spouse.scss";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Spouse = () => {
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState({
    regNo: "",
  });

  const { userId } = useParams();
  let id = userId;

  useEffect(() => {
    let getUserDoc = async (id) => {
      let docRef = doc(db, `greenleaf/${id}`);
      let docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        let theData = docSnap.data();
        setDataSet(theData);
        const getList = async () => {
          const currentValue = theData.regNo;
          const collectionRef = collection(db, "greenleaf");
          const q = query(collectionRef, where("regNo", "==", currentValue));
          await getDocs(q)
            .then((task) => {
              let medData = task.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
              setData(medData);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        getList();
      }
    };
    getUserDoc(id);
  }, [id]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.id}/casefolder`}>
              <Tooltip title="View Client" placement="bottom">
                <div className="viewButton">
                  <VisibilityIcon />
                </div>
              </Tooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="spouse">
      <h1>Case Folder</h1>
      <div className="spouseWrapper">
        <DataGrid
          className="datagrid"
          rows={data}
          columns={userColumns.concat(actionColumn)}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 2 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Spouse;
