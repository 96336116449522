import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportFemaleAssay = ({
  id,
  reportTitle,
  date,
  hormoneTitle,
  phaseTitle,
  nonMensTitle,
  daysTitle,
  ndaysTitle,
  unitsTitle,
  rangeTitle,
  hormone01,
  hormone02,
  hormone03,
  hormone04,
  hormone05,
  hormone06,
  phase01,
  phase02,
  phase03,
  phase04,
  phase05,
  phase06,
  nMens01,
  nMens02,
  nMens03,
  nMens04,
  nMens05,
  nMens06,
  days01,
  days02,
  days03,
  days04,
  days05,
  days06,
  ndays01,
  ndays02,
  ndays03,
  ndays04,
  ndays05,
  ndays06,
  units01,
  units02,
  units03,
  units04,
  units05,
  units06,
  range01,
  range02,
  range03,
  range04,
  range05,
  range06,
  eDegree,
  eSub,
  eTag,
}) => {
  const [createTitle, setCreateTitle] = useState(reportTitle);
  const [createDate, setCreateDate] = useState(date);
  const [createHormoneTitle, setCreateHormoneTitle] = useState(hormoneTitle);
  const [createPhaseTitle, setCreatePhaseTitle] = useState(phaseTitle);
  const [createNmensTitle, setCreateNmensTitle] = useState(nonMensTitle);
  const [createDaysTitle, setCreateDaysTitle] = useState(daysTitle);
  const [createNDaysTitle, setCreateNDaysTitle] = useState(ndaysTitle);
  const [createUnitsTitle, setCreateUnitsTitle] = useState(unitsTitle);
  const [createRangeTitle, setCreateRangeTitle] = useState(rangeTitle);
  const [createHormone01, setCreateHormone01] = useState(hormone01);
  const [createHormone02, setCreateHormone02] = useState(hormone02);
  const [createHormone03, setCreateHormone03] = useState(hormone03);
  const [createHormone04, setCreateHormone04] = useState(hormone04);
  const [createHormone05, setCreateHormone05] = useState(hormone05);
  const [createHormone06, setCreateHormone06] = useState(hormone06);
  const [createPhase01, setCreatePhase01] = useState(phase01);
  const [createPhase02, setCreatePhase02] = useState(phase02);
  const [createPhase03, setCreatePhase03] = useState(phase03);
  const [createPhase04, setCreatePhase04] = useState(phase04);
  const [createPhase05, setCreatePhase05] = useState(phase05);
  const [createPhase06, setCreatePhase06] = useState(phase06);
  const [createNmens01, setCreateNmens01] = useState(nMens01);
  const [createNmens02, setCreateNmens02] = useState(nMens02);
  const [createNmens03, setCreateNmens03] = useState(nMens03);
  const [createNmens04, setCreateNmens04] = useState(nMens04);
  const [createNmens05, setCreateNmens05] = useState(nMens05);
  const [createNmens06, setCreateNmens06] = useState(nMens06);
  const [createDays01, setCreateDays01] = useState(days01);
  const [createDays02, setCreateDays02] = useState(days02);
  const [createDays03, setCreateDays03] = useState(days03);
  const [createDays04, setCreateDays04] = useState(days04);
  const [createDays05, setCreateDays05] = useState(days05);
  const [createDays06, setCreateDays06] = useState(days06);
  const [createNDays01, setCreateNDays01] = useState(ndays01);
  const [createNDays02, setCreateNDays02] = useState(ndays02);
  const [createNDays03, setCreateNDays03] = useState(ndays03);
  const [createNDays04, setCreateNDays04] = useState(ndays04);
  const [createNDays05, setCreateNDays05] = useState(ndays05);
  const [createNDays06, setCreateNDays06] = useState(ndays06);
  const [createUnits01, setCreateUnits01] = useState(units01);
  const [createUnits02, setCreateUnits02] = useState(units02);
  const [createUnits03, setCreateUnits03] = useState(units03);
  const [createUnits04, setCreateUnits04] = useState(units04);
  const [createUnits05, setCreateUnits05] = useState(units05);
  const [createUnits06, setCreateUnits06] = useState(units06);
  const [createRange01, setCreateRange01] = useState(range01);
  const [createRange02, setCreateRange02] = useState(range02);
  const [createRange03, setCreateRange03] = useState(range03);
  const [createRange04, setCreateRange04] = useState(range04);
  const [createRange05, setCreateRange05] = useState(range05);
  const [createRange06, setCreateRange06] = useState(range06);
  const [createEmbryoDegree, setCreateEmbryoDegree] = useState(eDegree);
  const [createEmbryoSub, setCreateEmbryoSub] = useState(eSub);
  const [createEmbryoTag, setCreateEmbryoTag] = useState(eTag);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "reportfmha", id);
      await updateDoc(taskDocument, {
        reportTitle: createTitle,
        hormoneTitle: createHormoneTitle,
        phaseTitle: createPhaseTitle,
        nonMensTitle: createNmensTitle,
        daysTitle: createDaysTitle,
        ndaysTitle: createNDaysTitle,
        unitsTitle: createUnitsTitle,
        rangeTitle: createRangeTitle,
        date: createDate,
        hormone01: createHormone01,
        hormone02: createHormone02,
        hormone03: createHormone03,
        hormone04: createHormone04,
        hormone05: createHormone05,
        hormone06: createHormone06,
        phase01: createPhase01,
        phase02: createPhase02,
        phase03: createPhase03,
        phase04: createPhase04,
        phase05: createPhase05,
        phase06: createPhase06,
        nMens01: createNmens01,
        nMens02: createNmens02,
        nMens03: createNmens03,
        nMens04: createNmens04,
        nMens05: createNmens05,
        nMens06: createNmens06,
        days01: createDays01,
        days02: createDays02,
        days03: createDays03,
        days04: createDays04,
        days05: createDays05,
        days06: createDays06,
        ndays01: createNDays01,
        ndays02: createNDays02,
        ndays03: createNDays03,
        ndays04: createNDays04,
        ndays05: createNDays05,
        ndays06: createNDays06,
        units01: createUnits01,
        units02: createUnits02,
        units03: createUnits03,
        units04: createUnits04,
        units05: createUnits05,
        units06: createUnits06,
        range01: createRange01,
        range02: createRange02,
        range03: createRange03,
        range04: createRange04,
        range05: createRange05,
        range06: createRange06,
        eDegree: createEmbryoDegree,
        eSub: createEmbryoSub,
        eTag: createEmbryoTag,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update FMHA
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REPORT TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Report Title"
                    defaultValue={createTitle}
                    onChange={(e) => setCreateTitle(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      HORMONE,PHASES,DAYS,UNITS,RANGE HEADER
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Header"
                    defaultValue={createHormoneTitle}
                    onChange={(e) => setCreateHormoneTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phases Header"
                    defaultValue={createPhaseTitle}
                    onChange={(e) => setCreatePhaseTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Header"
                    defaultValue={createNmensTitle}
                    onChange={(e) => setCreateNmensTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Header"
                    defaultValue={createDaysTitle}
                    onChange={(e) => setCreateDaysTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Header"
                    defaultValue={createNDaysTitle}
                    onChange={(e) => setCreateNDaysTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Header"
                    defaultValue={createUnitsTitle}
                    onChange={(e) => setCreateUnitsTitle(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Header"
                    defaultValue={createRangeTitle}
                    onChange={(e) => setCreateRangeTitle(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1A">
                      ROW 1A, 1B, 1C, 1D, 1E, 1F, 1G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone01}
                    onChange={(e) => setCreateHormone01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase01}
                    onChange={(e) => setCreatePhase01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens01}
                    onChange={(e) => setCreateNmens01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays01}
                    onChange={(e) => setCreateDays01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays01}
                    onChange={(e) => setCreateNDays01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits01}
                    onChange={(e) => setCreateUnits01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange01}
                    onChange={(e) => setCreateRange01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2A">
                      ROW 2A, 2B, 2C, 2D, 2E, 2F, 2G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone02}
                    onChange={(e) => setCreateHormone02(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase02}
                    onChange={(e) => setCreatePhase02(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens02}
                    onChange={(e) => setCreateNmens02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays02}
                    onChange={(e) => setCreateDays02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays02}
                    onChange={(e) => setCreateNDays02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits02}
                    onChange={(e) => setCreateUnits02(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange02}
                    onChange={(e) => setCreateRange02(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3A">
                      ROW 3A, 3B, 3C, 3D, 3E, 3F, 3G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone03}
                    onChange={(e) => setCreateHormone03(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase03}
                    onChange={(e) => setCreatePhase03(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens03}
                    onChange={(e) => setCreateNmens03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays03}
                    onChange={(e) => setCreateDays03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays03}
                    onChange={(e) => setCreateNDays03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits03}
                    onChange={(e) => setCreateUnits03(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange03}
                    onChange={(e) => setCreateRange03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4A">
                      ROW 4A, 4B, 4C, 4D, 4E, 4F, 4G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone04}
                    onChange={(e) => setCreateHormone04(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase04}
                    onChange={(e) => setCreatePhase04(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens04}
                    onChange={(e) => setCreateNmens04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays04}
                    onChange={(e) => setCreateDays04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays04}
                    onChange={(e) => setCreateNDays04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits04}
                    onChange={(e) => setCreateUnits04(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange04}
                    onChange={(e) => setCreateRange04(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5A">
                      ROW 5A, 5B, 5C, 5D, 5E, 5F, 5G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone05}
                    onChange={(e) => setCreateHormone05(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase05}
                    onChange={(e) => setCreatePhase05(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens05}
                    onChange={(e) => setCreateNmens05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays05}
                    onChange={(e) => setCreateDays05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays05}
                    onChange={(e) => setCreateNDays05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits05}
                    onChange={(e) => setCreateUnits05(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange05}
                    onChange={(e) => setCreateRange05(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6A">
                      ROW 6A, 6B, 6C, 6D, 6E, 6F, 6G VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Value"
                    defaultValue={createHormone06}
                    onChange={(e) => setCreateHormone06(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phase Value"
                    defaultValue={createPhase06}
                    onChange={(e) => setCreatePhase06(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Non Men's Value"
                    defaultValue={createNmens06}
                    onChange={(e) => setCreateNmens06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 2-5 Value"
                    defaultValue={createDays06}
                    onChange={(e) => setCreateDays06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Day 21-23 Value"
                    defaultValue={createNDays06}
                    onChange={(e) => setCreateNDays06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Value"
                    defaultValue={createUnits06}
                    onChange={(e) => setCreateUnits06(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Normal Range Value"
                    defaultValue={createRange06}
                    onChange={(e) => setCreateRange06(e.target.value)}
                  />
                </div>

                {/* Embryo Data */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-13">
                      EMBRYO DEGREE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Degree"
                    defaultValue={createEmbryoDegree}
                    onChange={(e) => setCreateEmbryoDegree(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-14">
                      SUB EMBRYO{" "}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Cert Info"
                    defaultValue={createEmbryoSub}
                    onChange={(e) => setCreateEmbryoSub(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-15">
                      EMBRYO TAG
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Tag"
                    defaultValue={createEmbryoTag}
                    onChange={(e) => setCreateEmbryoTag(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportFemaleAssay;
