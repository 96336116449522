import "./navbar.scss";
import { useContext, useEffect, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { SearchOutlined } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { BellFilled } from "@ant-design/icons";
import { Badge, Drawer, Image, List, Space, Typography } from "antd";
import { getOrders } from "../../API";
import {
  collection,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";

// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [totalQuery, setTotalQuery] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const pcMode = useMediaQuery({ query: "(min-width: 1200px)" });

  // useEffect(() => {
  //   getOrders().then((res) => {
  //     setOrders(res.products);
  //   });
  // }, []);

  async function fetchTotal() {
    const coll = collection(db, "notifications");
    const snapshot = await getCountFromServer(coll);
    setTotalQuery(snapshot.data().count);
  }

  fetchTotal();

  const collectionRef = collection(db, "notifications");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "desc"));
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  return (
    <>
      <div
        className={
          pcMode
            ? "navbar navbar-light bg-light sticky-top"
            : "navbar sticky-top"
        }
      >
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search docs..." />
            <SearchOutlined />
          </div>

          <div className="items">
            <div className="item">
              <TravelExploreIcon
                style={{ color: "#01693C" }}
                className="icon"
              />
              <Link to="/global">
                <button
                  style={{
                    border: "none",
                    marginLeft: "5px",
                    backgroundColor: "#01693C",
                    color: "#f8f8f8",
                    borderRadius: "14px",
                    lineHeight: "2",
                  }}
                >
                  Global
                </button>
              </Link>
            </div>

            {/* Notication icon vis logic */}
            <div className="overlayDrawer">
              <Space>
                <Badge count={`${totalQuery}+`}>
                  <BellFilled
                    style={{ fontSize: 24 }}
                    onClick={() => {
                      setNotificationsOpen(true);
                    }}
                  />
                </Badge>
              </Space>
              <Drawer
                title="Notifications"
                open={notificationsOpen}
                onClose={() => {
                  setNotificationsOpen(false);
                }}
                maskClosable
                rootClassName="overlayD"
              >
                <List
                  dataSource={tasks}
                  renderItem={(item) => {
                    return (
                      <List.Item>
                        <Typography.Text strong>{item.msg}</Typography.Text>
                        <Typography.Text strong>{item.date}</Typography.Text>
                      </List.Item>
                    );
                  }}
                ></List>
              </Drawer>
            </div>
            {/* <div className="item">
            <NotificationsNoneRounded className="icon"
            />
          <div className="counter">new</div>
          </div> */}

            {/* Notication icon vis logic */}

            <div className="item itemsL">
              <img src={currentUser.photoURL} alt="" className="avatar" />
              <span>{currentUser.displayName}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
