import "./waitingroom.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import EditTask from "./EditTask";

const WaitingRoom = () => {
  const [tasks, setTasks] = useState([]);
  const [createTask, setCreateTask] = useState("");
  const [createClock, setCreateClock] = useState("");
  const [checked, setChecked] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const recordsPerPage = 500;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = tasks.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(tasks.length / recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);

  const collectionRef = collection(db, "tasks");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "desc"));
      await getDocs(q)
        .then((task) => {
          let tasksData = task.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
          setChecked(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  //Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collectionRef, {
        task: createTask,
        clockout: createClock,
        isChecked: false,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Handler
  const deleteTask = async (id) => {
    try {
      const rooms_Del = window.confirm(
        "Are you sure you want to delete this room?"
      );
      if (rooms_Del == true) {
        const documentRef = doc(db, "tasks", id);
        await deleteDoc(documentRef);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkBoxHandler = async (e, task) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isChecked: !state[indexToUpdate].isChecked,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "tasks", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isChecked;
        transaction.update(docRef, { isChecked: newValue });
      });
    } catch (error) {}
  };

  return (
    <>
      <div className="waitingroom">
        <p>Waiting List</p>
        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Who's Waiting
        </button>

        {records.map(({ task, id, timeStamp, isChecked, clockout }) => (
          // react checks amount of data rendered on a page using ID
          <div className="rooms" key={id}>
            <input
              type="checkbox"
              defaultChecked={isChecked}
              onChange={(e) => checkBoxHandler(e, task)}
              name={id}
            />
            <span className={`${isChecked === true ? "done" : ""}`}>
              {task}
              <br />
              <i>{new Date(timeStamp.seconds * 1000).toLocaleString()}</i>
              <br />
              <i>{clockout}</i>
            </span>
            <div className="buttonfloat">
              <button type="button" className="btn-primary">
                <EditTask task={task} id={id} clockout={clockout} />
              </button>
              <button
                type="button"
                className="btn btn-danger float-end"
                onClick={() => deleteTask(id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div className="pageWrapper">
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <a href="#" className="page-link" onClick={prevPage}>
                Prev
              </a>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => changeCPage(n)}
                >
                  {n}
                </a>
              </li>
            ))}

            <li className="page-item">
              <a href="#" className="page-link" onClick={nextPage}>
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Unattended
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Who's on the line..."
                    onChange={(e) => setCreateTask(e.target.value)}
                  />

                  <input
                    type="time"
                    className="form-control"
                    onChange={(e) => setCreateClock(e.target.value)}
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Room
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );

  function prevPage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default WaitingRoom;
