import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StreamIcon from "@mui/icons-material/Stream";
import InsightsIcon from "@mui/icons-material/Insights";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Person3Icon from "@mui/icons-material/Person3";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { ChatOutlined } from "@mui/icons-material";

// Cards import
import { KeyboardArrowUpOutlined, PersonOutlined } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase";

export const SidebarData = [
  {
    link: "/",
    icon: DashboardIcon,
    heading: "Dashboard",
  },
  {
    link: "/users",
    icon: PersonIcon,
    heading: "Clients",
  },
  {
    link: "/users/new",
    icon: HowToRegIcon,
    heading: "Registration",
  },
  {
    link: "/room",
    icon: HourglassTopIcon,
    heading: "Room",
  },
  // {
  //     link: '/chat',
  //     icon: ChatOutlined,
  //     heading: 'Chat',
  // },
  {
    link: "/events",
    icon: EventAvailableIcon,
    heading: "Events",
  },
  // {
  //     link: '#',
  //     icon: Diversity3Icon,
  //     heading: 'Adoption',
  // }
];

export const CardsData = [
  {
    title: "Clients",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 70,
    value: "2570",
    icon: PeopleIcon,
  },
  {
    title: "Room",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 90,
    value: "600",
    icon: HourglassTopIcon,
  },
  {
    title: "Events",
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    barValue: 80,
    value: "370",
    icon: EventAvailableIcon,
  },
];
