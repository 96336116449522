import "./consentform.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import EConsentForm from "../../components/econsentform/EConsentForm";

const ConsentForm = () => {
  return (
    <div className='cstform'>
    <Sidebar/>
    <div className="cstformContainer">
    <Navbar/>
    <div className="cstf">
    <EConsentForm/>
    </div>
    </div>
    </div>
  )
}

export default ConsentForm
