import "./trackschedules.scss";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { v4 as uuid } from "uuid";
import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
// import EditTrackSchedules from "./EditTrackSchedules";

const TrackSchedules = () => {
  const [events, setEvents] = useState({});
  const [createDate, setCreateDate] = useState("");
  const [createTitle, setCreateTitle] = useState("");
  const [createTags, setCreateTags] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const collectionRef = collection(db, "calendar");

  const getEvents = () => {
    const q = query(collectionRef);
    getDocs(q)
      .then((eventsD) => {
        let eventsData = eventsD.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setEvents(eventsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  //Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        id: uuid(),
        title: createTitle,
        date: createDate,
        tags: createTags,
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleEventClick = async (selected) => {
    try {
      const events_Del = window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}`
      );
      if (events_Del == true) {
        const documentRef = doc(db, "calendar", selected.event.id);
        await deleteDoc(documentRef);
        window.location.reload();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="atrck">
        <p>Appointments</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#reminderModal"
          style={{ marginBottom: "10px" }}
        >
          Add Reminder
        </button>

        {/* Full Calendar */}
        <div className="calendarWrapper">
          <FullCalendar
            editable={true}
            selectable={true}
            events={events}
            headerToolbar={{
              start: "today prev next",
              end: "dayGridMonth dayGridWeek dayGridDay",
            }}
            plugins={[daygridPlugin, interactionPlugin]}
            views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
            eventClick={handleEventClick}
          />
        </div>
        {/* Full Calendar */}
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="reminderModal"
        tabindex="-1"
        aria-labelledby="reminderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="reminderModalLabel">
                  Notify
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-01">
                        DATE ASSIGNED
                      </span>
                    </div>
                    <input
                      type="date"
                      name="date"
                      className="form-control"
                      onChange={(e) => setCreateDate(e.target.value)}
                      // value={events.date}
                      // onChange={handleInput}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-02">
                        DETAILS
                      </span>
                    </div>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="State client's appointment details"
                      onChange={(e) => setCreateTitle(e.target.value)}
                      // value={events.title}
                      // onChange={handleInput}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-03">
                        TAG
                      </span>
                    </div>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Tag an appointment"
                      onChange={(e) => setCreateTags(e.target.value)}
                      // value={events.tags}
                      // onChange={handleInput}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Notifier
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TrackSchedules;
