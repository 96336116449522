import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditTpChart = ({ id, bloodPressure, pulseRate, bmi, weight, height }) => {
  const [createBp, setCreateBp] = useState(bloodPressure);
  const [createPr, setCreatePr] = useState(pulseRate);
  const [createBmi, setCreateBmi] = useState(bmi);
  const [createWeight, setCreateWeight] = useState(weight);
  const [createHeight, setCreateHeight] = useState(height);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "tpchart", id);
      await updateDoc(taskDocument, {
        bloodPressure: createBp,
        pulseRate: createPr,
        bmi: createBmi,
        weight: createWeight,
        height: createHeight,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update TPR Chart
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-02">
                      BLOOD PRESSURE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter blood pressure..."
                    defaultValue={createBp}
                    onChange={(e) => setCreateBp(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-03">
                      PULSE RATE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Pulse Rate..."
                    defaultValue={createPr}
                    onChange={(e) => setCreatePr(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-04">
                      BODY MASS INDEX
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Body Mass Index..."
                    defaultValue={createBmi}
                    onChange={(e) => setCreateBmi(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-05">
                      WEIGHT
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Weight..."
                    defaultValue={createWeight}
                    onChange={(e) => setCreateWeight(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-06">
                      HEIGHT
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Height..."
                    defaultValue={createHeight}
                    onChange={(e) => setCreateHeight(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTpChart;
