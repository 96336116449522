import "./navbar.scss";
import {
  FullscreenExitOutlined,
  LanguageOutlined,
  ListOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import PersonIcon from "@mui/icons-material/Person";
import { useParams } from "react-router-dom";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Navbar = () => {
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const pcMode = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div
      className={
        pcMode
          ? "navbarP navbar-light bg-light sticky-top"
          : "navbar sticky-top"
      }
    >
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search docs..." />
          <SearchOutlined />
        </div>

        <div
          className="usersc mobileClient"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <p style={{ fontWeight: "900" }}>
            {" "}
            <PersonIcon style={{ color: "#01693C" }} /> {users.title}{" "}
            {users.firstName} {users.lastName}
          </p>
        </div>

        <div className="items">
          <div className="item">
            <TravelExploreIcon style={{ color: "#01693C" }} className="icon" />
            <Link to="/global">
              <button
                style={{
                  border: "none",
                  marginLeft: "5px",
                  backgroundColor: "#01693C",
                  color: "#f8f8f8",
                  borderRadius: "12px",
                }}
              >
                Global
              </button>
            </Link>
          </div>

          <div className="item">
            <NotificationsActiveIcon
              style={{ color: "#023020" }}
              className="icon"
            />
            <Link to="/alarm">
              <button
                style={{
                  border: "none",
                  marginLeft: "5px",
                  backgroundColor: "#023020",
                  color: "#f8f8f8",
                  borderRadius: "12px",
                }}
              >
                Set a reminder
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Navbar;
