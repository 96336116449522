import "./spermalarm.scss";
import {useEffect, useState} from 'react'
import {db} from "../../firebase"
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";

const Spermalarm = () => {

  const [createUserName, setCreateUserName] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createPDD, setCreatePDD] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const collectionRef = collection(db, 'alarmfreezing')

   //Add Task Handler
 const submitTask = async (e) => {
  e.preventDefault();

  try {
    await addDoc(collectionRef, {
      date: createDate,
      userName: createUserName,
      datePrompt: createPDD,
      timeStamp: serverTimestamp()
    })
    setButtonDisabled(true);
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}

useEffect(() =>{
  setCreateDate(moment(createPDD).add(83, 'days').calendar())
}, [createPDD]);

  return (
    <>
    <div className="spermalarm">
        <div className="salarmcontainer">
       <h4>Set Reminders For Sperm Freezing!!!</h4>
    <hr />
       <span class="bi bi-bell bell"></span>

    {/* Modal Button */}
    <div class="text-center">
      <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#spermReminderModal">
    Remind me!
  </button>
  </div>
  </div>
    </div>

        {/* Modal */}
        <div className="modal fade" id="spermReminderModal" tabindex="-1" aria-labelledby="spermReminderModalLabel" aria-hidden="true">
  <div className="modal-dialog">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="spermReminderModalLabel">Sperm Trigger</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      
      <form>
    <input
      type="text"
      className="form-control"
      placeholder="Username"
      onChange={(e) => setCreateUserName(e.target.value)}
    />

    <input
      type="date"
      className="form-control"
      onChange={(e) => setCreatePDD(e.target.value)}
    />
</form>
    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" disabled={buttonDisabled}>Add Event</button>
      </div>
    </div>
    </form>
  </div>
</div>
    </>
  )
}

export default Spermalarm
