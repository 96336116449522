import "./sidebar.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState } from "react";
import userLogOut from "../../auth/UserLogout";
import logo from "../../bgImg/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { SidebarData } from "../Data/Data";
import { useMediaQuery } from "react-responsive";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const { error, logOut } = userLogOut();

  const handleLogOut = async () => {
    await logOut();

    if (!error) {
      navigate("/login");
    }
  };

  const mobileMode = useMediaQuery({ query: "(max-width: 568px)" });
  //  const selected = window.location.pathname.split("/")[1];
  //  const [selected, setSelected] = useState("");
  return (
    <>
      <div className={"sidebar sticky-xl-top"}>
        <div className="top sticky-top">
          <Link to="/" style={{ textDecoration: "none" }}>
            <span className="logo">
              <img src={logo} alt="logo" />
              <p>greenleaf</p>
            </span>
          </Link>
        </div>
        <hr />

        <div className="center menu">
          <ul>
            <p className="title">MAIN</p>
            {SidebarData.map((item, index) => {
              return (
                <div
                  key={index}
                  // className={selected === index ? "menuItem active" : "menuItem"}
                  // onClick={() => setSelected(index)}
                >
                  <Link to={item.link} style={{ textDecoration: "none" }}>
                    <li>
                      <item.icon className="icon" />
                      <span className="titleHead">{item.heading}</span>
                    </li>
                  </Link>
                </div>
              );
            })}

            <p className="title">EXIT</p>
            {/* Logout Icon and function */}
            <div style={{ marginBottom: "18px" }}>
              <li>
                <LogoutIcon
                  className="icon"
                  onClick={handleLogOut}
                  type="button"
                />
                <span
                  className="titleHead"
                  onClick={handleLogOut}
                  type="button"
                >
                  Logout
                </span>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
