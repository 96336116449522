import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";

const EditTrackReview = ({
  id,
  reviewHeader1,
  reviewHeader2,
  reviewHeader3,
  reviewHeader4,
  sperm,
  sperm01,
  sperm02,
  sperm03,
  ovarian,
  ovarian01,
  ovarian02,
  ovarian03,
  womb,
  womb01,
  womb02,
  womb03,
  medical,
  medical01,
  medical02,
  medical03,
  conclusion,
  remark,
  plan,
  reported,
}) => {
  const [reviewHeader01, setReviewHeader01] = useState(reviewHeader1);
  const [reviewHeader02, setReviewHeader02] = useState(reviewHeader2);
  const [reviewHeader03, setReviewHeader03] = useState(reviewHeader3);
  const [reviewHeader04, setReviewHeader04] = useState(reviewHeader4);
  const [createSperm, setCreateSperm] = useState(sperm);
  const [createSperm01, setCreateSperm01] = useState(sperm01);
  const [createSperm02, setCreateSperm02] = useState(sperm02);
  const [createSperm03, setCreateSperm03] = useState(sperm03);
  const [createOvarian, setCreateOvarian] = useState(ovarian);
  const [createOvarian01, setCreateOvarian01] = useState(ovarian01);
  const [createOvarian02, setCreateOvarian02] = useState(ovarian02);
  const [createOvarian03, setCreateOvarian03] = useState(ovarian03);
  const [createWomb, setCreateWomb] = useState(womb);
  const [createWomb01, setCreateWomb01] = useState(womb01);
  const [createWomb02, setCreateWomb02] = useState(womb02);
  const [createWomb03, setCreateWomb03] = useState(womb03);
  const [createMedical, setCreateMedical] = useState(medical);
  const [createMedical01, setCreateMedical01] = useState(medical01);
  const [createMedical02, setCreateMedical02] = useState(medical02);
  const [createMedical03, setCreateMedical03] = useState(medical03);
  const [createConclusion, setCreateConclusion] = useState(conclusion);
  const [createRemark, setCreateRemark] = useState(remark);
  const [createPlan, setCreatePlan] = useState(plan);
  const [createReport, setCreateReport] = useState(reported);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "reviewresult", id);
      await updateDoc(taskDocument, {
        reviewHeader1: reviewHeader01,
        reviewHeader2: reviewHeader02,
        reviewHeader3: reviewHeader03,
        reviewHeader4: reviewHeader04,
        sperm: createSperm,
        sperm01: createSperm01,
        sperm02: createSperm02,
        sperm03: createSperm03,
        ovarian: createOvarian,
        ovarian01: createOvarian01,
        ovarian02: createOvarian02,
        ovarian03: createOvarian03,
        womb: createWomb,
        womb01: createWomb01,
        womb02: createWomb02,
        womb03: createWomb03,
        medical: createMedical,
        medical01: createMedical01,
        medical02: createMedical02,
        medical03: createMedical03,
        conclusion: createConclusion,
        remark: createRemark,
        plan: createPlan,
        reported: createReport,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update Reviews
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      SPERM, OVARIAN, WOMB, MEDICAL HEADER
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sperm Quality Header"
                    defaultValue={reviewHeader01}
                    onChange={(e) => setReviewHeader01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ovarian Function Header"
                    defaultValue={reviewHeader02}
                    onChange={(e) => setReviewHeader02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Womb Integrity Header"
                    defaultValue={reviewHeader03}
                    onChange={(e) => setReviewHeader03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Medical Fitness Header"
                    defaultValue={reviewHeader04}
                    onChange={(e) => setReviewHeader04(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      ROW 1A, 1B, 1C, 1D VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sperm Quality"
                    defaultValue={createSperm}
                    onChange={(e) => setCreateSperm(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ovarian Function"
                    defaultValue={createOvarian}
                    onChange={(e) => setCreateOvarian(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Womb Integrity"
                    defaultValue={createWomb}
                    onChange={(e) => setCreateWomb(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Medical Fitness"
                    defaultValue={createMedical}
                    onChange={(e) => setCreateMedical(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      ROW 2A, 2B, 2C, 2D VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sperm Quality"
                    defaultValue={createSperm01}
                    onChange={(e) => setCreateSperm01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ovarian Function"
                    defaultValue={createOvarian01}
                    onChange={(e) => setCreateOvarian01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Womb Integrity"
                    defaultValue={createWomb01}
                    onChange={(e) => setCreateWomb01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Medical Fitness"
                    defaultValue={createMedical01}
                    onChange={(e) => setCreateMedical01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      ROW 3A, 3B, 3C, 3D VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sperm Quality"
                    defaultValue={createSperm02}
                    onChange={(e) => setCreateSperm02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ovarian Function"
                    defaultValue={createOvarian02}
                    onChange={(e) => setCreateOvarian02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Womb Integrity"
                    defaultValue={createWomb02}
                    onChange={(e) => setCreateWomb02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Medical Fitness"
                    defaultValue={createMedical02}
                    onChange={(e) => setCreateMedical02(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      ROW 4A, 4B, 4C, 4D VALUES
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Sperm Quality"
                    defaultValue={createSperm03}
                    onChange={(e) => setCreateSperm03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ovarian Function"
                    defaultValue={createOvarian03}
                    onChange={(e) => setCreateOvarian03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Womb Integrity"
                    defaultValue={createWomb03}
                    onChange={(e) => setCreateWomb03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Medical Fitness"
                    defaultValue={createMedical03}
                    onChange={(e) => setCreateMedical03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      CONCLUSION
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="enter conclusion"
                    defaultValue={createConclusion}
                    onChange={(e) => setCreateConclusion(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REMARK
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="enter remarks"
                    defaultValue={createRemark}
                    onChange={(e) => setCreateRemark(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      PLAN
                    </span>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="enter plan"
                    defaultValue={createPlan}
                    onChange={(e) => setCreatePlan(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REPORTED BY
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="reported by.."
                    defaultValue={createReport}
                    onChange={(e) => setCreateReport(e.target.value)}
                    size="3080"
                  />
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update Reviews
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTrackReview;
