import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportMaleAssay = ({
  id,
  reportTitle,
  date,
  hormoneTitle,
  hormone01,
  hormone02,
  hormone03,
  hormone04,
  hormone05,
  hormone06,
  hormone07,
  hormone08,
  hormone09,
  hormone10,
  daysTitle,
  dayMone01,
  dayMone02,
  dayMone03,
  dayMone04,
  dayMone05,
  dayMone06,
  dayMone07,
  dayMone08,
  dayMone09,
  dayMone10,
  mipTitle,
  mip01,
  mip02,
  mip03,
  mip04,
  mip05,
  mip06,
  mip07,
  mip08,
  mip09,
  mip10,
  unitTitle,
  unit01,
  unit02,
  unit03,
  unit04,
  unit05,
  unit06,
  unit07,
  unit08,
  unit09,
  unit10,
  rangeTitle,
  range01,
  range02,
  range03,
  range04,
  range05,
  range06,
  range07,
  range08,
  range09,
  range10,
  eDegree,
  eSub,
  eTag,
}) => {
  const [createTitle, setCreateTitle] = useState(reportTitle);
  const [createHormoneTitle, setCreateHormoneTitle] = useState(hormoneTitle);
  const [createHormone01, setCreateHormone01] = useState(hormone01);
  const [createHormone02, setCreateHormone02] = useState(hormone02);
  const [createHormone03, setCreateHormone03] = useState(hormone03);
  const [createHormone04, setCreateHormone04] = useState(hormone04);
  const [createHormone05, setCreateHormone05] = useState(hormone05);
  const [createHormone06, setCreateHormone06] = useState(hormone06);
  const [createHormone07, setCreateHormone07] = useState(hormone07);
  const [createHormone08, setCreateHormone08] = useState(hormone08);
  const [createHormone09, setCreateHormone09] = useState(hormone09);
  const [createHormone10, setCreateHormone10] = useState(hormone10);
  const [createDaysTitle, setCreateDaysTitle] = useState(daysTitle);
  const [createDaymone01, setCreateDaymone01] = useState(dayMone01);
  const [createDaymone02, setCreateDaymone02] = useState(dayMone02);
  const [createDaymone03, setCreateDaymone03] = useState(dayMone03);
  const [createDaymone04, setCreateDaymone04] = useState(dayMone04);
  const [createDaymone05, setCreateDaymone05] = useState(dayMone05);
  const [createDaymone06, setCreateDaymone06] = useState(dayMone06);
  const [createDaymone07, setCreateDaymone07] = useState(dayMone07);
  const [createDaymone08, setCreateDaymone08] = useState(dayMone08);
  const [createDaymone09, setCreateDaymone09] = useState(dayMone09);
  const [createDaymone10, setCreateDaymone10] = useState(dayMone10);
  const [createMipTitle, setCreateMipTitle] = useState(mipTitle);
  const [createMip01, setCreateMip01] = useState(mip01);
  const [createMip02, setCreateMip02] = useState(mip02);
  const [createMip03, setCreateMip03] = useState(mip03);
  const [createMip04, setCreateMip04] = useState(mip04);
  const [createMip05, setCreateMip05] = useState(mip05);
  const [createMip06, setCreateMip06] = useState(mip06);
  const [createMip07, setCreateMip07] = useState(mip07);
  const [createMip08, setCreateMip08] = useState(mip08);
  const [createMip09, setCreateMip09] = useState(mip09);
  const [createMip10, setCreateMip10] = useState(mip10);
  const [createUnitTitle, setCreateUnitTitle] = useState(unitTitle);
  const [createUnit01, setCreateUnit01] = useState(unit01);
  const [createUnit02, setCreateUnit02] = useState(unit02);
  const [createUnit03, setCreateUnit03] = useState(unit03);
  const [createUnit04, setCreateUnit04] = useState(unit04);
  const [createUnit05, setCreateUnit05] = useState(unit05);
  const [createUnit06, setCreateUnit06] = useState(unit06);
  const [createUnit07, setCreateUnit07] = useState(unit07);
  const [createUnit08, setCreateUnit08] = useState(unit08);
  const [createUnit09, setCreateUnit09] = useState(unit09);
  const [createUnit10, setCreateUnit10] = useState(unit10);
  const [createRangeTitle, setCreateRangeTitle] = useState(rangeTitle);
  const [createRange01, setCreateRange01] = useState(range01);
  const [createRange02, setCreateRange02] = useState(range02);
  const [createRange03, setCreateRange03] = useState(range03);
  const [createRange04, setCreateRange04] = useState(range04);
  const [createRange05, setCreateRange05] = useState(range05);
  const [createRange06, setCreateRange06] = useState(range06);
  const [createRange07, setCreateRange07] = useState(range07);
  const [createRange08, setCreateRange08] = useState(range08);
  const [createRange09, setCreateRange09] = useState(range09);
  const [createRange10, setCreateRange10] = useState(range10);
  const [createDate, setCreateDate] = useState(date);
  const [createEmbryoDegree, setCreateEmbryoDegree] = useState(eDegree);
  const [createEmbryoSub, setCreateEmbryoSub] = useState(eSub);
  const [createEmbryoTag, setCreateEmbryoTag] = useState(eTag);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "reportmha", id);
      await updateDoc(taskDocument, {
        reportTitle: createTitle,
        date: createDate,
        hormoneTitle: createHormoneTitle,
        hormone01: createHormone01,
        hormone02: createHormone02,
        hormone03: createHormone03,
        hormone04: createHormone04,
        hormone05: createHormone05,
        hormone06: createHormone06,
        hormone07: createHormone07,
        hormone08: createHormone08,
        hormone09: createHormone09,
        hormone10: createHormone10,
        daysTitle: createDaysTitle,
        dayMone01: createDaymone01,
        dayMone02: createDaymone02,
        dayMone03: createDaymone03,
        dayMone04: createDaymone04,
        dayMone05: createDaymone05,
        dayMone06: createDaymone06,
        dayMone07: createDaymone07,
        dayMone08: createDaymone08,
        dayMone09: createDaymone09,
        dayMone10: createDaymone10,
        mipTitle: createMipTitle,
        mip01: createMip01,
        mip02: createMip02,
        mip03: createMip03,
        mip04: createMip04,
        mip05: createMip05,
        mip06: createMip06,
        mip07: createMip07,
        mip08: createMip08,
        mip09: createMip09,
        mip10: createMip10,
        unitTitle: createUnitTitle,
        unit01: createUnit01,
        unit02: createUnit02,
        unit03: createUnit03,
        unit04: createUnit04,
        unit05: createUnit05,
        unit06: createUnit06,
        unit07: createUnit07,
        unit08: createUnit08,
        unit09: createUnit09,
        unit10: createUnit10,
        rangeTitle: createRangeTitle,
        range01: createRange01,
        range02: createRange02,
        range03: createRange03,
        range04: createRange04,
        range05: createRange05,
        range06: createRange06,
        range07: createRange07,
        range08: createRange08,
        range09: createRange09,
        range10: createRange10,
        eDegree: createEmbryoDegree,
        eSub: createEmbryoSub,
        eTag: createEmbryoTag,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update MHA
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REPORT TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Report Title"
                    defaultValue={createTitle}
                    onChange={(e) => setCreateTitle(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                {/* Row Headers */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      ROW HEADERS
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Header"
                    defaultValue={createHormoneTitle}
                    onChange={(e) => setCreateHormoneTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Header"
                    defaultValue={createDaysTitle}
                    onChange={(e) => setCreateDaysTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Header"
                    defaultValue={createMipTitle}
                    onChange={(e) => setCreateMipTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Header"
                    defaultValue={createUnitTitle}
                    onChange={(e) => setCreateUnitTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Header"
                    defaultValue={createRangeTitle}
                    onChange={(e) => setCreateRangeTitle(e.target.value)}
                  />
                </div>
                {/* Row Headers */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      1ST ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 1"
                    defaultValue={createHormone01}
                    onChange={(e) => setCreateHormone01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 1"
                    defaultValue={createDaymone01}
                    onChange={(e) => setCreateDaymone01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 1"
                    defaultValue={createMip01}
                    onChange={(e) => setCreateMip01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 1"
                    defaultValue={createUnit01}
                    onChange={(e) => setCreateUnit01(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 1"
                    defaultValue={createRange01}
                    onChange={(e) => setCreateRange01(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      2ND ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 2"
                    defaultValue={createHormone02}
                    onChange={(e) => setCreateHormone02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 2"
                    defaultValue={createDaymone02}
                    onChange={(e) => setCreateDaymone02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 2"
                    defaultValue={createMip02}
                    onChange={(e) => setCreateMip02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 2"
                    defaultValue={createUnit02}
                    onChange={(e) => setCreateUnit02(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 2"
                    defaultValue={createRange02}
                    onChange={(e) => setCreateRange02(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      3RD ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 3"
                    defaultValue={createHormone03}
                    onChange={(e) => setCreateHormone03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 3"
                    defaultValue={createDaymone03}
                    onChange={(e) => setCreateDaymone03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 3"
                    defaultValue={createMip03}
                    onChange={(e) => setCreateMip03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 3"
                    defaultValue={createUnit03}
                    onChange={(e) => setCreateUnit03(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 3"
                    defaultValue={createRange03}
                    onChange={(e) => setCreateRange03(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5">
                      4TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 4"
                    defaultValue={createHormone04}
                    onChange={(e) => setCreateHormone04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 4"
                    defaultValue={createDaymone04}
                    onChange={(e) => setCreateDaymone04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 4"
                    defaultValue={createMip04}
                    onChange={(e) => setCreateMip04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 4"
                    defaultValue={createUnit04}
                    onChange={(e) => setCreateUnit04(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 4"
                    defaultValue={createRange04}
                    onChange={(e) => setCreateRange04(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6">
                      5TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 5"
                    defaultValue={createHormone05}
                    onChange={(e) => setCreateHormone05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 5"
                    defaultValue={createDaymone05}
                    onChange={(e) => setCreateDaymone05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 5"
                    defaultValue={createMip05}
                    onChange={(e) => setCreateMip05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 5"
                    defaultValue={createUnit05}
                    onChange={(e) => setCreateUnit05(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 5"
                    defaultValue={createRange05}
                    onChange={(e) => setCreateRange05(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-7">
                      6TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 6"
                    defaultValue={createHormone06}
                    onChange={(e) => setCreateHormone06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 6"
                    defaultValue={createDaymone06}
                    onChange={(e) => setCreateDaymone06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 6"
                    defaultValue={createMip06}
                    onChange={(e) => setCreateMip06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 6"
                    defaultValue={createUnit06}
                    onChange={(e) => setCreateUnit06(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 6"
                    defaultValue={createRange06}
                    onChange={(e) => setCreateRange06(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-8">
                      7TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 7"
                    defaultValue={createHormone07}
                    onChange={(e) => setCreateHormone07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 7"
                    defaultValue={createDaymone07}
                    onChange={(e) => setCreateDaymone07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 7"
                    defaultValue={createMip07}
                    onChange={(e) => setCreateMip07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 7"
                    defaultValue={createUnit07}
                    onChange={(e) => setCreateUnit07(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 7"
                    defaultValue={createRange07}
                    onChange={(e) => setCreateRange07(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-9">
                      8TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 8"
                    defaultValue={createHormone08}
                    onChange={(e) => setCreateHormone08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 8"
                    defaultValue={createDaymone08}
                    onChange={(e) => setCreateDaymone08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 8"
                    defaultValue={createMip08}
                    onChange={(e) => setCreateMip08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 8"
                    defaultValue={createUnit08}
                    onChange={(e) => setCreateUnit08(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 8"
                    defaultValue={createRange08}
                    onChange={(e) => setCreateRange08(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-10">
                      9TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row 9"
                    defaultValue={createHormone09}
                    onChange={(e) => setCreateHormone09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row 9"
                    defaultValue={createDaymone09}
                    onChange={(e) => setCreateDaymone09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row 9"
                    defaultValue={createMip09}
                    onChange={(e) => setCreateMip09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row 9"
                    defaultValue={createUnit09}
                    list="unitsSet"
                    onChange={(e) => setCreateUnit09(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row 9"
                    defaultValue={createRange09}
                    onChange={(e) => setCreateRange09(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Row Counts */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-11">
                      10TH ROW DATA
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hormone Row10"
                    defaultValue={createHormone10}
                    onChange={(e) => setCreateHormone10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Days Row10"
                    defaultValue={createDaymone10}
                    onChange={(e) => setCreateDaymone10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter MIP Row10"
                    defaultValue={createMip10}
                    onChange={(e) => setCreateMip10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Units Row10"
                    defaultValue={createUnit10}
                    onChange={(e) => setCreateUnit10(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Range Row10"
                    defaultValue={createRange10}
                    onChange={(e) => setCreateRange10(e.target.value)}
                  />
                </div>
                {/* Row Counts */}

                {/* Embryo Data */}
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-13">
                      EMBRYO DEGREE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Degree"
                    defaultValue={createEmbryoDegree}
                    onChange={(e) => setCreateEmbryoDegree(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-14">
                      SUB EMBRYO{" "}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Cert Info"
                    defaultValue={createEmbryoSub}
                    onChange={(e) => setCreateEmbryoSub(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-15">
                      EMBRYO TAG
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Embryo Tag"
                    defaultValue={createEmbryoTag}
                    onChange={(e) => setCreateEmbryoTag(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportMaleAssay;
