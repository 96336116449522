import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportAmh = ({
  id,
  reportTitle,
  row1Header,
  row2Header,
  date,
  investigation01,
  result01,
  investigation02,
  result02,
  investigation03,
  result03,
  investigation04,
  result04,
  investigation05,
  result05,
  investigation06,
  result06,
  investigation07,
  result07,
}) => {
  const [createTitle, setCreateTitle] = useState(reportTitle);
  const [createRow1Header, setCreateRow1Header] = useState(row1Header);
  const [createRow2Header, setCreateRow2Header] = useState(row2Header);
  const [createDate, setCreateDate] = useState(date);
  const [createInvestigation01, setCreateInvestigation01] =
    useState(investigation01);
  const [createResult01, setCreateResult01] = useState(result01);
  const [createInvestigation02, setCreateInvestigation02] =
    useState(investigation02);
  const [createResult02, setCreateResult02] = useState(result02);
  const [createInvestigation03, setCreateInvestigation03] =
    useState(investigation03);
  const [createResult03, setCreateResult03] = useState(result03);
  const [createInvestigation04, setCreateInvestigation04] =
    useState(investigation04);
  const [createResult04, setCreateResult04] = useState(result04);
  const [createInvestigation05, setCreateInvestigation05] =
    useState(investigation05);
  const [createResult05, setCreateResult05] = useState(result05);
  const [createInvestigation06, setCreateInvestigation06] =
    useState(investigation06);
  const [createResult06, setCreateResult06] = useState(result06);
  const [createInvestigation07, setCreateInvestigation07] =
    useState(investigation07);
  const [createResult07, setCreateResult07] = useState(result07);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "serology", id);
      await updateDoc(taskDocument, {
        reportTitle: createTitle,
        date: createDate,
        row1Header: createRow1Header,
        row2Header: createRow2Header,
        investigation01: createInvestigation01,
        result01: createResult01,
        investigation02: createInvestigation02,
        result02: createResult02,
        investigation03: createInvestigation03,
        result03: createResult03,
        investigation04: createInvestigation04,
        result04: createResult04,
        investigation05: createInvestigation05,
        result05: createResult05,
        investigation06: createInvestigation06,
        result06: createResult06,
        investigation07: createInvestigation07,
        result07: createResult07,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update SEROLOGY
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      REPORT TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Report Title"
                    defaultValue={createTitle}
                    onChange={(e) => setCreateTitle(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={row1Header}
                    placeholder="Enter Row1HeaderTitle"
                    onChange={(e) => setCreateRow1Header(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2HeaderTitle"
                    defaultValue={row2Header}
                    onChange={(e) => setCreateRow2Header(e.target.value)}
                  />

                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Row1HeaderTitle and Row2HeaderTitle
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={createInvestigation01}
                    onChange={(e) => setCreateInvestigation01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={createResult01}
                    onChange={(e) => setCreateResult01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2AData"
                    defaultValue={createInvestigation02}
                    onChange={(e) => setCreateInvestigation02(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row2BData"
                    defaultValue={createResult02}
                    onChange={(e) => setCreateResult02(e.target.value)}
                  />

                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      Row2AData and Row2BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      Row3AData and Row3BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3AData"
                    defaultValue={createInvestigation03}
                    onChange={(e) => setCreateInvestigation03(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3BData"
                    defaultValue={createResult03}
                    onChange={(e) => setCreateResult03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4AData"
                    defaultValue={createInvestigation04}
                    onChange={(e) => setCreateInvestigation04(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4BData"
                    defaultValue={createResult04}
                    onChange={(e) => setCreateResult04(e.target.value)}
                  />

                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      Row4AData and Row4BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5">
                      Row5AData and Row5BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5AData"
                    defaultValue={createInvestigation05}
                    onChange={(e) => setCreateInvestigation05(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5BData"
                    defaultValue={createResult05}
                    onChange={(e) => setCreateResult05(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6AData"
                    defaultValue={createInvestigation06}
                    onChange={(e) => setCreateInvestigation06(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6BData"
                    defaultValue={createResult06}
                    onChange={(e) => setCreateResult06(e.target.value)}
                  />

                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6">
                      Row6AData and Row6BData
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-7">
                      Row7AData and Row7BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7AData"
                    defaultValue={createInvestigation07}
                    onChange={(e) => setCreateInvestigation07(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7BData"
                    defaultValue={createResult07}
                    onChange={(e) => setCreateResult07(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportAmh;
