import "./tpchart.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import EditTpChart from "./EditTpChart";
import { useParams } from "react-router-dom";

const TpChart = () => {
  const [tasks, setTasks] = useState([]);
  const [createBp, setCreateBp] = useState("");
  const [createPr, setCreatePr] = useState("");
  const [createBmi, setCreateBmi] = useState("");
  const [createWeight, setCreateWeight] = useState("");
  const [createHeight, setCreateHeight] = useState("");
  const [checked, setChecked] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "tpchart");

  useEffect(() => {
    const getTasks = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "desc"));
      await getDocs(q)
        .then((list) => {
          let tasksData = list.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setTasks(tasksData);
          setChecked(tasksData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTasks();
  }, []);

  //Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        bloodPressure: createBp,
        pulseRate: createPr,
        bmi: createBmi,
        weight: createWeight,
        height: createHeight,
        isRegular: false,
        isNonRegular: false,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // Regular
  const checkBoxRegular = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isRegular: !state[indexToUpdate].isRegular,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "tpchart", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isRegular;
        transaction.update(docRef, { isRegular: newValue });
      });
    } catch (error) {}
  };

  // non-regular
  const checkBoxNonRegular = async (e) => {
    setChecked((state) => {
      const indexToUpdate = state.findIndex(
        (checkBox) => checkBox.id.toString() === e.target.name
      );
      let newState = state.slice();
      newState.splice(indexToUpdate, 1, {
        ...state[indexToUpdate],
        isNonRegular: !state[indexToUpdate].isNonRegular,
      });
      setTasks(newState);
      return newState;
    });

    // Persisting the checked Value

    try {
      const docRef = doc(db, "greenleaf/" + userId, "tpchart", e.target.name);
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(docRef);
        if (!taskDoc.exists()) {
          throw "Document does not exist!";
        }
        const newValue = !taskDoc.data().isNonRegular;
        transaction.update(docRef, { isNonRegular: newValue });
      });
    } catch (error) {}
  };

  return (
    <>
      <div className="tpchart">
        <p>TPR Chart</p>

        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#tpchartModal"
        >
          Add Records
        </button>

        {tasks.map(
          ({
            id,
            bloodPressure,
            pulseRate,
            bmi,
            weight,
            height,
            isRegular,
            isNonRegular,
            timeStamp,
          }) => (
            <>
              <div className="tphistory" key={id}>
                <div className="tpdata">
                  <div className="tpdata1">
                    <span>
                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Blood Pressure:&nbsp;</strong>
                          {bloodPressure}
                        </p>
                      </span>

                      <span className="pim2">
                        <p>
                          <strong>Pulse rate:&nbsp;</strong>
                          {pulseRate}
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={isRegular}
                          onChange={(e) => checkBoxRegular(e)}
                          name={id}
                        />{" "}
                        <p>Regular</p>
                        <input
                          type="checkbox"
                          defaultChecked={isNonRegular}
                          onChange={(e) => checkBoxNonRegular(e)}
                          name={id}
                        />{" "}
                        <p>Non-Regular</p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Body Mass Index:&nbsp;</strong>
                          {bmi}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Weight:&nbsp;</strong>
                          {weight}
                        </p>
                      </span>

                      <span className="pim">
                        <p style={{ fontSize: 18, color: "#01693C" }}>
                          <strong>Height:&nbsp;</strong>
                          {height}
                        </p>
                      </span>

                      <i>
                        {new Date(timeStamp.seconds * 1000).toLocaleString()}
                      </i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-end">
                <button type="button" className="btn-pri">
                  <EditTpChart
                    id={id}
                    bloodPressure={bloodPressure}
                    pulseRate={pulseRate}
                    bmi={bmi}
                    weight={weight}
                    height={height}
                  />
                </button>
              </div>
            </>
          )
        )}
        {/* Ending Div */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="tpchartModal"
        tabindex="-1"
        aria-labelledby="tpchartModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="tpchartModalLabel">
                  Tp Chart
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-02">
                        BLOOD PRESSURE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter blood pressure..."
                      onChange={(e) => setCreateBp(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-03">
                        PULSE RATE
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Pulse Rate..."
                      onChange={(e) => setCreatePr(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-04">
                        BODY MASS INDEX
                      </span>
                    </div>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Body Mass Index..."
                      onChange={(e) => setCreateBmi(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-05">
                        WEIGHT
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Weight..."
                      onChange={(e) => setCreateWeight(e.target.value)}
                    />
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="pair-06">
                        HEIGHT
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Height..."
                      onChange={(e) => setCreateHeight(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Report
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TpChart;
