import "./consultation.scss"
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Cnotes from './../../components/cnotes/Cnotes';
import Cnote from "../../components/cnote/Cnote";
import SonoReport from "../../components/sonoreport/SonoReport";
import TpChart from "../../components/tpchart/TpChart";

const Consulation = () => {
  return (
    <div className='consultation'>
    <Sidebar/>
    <div className="consultationContainer">
    <Navbar/>
    <div className="cnt">
    <Cnotes/>
    </div>
    <div className="cno">
      <Cnote/>
    </div>
    <div className="sono">
      <SonoReport/>
    </div>
    <div className="tpcharts">
      <TpChart/>
    </div>
    </div>
    </div>
  )
}

export default Consulation