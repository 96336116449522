import "./billing.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Accounts from "../../components/accounts/Accounts";
import TrackBills from "../../components/trackbills/TrackBills";
import Bnote from "../../components/bnote/Bnote";

const Billing = () => {
  return (
    <div className='billing'>
    <Sidebar/>
    <div className="billingContainer">
    <Navbar/>
    <div className="account">
    <Accounts/>
    </div>
    <div className="bnt">
    <Bnote/>
    </div>
    <div className="tbills">
      <TrackBills/>
    </div>
    </div>
    </div>
  )
}

export default Billing