import "./viewreportamh.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportAmh = () => {
  const [ser, setSer] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { serologyId } = useParams();

  // let id = serId

  useEffect(() => {
    const getser = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "serology", serologyId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setSer(theData);
      } else {
        setSer({});
      }
    };
    getser(serologyId);
  }, [serologyId]);

  return (
    <>
      <div className="viewreportser">
        <div className="headerser">
          <div>
            <h1>
              <img src={logo} alt="reportLogo" />
              &nbsp;Greenleaf Clinic
            </h1>
          </div>

          <span>
            <i>
              <HomeIcon className="icon" />
              &nbsp;Address: No.44, Yinka Folorunsho street, off Razak Banjoko,
            </i>
            <br />
            <i> Odofin road-Central Mosque Igbogbo, Ikorodu, Lagos, Nigeria.</i>
            <br />
            <i>
              <PhoneInTalkIcon className="icon" />
              &nbsp;Contact: 0916 925 7751
            </i>
            <br />
            <i>
              <AlternateEmailIcon className="icon" />
              &nbsp;Email: info@greenleafclinic.org
            </i>
            <br />
            <i>
              <OpenInBrowserIcon className="icon" />
              &nbsp;Website: www.greenleafclinic.org
            </i>
            <br />
            {/* <i>
              <LocalHospitalIcon className="icon" />
              &nbsp;Portal: www.accessgreenleaf.com{" "}
            </i> */}
          </span>
        </div>

        <hr />

        <div className="serpatientsdetail">
          <div>
            <p>
              <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
              {users.firstName}&nbsp;{users.lastName}
            </p>
            <p>
              <strong>Email Address:</strong>&nbsp;{users.email}
            </p>
            <p>
              <strong>Date:</strong>&nbsp;{ser.date}
            </p>
          </div>
          <div>
            <p>
              <strong>Age:</strong>&nbsp;{users.age}
            </p>
            <p>
              <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
            </p>
            <p>
              <strong>Occupation:</strong>&nbsp;{users.occupation}
            </p>
          </div>
          <div>
            <p>
              <strong>Sex:</strong>&nbsp;{users.sex}
            </p>
            <p>
              <strong>Registration No:</strong>&nbsp;{users.regNo}
            </p>
            <p>
              <strong>State of Origin:</strong>&nbsp;{users.state}
            </p>
          </div>
        </div>

        <hr />

        <div className="header">
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            {ser.reportTitle}
          </h1>
        </div>

        <div className="coverageser">
          <div className="overallser">
            <div className="headerser">
              <p>{ser.row1Header}</p>
              <p>{ser.row2Header}</p>
            </div>
            <div className="contentser">
              {/* Investigation vis result */}
              <section style={{ marginTop: "8px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation01}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result01}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation02}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result02}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation03}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result03}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation04}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result04}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation05}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result05}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation06}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result06}</p>
                </section>
              </section>
              {/* Investigation vis result */}

              {/* Investigation vis result */}
              <section style={{ marginTop: "-38px" }}>
                <section className="ser" style={{ marginLeft: "18px" }}>
                  <p>{ser.investigation07}</p>
                </section>
                <section
                  className="ser"
                  style={{ marginTop: "-41.0px", marginLeft: "240px" }}
                >
                  <p>{ser.result07}</p>
                </section>
              </section>
              {/* Investigation vis result */}
            </div>
          </div>
        </div>

        {/* Ending Div */}
      </div>
    </>
  );
};

export default ViewReportAmh;
