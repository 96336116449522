import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useParams } from "react-router-dom";

const EditReportUrinalysis = ({
  id,
  durinalysisTitle,
  dmacroscopyheader,
  dmacroscopycell01,
  dmacroscopyvalue01,
  dmicroscopyheader,
  dmicroscopycell01,
  dmicroscopycell02,
  dmicroscopycell03,
  dmicroscopycell04,
  dmicroscopycell05,
  dmicroscopycell06,
  dmicroscopycell07,
  dmicroscopycell08,
  dmicroscopycell09,
  dmicroscopycell10,
  dmicroscopyvalue01,
  dmicroscopyvalue02,
  dmicroscopyvalue03,
  dmicroscopyvalue04,
  dmicroscopyvalue05,
  dmicroscopyvalue06,
  dmicroscopyvalue07,
  dmicroscopyvalue08,
  dmicroscopyvalue09,
  dmicroscopyvalue10,
  date,
  checkedby,
}) => {
  const [urinalysisTitle, setUrinalysisTitle] = useState(durinalysisTitle);
  const [macroscopyheader, setMacroscopyHeader] = useState(dmacroscopyheader);
  const [macroscopycell01, setMacroscopyCell01] = useState(dmacroscopycell01);
  const [macroscopyvalue01, setMacroscopyValue01] =
    useState(dmacroscopyvalue01);
  const [microscopyheader, setMicroscopyHeader] = useState(dmicroscopyheader);
  const [microscopycell01, setMicroscopyCell01] = useState(dmicroscopycell01);
  const [microscopycell02, setMicroscopyCell02] = useState(dmicroscopycell02);
  const [microscopycell03, setMicroscopyCell03] = useState(dmicroscopycell03);
  const [microscopycell04, setMicroscopyCell04] = useState(dmicroscopycell04);
  const [microscopycell05, setMicroscopyCell05] = useState(dmicroscopycell05);
  const [microscopycell06, setMicroscopyCell06] = useState(dmicroscopycell06);
  const [microscopycell07, setMicroscopyCell07] = useState(dmicroscopycell07);
  const [microscopycell08, setMicroscopyCell08] = useState(dmicroscopycell08);
  const [microscopycell09, setMicroscopyCell09] = useState(dmicroscopycell09);
  const [microscopycell10, setMicroscopyCell10] = useState(dmicroscopycell10);
  const [microscopyvalue01, setMicroscopyValue01] =
    useState(dmicroscopyvalue01);
  const [microscopyvalue02, setMicroscopyValue02] =
    useState(dmicroscopyvalue02);
  const [microscopyvalue03, setMicroscopyValue03] =
    useState(dmicroscopyvalue03);
  const [microscopyvalue04, setMicroscopyValue04] =
    useState(dmicroscopyvalue04);
  const [microscopyvalue05, setMicroscopyValue05] =
    useState(dmicroscopyvalue05);
  const [microscopyvalue06, setMicroscopyValue06] =
    useState(dmicroscopyvalue06);
  const [microscopyvalue07, setMicroscopyValue07] =
    useState(dmicroscopyvalue07);
  const [microscopyvalue08, setMicroscopyValue08] =
    useState(dmicroscopyvalue08);
  const [microscopyvalue09, setMicroscopyValue09] =
    useState(dmicroscopyvalue09);
  const [microscopyvalue10, setMicroscopyValue10] =
    useState(dmicroscopyvalue10);
  const [createDate, setCreateDate] = useState(date);
  const [checkedBy, setCheckedby] = useState(checkedby);

  const { userId } = useParams();

  const updateTask = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(
        db,
        "greenleaf/" + userId,
        "reportUrinalysis",
        id
      );
      await updateDoc(taskDocument, {
        durinalysisTitle: urinalysisTitle,
        dmacroscopyheader: macroscopyheader,
        dmacroscopycell01: macroscopycell01,
        dmacroscopyvalue01: macroscopyvalue01,
        dmicroscopyheader: microscopyheader,
        dmicroscopycell01: microscopycell01,
        dmicroscopycell02: microscopycell02,
        dmicroscopycell03: microscopycell03,
        dmicroscopycell04: microscopycell04,
        dmicroscopycell05: microscopycell05,
        dmicroscopycell06: microscopycell06,
        dmicroscopycell07: microscopycell07,
        dmicroscopycell08: microscopycell08,
        dmicroscopycell09: microscopycell09,
        dmicroscopycell10: microscopycell10,
        dmicroscopyvalue01: microscopyvalue01,
        dmicroscopyvalue02: microscopyvalue02,
        dmicroscopyvalue03: microscopyvalue03,
        dmicroscopyvalue04: microscopyvalue04,
        dmicroscopyvalue05: microscopyvalue05,
        dmicroscopyvalue06: microscopyvalue06,
        dmicroscopyvalue07: microscopyvalue07,
        dmicroscopyvalue08: microscopyvalue08,
        dmicroscopyvalue09: microscopyvalue09,
        dmicroscopyvalue10: microscopyvalue10,
        date: createDate,
        checkedby: checkedBy,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteRoundedIcon />
      </button>
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "green" }}
              >
                Update Report
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      URINALYSIS TITLE
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter URINALYSIS Report Title"
                    defaultValue={urinalysisTitle}
                    onChange={(e) => setUrinalysisTitle(e.target.value)}
                  />
                </div>

                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-02">
                      MACROSCOPY HEADER
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Macroscopy Header"
                    defaultValue={macroscopyheader}
                    onChange={(e) => setMacroscopyHeader(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-03">
                      Macroscopy Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={macroscopycell01}
                    onChange={(e) => setMacroscopyCell01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={macroscopyvalue01}
                    onChange={(e) => setMacroscopyValue01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-04">
                      MICROSCOPY HEADER
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Microscopy Header"
                    defaultValue={microscopyheader}
                    onChange={(e) => setMicroscopyHeader(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-05">
                      Microscopy Row1AData and Row1BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={microscopycell01}
                    onChange={(e) => setMicroscopyCell01(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={microscopyvalue01}
                    onChange={(e) => setMicroscopyValue01(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-06">
                      Microscopy Row2AData and Row2BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1AData"
                    defaultValue={microscopycell02}
                    onChange={(e) => setMicroscopyCell02(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row1BData"
                    defaultValue={microscopyvalue02}
                    onChange={(e) => setMicroscopyValue02(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-07">
                      Microscopy Row3AData and Row3BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3AData"
                    defaultValue={microscopycell03}
                    onChange={(e) => setMicroscopyCell03(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row3BData"
                    defaultValue={microscopyvalue03}
                    onChange={(e) => setMicroscopyValue03(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-08">
                      Microscopy Row4AData and Row4BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4AData"
                    defaultValue={microscopycell04}
                    onChange={(e) => setMicroscopyCell04(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row4BData"
                    defaultValue={microscopyvalue04}
                    onChange={(e) => setMicroscopyValue04(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-09">
                      Microscopy Row5AData and Row5BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5AData"
                    defaultValue={microscopycell05}
                    onChange={(e) => setMicroscopyCell05(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row5BData"
                    defaultValue={microscopyvalue05}
                    onChange={(e) => setMicroscopyValue05(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-10">
                      Microscopy Row6AData and Row6BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6AData"
                    defaultValue={microscopycell06}
                    onChange={(e) => setMicroscopyCell06(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row6BData"
                    defaultValue={microscopyvalue06}
                    onChange={(e) => setMicroscopyValue06(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-11">
                      Microscopy Row7AData and Row7BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7AData"
                    defaultValue={microscopycell07}
                    onChange={(e) => setMicroscopyCell07(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row7BData"
                    defaultValue={microscopyvalue07}
                    onChange={(e) => setMicroscopyValue07(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-12">
                      Microscopy Row8AData and Row8BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8AData"
                    defaultValue={microscopycell08}
                    onChange={(e) => setMicroscopyCell08(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row8BData"
                    defaultValue={microscopyvalue08}
                    onChange={(e) => setMicroscopyValue08(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-13">
                      Microscopy Row9AData and Row9BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9AData"
                    defaultValue={microscopycell09}
                    onChange={(e) => setMicroscopyCell09(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row9BData"
                    defaultValue={microscopyvalue09}
                    onChange={(e) => setMicroscopyValue09(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-14">
                      Microscopy Row10AData and Row10BData
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10AData"
                    defaultValue={microscopycell10}
                    onChange={(e) => setMicroscopyCell10(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Row10BData"
                    defaultValue={microscopyvalue10}
                    onChange={(e) => setMicroscopyValue10(e.target.value)}
                  />
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair">
                      CHECKED BY
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Checked by"
                    defaultValue={checkedBy}
                    onChange={(e) => setCheckedby(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateTask(e)}
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReportUrinalysis;
