import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";
import moment from "moment";

const EditSperm = ({
  id,
  name,
  frozen,
  thawed,
  straw,
  status,
  statusa,
  storage,
  dateFrozen,
  dateThawed,
  paymentDueDate,
  isTriggered,
}) => {
  const [createName, setCreateName] = useState(name);
  const [createFrozen, setCreateFrozen] = useState(frozen);
  const [createThawed, setCreateThawed] = useState(thawed);
  const [createStraw, setCreateStraw] = useState(straw);
  const [createStatusA, setCreateStatusA] = useState(statusa);
  const [createStatus, setCreateStatus] = useState(status);
  const [createStorage, setCreateStorage] = useState(storage);
  const [createDateThawed, setCreateDateThawed] = useState(dateThawed);
  const [createDateFrozen, setCreateDateFrozen] = useState(dateFrozen);
  const [createPDD, setCreatePDD] = useState(paymentDueDate);
  const [createTriggers, setCreateTriggers] = useState(isTriggered);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "sperm", id);
      await updateDoc(taskDocument, {
        name: createName,
        straw: createStraw,
        frozen: createFrozen,
        thawed: createThawed,
        status: createStatus,
        statusa: createStatusA,
        storage: createStorage,
        dateThawed: createDateThawed,
        dateFrozen: createDateFrozen,
        paymentDueDate: createPDD,
        isTriggered: createTriggers,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setCreatePDD(moment(createDateFrozen).add(3, "months").calendar());
  }, [createDateFrozen]);

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update SpermPresv
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                placeholder="enter name"
                defaultValue={createName}
                onChange={(e) => setCreateName(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                className="form-control"
                placeholder="enter number of straw"
                defaultValue={createStraw}
                onChange={(e) => setCreateStraw(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="enter number frozen"
                className="form-control"
                defaultValue={createFrozen}
                onChange={(e) => setCreateFrozen(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="enter number thawed"
                className="form-control"
                defaultValue={createThawed}
                onChange={(e) => setCreateThawed(e.target.value)}
                size="3080"
              />
              <div
                type="text"
                className="form-control"
                onChange={(e) => setCreatePDD(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="set status"
                className="form-control"
                defaultValue={createStatus}
                onChange={(e) => setCreateStatus(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="set payment status"
                className="form-control"
                defaultValue={createStatusA}
                onChange={(e) => setCreateStatusA(e.target.value)}
                size="3080"
              />
              <input
                type="text"
                placeholder="set storage location"
                className="form-control"
                defaultValue={createStorage}
                onChange={(e) => setCreateStorage(e.target.value)}
                size="3080"
              />

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair">
                    DATE THAWED
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDateThawed}
                  onChange={(e) => setCreateDateThawed(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair">
                    DATE FROZEN
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={dateFrozen}
                  onChange={(e) => setCreateDateFrozen(e.target.value)}
                />
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="pair">
                    IS THIS EVENT TRIGGERED?
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Have you triggered this event?"
                  defaultValue={createTriggers}
                  onChange={(e) => setCreateTriggers(e.target.value)}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update SpermPresv
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSperm;
