import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";

const EditDrugs = ({
  id,
  drug,
  dosage,
  quantity,
  nurse,
  date,
  admin,
  usage,
  duration,
}) => {
  const [createDrug, setCreateDrug] = useState(drug);
  const [createDosage, setCreateDosage] = useState(dosage);
  const [createQuantity, setCreateQuantity] = useState(quantity);
  const [createNurse, setCreateNurse] = useState(nurse);
  const [createDate, setCreateDate] = useState(date);
  const [createAdmin, setCreateAdmin] = useState(admin);
  const [createDuration, setCreateDuration] = useState(duration);
  const [createUsage, setCreateUsage] = useState(usage);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(db, "greenleaf/" + userId, "pharmacy", id);
      await updateDoc(taskDocument, {
        drug: createDrug,
        dosage: createDosage,
        quantity: createQuantity,
        nurse: createNurse,
        date: createDate,
        admin: createAdmin,
        usage: createUsage,
        duration: createDuration,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update Pharmacy
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="enter drug name"
                  defaultValue={createDrug}
                  onChange={(e) => setCreateDrug(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="enter quantity"
                  defaultValue={createQuantity}
                  onChange={(e) => setCreateQuantity(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="enter nurse/staff dispensed to"
                  defaultValue={createNurse}
                  onChange={(e) => setCreateNurse(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="enter dosage"
                  defaultValue={createDosage}
                  onChange={(e) => setCreateDosage(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  placeholder="Route of Administration"
                  className="form-control"
                  defaultValue={createAdmin}
                  onChange={(e) => setCreateAdmin(e.target.value)}
                  size="3080"
                />
                <input
                  type="text"
                  placeholder="enter duration"
                  className="form-control"
                  defaultValue={createDuration}
                  onChange={(e) => setCreateDuration(e.target.value)}
                  size="3080"
                />
                <input
                  type="text"
                  placeholder="enter frequency"
                  className="form-control"
                  defaultValue={createUsage}
                  onChange={(e) => setCreateUsage(e.target.value)}
                  size="3080"
                />
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update Pharmacy
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDrugs;
