import "./treatmentsheets.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Sheets from "../../components/sheets/Sheets";
import IuiTreatments from "../../components/iuitreatments/IuiTreatments";
import Recipientsheet from "../../components/recipientsheet/Recipientsheet";
import StimulationSheet from "../../components/stimulationsheet/StimulationSheet";

const TreatmentSheets = () => {
  return (
    <div className='treatmentsheets'>
      <Sidebar/>
      <div className="treatmentSheetsContainer">
      <Navbar/>
      <div className="sheetsment">
        <Sheets/>
      </div>
      <div className="iuisheets">
        <IuiTreatments/>
      </div>
      <div className="recpsheets">
        <Recipientsheet/>
      </div>
      <div className="stimulsheets">
        <StimulationSheet/>
      </div>
      </div>
      </div>
  )
}

export default TreatmentSheets