import "./cnote.scss";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import EditCnote from "./EditCnote";

const Cnote = () => {
  const [createNotes, setCreateNotes] = useState("");
  const [createReport, setCreateReport] = useState("");
  const [createDuration, setCreateDuration] = useState("");
  const [notes, setNotes] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { userId } = useParams();

  const collectionRef = collection(db, "greenleaf/" + userId, "consultation");

  useEffect(() => {
    const getNotes = async () => {
      const q = query(collectionRef, orderBy("timeStamp", "desc"));
      await getDocs(q).then((note) => {
        let notesData = note.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setNotes(notesData);
      });
    };
    getNotes();
  }, []);

  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        cnote: createNotes,
        reported: createReport,
        duration: createDuration,
        timeStamp: serverTimestamp(),
      });
      setButtonDisabled(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="cnote">
        <p>Notes for Consultation</p>
        <hr />

        {/* Modal Button */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#cnotesModal"
        >
          Add Notes
        </button>

        {notes.map(({ cnote, id, timeStamp, reported, duration }) => (
          <div className="addcnote" key={id}>
            <span>
              {cnote}
              <br />
              <p style={{ color: "#01693C" }}>{duration}</p>
              <br />
              <i>{new Date(timeStamp.seconds * 1000).toLocaleString()}</i>
              <p>{reported}</p>
            </span>
            <div className="buttonfloat">
              <button type="button" className="btn-primary">
                <EditCnote
                  cnote={cnote}
                  id={id}
                  reported={reported}
                  duration={duration}
                />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="cnotesModal"
        tabindex="-1"
        aria-labelledby="cnotesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="d-flex" onSubmit={submitTask}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="cnotesModalLabel">
                  Consultation Notes
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Notes from the Doc..."
                  onChange={(e) => setCreateNotes(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="duration(tracking old clients).."
                  onChange={(e) => setCreateDuration(e.target.value)}
                  size="3080"
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="reported by.."
                  onChange={(e) => setCreateReport(e.target.value)}
                  size="3080"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={buttonDisabled}
                >
                  Add Notes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Cnote;
