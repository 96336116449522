import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAQjD741lOKAvaKzbY4bAwzSVhBK7o07r4",
  authDomain: "glcdatabase-41f99.firebaseapp.com",
  projectId: "glcdatabase-41f99",
  storageBucket: "glcdatabase-41f99.appspot.com",
  messagingSenderId: "1027857709674",
  appId: "1:1027857709674:web:f52a9d4b5b7942359de813",
  measurementId: "G-BV8M3V5N2G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const auth = getAuth();

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const storage = getStorage();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BNlqvBX5kc3Bn4hYwbrZUvayWqghWg3WchLB_lG17NNXyK8oANvuFodYmeaasTHHk_2uWbcrKoKRpHLOhAnouv4",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
