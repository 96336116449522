import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { db } from "../../firebase";

const EditRecipientSheet = ({
  id,
  recpSheets,
  startB,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
}) => {
  const [createRSheet, setCreateRSheet] = useState(recpSheets);
  const [createStart, setCreateStart] = useState(startB);
  const [createOne, setCreateOne] = useState(one);
  const [createTwo, setCreateTwo] = useState(two);
  const [createThree, setCreateThree] = useState(three);
  const [createFour, setCreateFour] = useState(four);
  const [createFive, setCreateFive] = useState(five);
  const [createSix, setCreateSix] = useState(six);
  const [createSeven, setCreateSeven] = useState(seven);
  const [createEight, setCreateEight] = useState(eight);
  const [createNine, setCreateNine] = useState(nine);
  const [createTen, setCreateTen] = useState(ten);
  const [createEleven, setCreateEleven] = useState(eleven);

  const { userId } = useParams();

  const updateData = async (e) => {
    e.preventDefault();
    try {
      const taskDocument = doc(
        db,
        "greenleaf/" + userId,
        "recipientTreatments",
        id
      );
      await updateDoc(taskDocument, {
        recpSheets: createRSheet,
        startB: createStart,
        one: createOne,
        two: createTwo,
        three: createThree,
        four: createFour,
        five: createFive,
        six: createSix,
        seven: createSeven,
        eight: createEight,
        nine: createNine,
        ten: createTen,
        eleven: createEleven,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* Modal Button */}
      <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}
      >
        <EditNoteIcon />
      </button>

      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editLabel"
                style={{ color: "grey" }}
              >
                Update IUI Treatment Sheet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter Recipient Headers"
                    defaultValue={createRSheet}
                    onChange={(e) => setCreateRSheet(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-1">
                      Row Listing
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter bleeding.."
                    defaultValue={createStart}
                    onChange={(e) => setCreateStart(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-01">
                      Start Bleeding
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-2">
                      One vis Row Data and Two vis Row Data
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from one vis Row Data"
                    className="form-control"
                    defaultValue={createOne}
                    onChange={(e) => setCreateOne(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from two vis Row Data"
                    className="form-control"
                    defaultValue={createTwo}
                    onChange={(e) => setCreateTwo(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from three vis Row Data"
                    className="form-control"
                    defaultValue={createThree}
                    onChange={(e) => setCreateThree(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from four vis Row Data"
                    className="form-control"
                    defaultValue={createFour}
                    onChange={(e) => setCreateFour(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-3">
                      Three vis Row Data and Four vis Row Data
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-4">
                      Five vis Row Data and Six vis Row Data
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from five vis Row Data"
                    className="form-control"
                    defaultValue={createFive}
                    onChange={(e) => setCreateFive(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from six vis Row Data"
                    className="form-control"
                    defaultValue={createSix}
                    onChange={(e) => setCreateSix(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from seven vis Row Data"
                    className="form-control"
                    defaultValue={createSeven}
                    onChange={(e) => setCreateSeven(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from eight vis Row Data"
                    className="form-control"
                    defaultValue={createEight}
                    onChange={(e) => setCreateEight(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-5">
                      Seven vis Row Data and Eight vis Row Data
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-6">
                      Nine vis Row Data and Ten vis Row Data
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="counting from nine vis Row Data"
                    className="form-control"
                    defaultValue={createNine}
                    onChange={(e) => setCreateNine(e.target.value)}
                    size="3080"
                  />
                  <input
                    type="text"
                    placeholder="counting from ten vis Row Data"
                    className="form-control"
                    defaultValue={createTen}
                    onChange={(e) => setCreateTen(e.target.value)}
                    size="3080"
                  />
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="counting from eleven vis Row Data"
                    className="form-control"
                    defaultValue={createEleven}
                    onChange={(e) => setCreateEleven(e.target.value)}
                    size="3080"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="pair-7">
                      Eleven vis Row Data
                    </span>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => updateData(e)}
              >
                Update Sheet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRecipientSheet;
