import { useEffect, useState } from "react";
import "./viewreportmcs.scss";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const ViewReportMcs = () => {
  const [mcs, setMcs] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  // View respective users
  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { cultureId } = useParams();

  // let id = mcsId

  useEffect(() => {
    const getMcs = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "reportmcs", cultureId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setMcs(theData);
      } else {
        setMcs({});
      }
    };
    getMcs(cultureId);
  }, [cultureId]);

  return (
    <>
      <div className="viewreportmcs">
        <div className="headermcs">
          <div>
            <h1>
              <img src={logo} alt="reportLogo" />
              &nbsp;Greenleaf Clinic
            </h1>
          </div>

          <span>
            <i>
              <HomeIcon className="icon" />
              &nbsp;Address: No.44, Yinka Folorunsho street, off Razak Banjoko,
            </i>
            <br />
            <i> Odofin road-Central Mosque Igbogbo, Ikorodu, Lagos, Nigeria.</i>
            <br />
            <i>
              <PhoneInTalkIcon className="icon" />
              &nbsp;Contact: 0916 925 7751
            </i>
            <br />
            <i>
              <AlternateEmailIcon className="icon" />
              &nbsp;Email: info@greenleafclinic.org
            </i>
            <br />
            <i>
              <OpenInBrowserIcon className="icon" />
              &nbsp;Website: www.greenleafclinic.org
            </i>
            <br />
            {/* <i>
              <LocalHospitalIcon className="icon" />
              &nbsp;Portal: www.accessgreenleaf.com{" "}
            </i> */}
          </span>
        </div>

        <hr />

        <div className="mcspatientsdetail">
          <div>
            <p>
              <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
              {users.firstName}&nbsp;{users.lastName}
            </p>
            <p>
              <strong>Email Address:</strong>&nbsp;{users.email}
            </p>
            <p>
              <strong>Date:</strong>&nbsp;{mcs.date}
            </p>
          </div>
          <div>
            <p>
              <strong>Age:</strong>&nbsp;{users.age}
            </p>
            <p>
              <strong>Phone Number:</strong>&nbsp;{users.phoneNumber}
            </p>
            <p>
              <strong>Occupation:</strong>&nbsp;{users.occupation}
            </p>
          </div>
          <div>
            <p>
              <strong>Sex:</strong>&nbsp;{users.sex}
            </p>
            <p>
              <strong>Registration No:</strong>&nbsp;{users.regNo}
            </p>
            <p>
              <strong>State of Origin:</strong>&nbsp;{users.state}
            </p>
          </div>
        </div>

        <hr />

        <div className="header">
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            {mcs.reportTitle}
          </h1>
          <u>
            <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
              {mcs.subReportTitle}
            </h3>
          </u>
          <u>
            <p style={{ textAlign: "center" }}>{mcs.subHeading}</p>
          </u>
        </div>

        <div className="coveragemcs">
          <div className="overallmcs">
            <div className="headermcs">
              <p>{mcs.row1Header}</p>
              <p>{mcs.row2Header}</p>
            </div>
            <div className="contentmcs">
              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "8px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics01}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity01}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics02}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity02}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics03}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity03}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics04}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity04}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics05}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity05}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics06}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity06}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics07}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity07}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics08}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity08}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics09}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity09}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}

              {/* Antibiotics vis sensitivity */}
              <section style={{ marginTop: "-38px" }}>
                <section className="cms">
                  <p>{mcs.antibiotics10}</p>
                </section>
                <section
                  className="cms"
                  style={{ marginTop: "-41.0px", marginLeft: "180px" }}
                >
                  <p>{mcs.sensitivity10}</p>
                </section>
              </section>
              {/* Antibiotics vis sensitivity */}
            </div>
          </div>
        </div>

        {/* Ending Div */}
      </div>
    </>
  );
};

export default ViewReportMcs;
