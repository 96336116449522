import "./viewbills.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png";

const ViewBills = () => {
  const [bills, setBills] = useState([]);
  const [users, setUsers] = useState([]);

  const { userId } = useParams();
  let id = userId;

  useEffect(() => {
    const getUsers = async (id) => {
      const docRef = doc(db, `greenleaf/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData);
      } else {
        setUsers({});
      }
    };
    getUsers(id);
  }, [id]);

  const { receiptId } = useParams();

  useEffect(() => {
    const getMcs = async () => {
      const docRef = doc(db, "greenleaf/" + userId, "billing", receiptId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setBills(theData);
      } else {
        setBills({});
      }
    };
    getMcs(receiptId);
  }, [receiptId]);

  return (
    <>
      <div className="viewreceipt">
        <div className="receiptbody">
          <h4 style={{ textAlign: "center" }}>
            <img src={logo} alt="reportLogo" />
            <strong>&nbsp;Greenleaf Clinic</strong>
          </h4>
          <hr className="headerRule" />
          <p>
            <strong>Full Name:</strong>&nbsp;{users.title}&nbsp;
            {users.firstName}&nbsp;{users.lastName}
          </p>
          <p>
            <strong>Email Address:</strong>&nbsp;{users.email}
          </p>
          <p>
            <strong>Date:</strong>&nbsp;{bills.date}
          </p>
          <i>
            <p style={{ maxWidth: "400px", textAlign: "left" }}>
              <strong>Description:</strong>&nbsp;{bills.desc}
            </p>
          </i>
          <p style={{ maxWidth: "400px", textAlign: "left" }}>
            <strong>Mode Of Payment:</strong>&nbsp;{bills.mode}
          </p>
        </div>

        <hr />
        <div className="receiptcalc">
          <p style={{ textAlign: "right", fontSize: "20px" }}>
            <strong>Bill:</strong>&nbsp;₦
            {bills.bill?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p
            style={{ textAlign: "right", fontSize: "20px", marginTop: "-16px" }}
          >
            <strong>Amount Paid:</strong>&nbsp;₦
            {bills.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <hr />
          <p style={{ textAlign: "right", fontSize: "20px" }}>
            <strong>Bal:</strong>&nbsp;₦
            {bills.bal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <hr />
        </div>

        <div className="receiptfooter">
          <i>
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              <strong>Thank You for your Patronage!!!</strong>
            </p>
          </i>
        </div>
      </div>
    </>
  );
};

export default ViewBills;
